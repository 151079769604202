import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import brand from "../../../assets/images/logo-icon-blk.png";
import ArrowIcon from "../../../assets/images/right-arrow.png";
import PaymentInputs from "../../../components/stripePaymentCustomForm/inputCardValidate";
import { useSelector } from "react-redux";
import { stripePaymentMethodHandler } from "../../../utils/paymentConfirmation";
import { CardElement, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { toast } from "react-toastify";
import {
  API_PAYMENT_INTENT_SECRET,
  API_PAYMENT_SAVE_PAYMENT,
  postApi,
  API_PERSONAL_BUY_MULTIPLE_PROFILE,
  API_JOB_SEEKER_UPGRADE_PROFILE
} from "../../../utils";

function SavedCard(props) {
  const cardInformation = props.cardInfo || {
    id: "pm_1Lk88hSHnVwgWeNxt6UyGj96",
    object: "payment_method",
    billing_details: {
      address: {
        city: null,
        country: null,
        line1: null,
        line2: null,
        postal_code: null,
        state: null,
      },
      email: null,
      name: null,
      phone: null,
    },
    card: {
      brand: "visa",
      checks: {
        address_line1_check: null,
        address_postal_code_check: null,
        cvc_check: "pass",
      },
      country: "US",
      exp_month: 3,
      exp_year: 2033,
      fingerprint: "sdmsOWGVhQsCFVLk",
      funding: "credit",
      generated_from: null,
      last4: "4242",
      networks: {
        available: ["visa"],
        preferred: null,
      },
      three_d_secure_usage: {
        supported: true,
      },
      wallet: null,
    },
    created: 1663686816,
    customer: "cus_MT4Cps4eLJJIJc",
    livemode: false,
    metadata: {},
    type: "card",
  };
  const navigation = useNavigate();
  const userToken = useSelector(
    (state) => state.UserAuth.userData.payload.token
  );
  const userId = useSelector(
    (state) => state.UserAuth.userData.payload.user._id
  );
  const user = useSelector((state) => state.UserAuth.userData.payload.user);
  const usertype = useSelector((state) => state.userType.userType);

  var amount = props.amount;
  const planId = props?.planName;
  const credit = props?.credit;
  const payAsYouGoProfileIds = props?.payAsYouGoProfileIds;

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const [client_secret, setclient_secret] = useState();

  const currency = "INR";
  const paymentMode = "card";
  // create payment,intedent call backend
  const bodyIntentSecret = {
    amount: amount ?? props?.payAsyougoAmount,
    currency,
    payment_method_types: [paymentMode],
  };

  // if (usertype.payload === 'jobSeeker') {
  //   amount = 35;
  //   console.log("this is job seeker so changing the amount to 35")
  // } else {
  console.log("this is props data to show plan ID", planId);
  console.log("--------this is pay as you go profile ids", payAsYouGoProfileIds, "pay as you amount", props?.payAsyougoAmount);

  if (planId === undefined) {
    console.log("---------thisss is pay as you go purchasing --------", props?.payAsyougoAmount, "----credit", payAsYouGoProfileIds);
    amount = props?.payAsyougoAmount
  }
  // }

  useEffect(() => {
    postApi(
      API_PAYMENT_INTENT_SECRET,
      bodyIntentSecret,
      userToken,
      ({ data: { client_secret } }) => {
        setclient_secret(client_secret);
      },
      errorFunction
    );
  }, [stripe, elements]);

  //***Getting the card value */

  //Adding card to list

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      setLoading(false);
      return;
    }

    if (!client_secret) {
      setLoading(false);
      return;
    }

    //create payment method

    //confirm payment
    const { error: stripeError, paymentIntent } = await stripe
      .confirmCardPayment(client_secret, {
        payment_method: cardInformation.id,
        payment_method_options: {
          card: {
            cvc: elements.getElement(CardCvcElement),
          },
        },
      })
      .catch((e) => {
        console.log(e);
      });

    if (stripeError) {
      // Show error to your customer (e.g., insufficient funds)
      toast.error(stripeError.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setLoading(false);
      return;
    }
    if (usertype.payload === 'jobSeeker') {
      postApi(
        API_JOB_SEEKER_UPGRADE_PROFILE,
        {
          userId: userId
        },
        userToken,
        sucessFunctionPur,
        errorFunction
      );
    } else {
      if (planId === undefined) {
        // this is pay as you payment
        console.log(" -- trigerring this buy multiple profiles for pay-as-you-go --- ")
        const obj = {
          pointInfoId: payAsYouGoProfileIds,
          credit: payAsYouGoProfileIds?.length,
          payAsyouGo: true,
        };
        postApi(
          API_PERSONAL_BUY_MULTIPLE_PROFILE,
          obj,
          userToken,
          sucessFunctionPur,
          errorFunction
        );
      } else {
        //save payment details to databse
        const dataPaymentBody = {
          name: user.fullName || "",
          transactionId: paymentIntent.id,
          userId,
          amount: amount ?? props?.payAsyougoAmount,
          credit,
          usertype: usertype.payload === "jobSeeker" ? 1 : 2,
          currency,
          paymentMode,
          planId,
          status: paymentIntent.status,
          cardNo: cardInformation.card.last4,
        };

        postApi(
          API_PAYMENT_SAVE_PAYMENT,
          dataPaymentBody,
          userToken,
          successFunction,
          errorFunction
        );
      }
    }



    toast.success(
      `Payment of ${bodyIntentSecret.amount} ${paymentIntent.status}`,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
    props.setPaymentIntent(paymentIntent);
    setLoading(false);
    props.setPaymentCompleted(true);
  };

  const successFunction = ({ data }) => {
    console.log(data);
  };
  const errorFunction = (err) => {
    console.log(err, "sorry error ocured");
    setLoading(false);
  };

  const sucessFunctionPur = (res) => {
    toast.success(res.data.message, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        lineHeight: "27px",
        color: "#212529",
        fontSize: "15px",

        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  //
  return client_secret ? (
    <section id="form-page" className="red-graphics-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-3 col-sm-2" />
          <div className="col-lg-4 col-md-6 col-sm-8 text-center">
            <div className="brand-logo">
              <img src={brand} alt="brand" />
              <br />
              <br />
              {/* style="color:white; font-weight: bold;" */}
              <h3>Card Info</h3>
            </div>

            <form onSubmit={handleSubmit}>
              {/* <PaymentInputs onTouch={getCardData} /> */}

              <div className="row">
                <div className="col-md-12 mb-3">
                  <div className="signup-input-bx ">
                    <label htmlFor="cc-number">Card Type</label>
                    <span>{cardInformation.card.brand.toUpperCase()} </span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 mb-3">
                  <div className="signup-input-bx ">
                    <label htmlFor="cc-number">Card Number</label>
                    <span>**** **** **** {cardInformation.card.last4} </span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 mb-3">
                  <div className="signup-input-bx ">
                    <label htmlFor="expiry">Expiration Date</label>
                    <span>
                      {cardInformation.card.exp_month} /
                      {cardInformation.card.exp_year}{" "}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="signup-input-bx ">
                      <label htmlFor="cvc">CVC</label>
                      <CardCvcElement
                        id="cvc"
                        className="form-control"
                        options={CARD_ELEMENT_OPTIONS}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-sm-12 mt-2 add_card-checkbox">
                    <button
                      className="btn btn-dark w-100"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? (
                        <div
                          className="spinner-border spinner-border-sm text-light"
                          role="status"
                        />
                      ) : (
                        `PAY £ ${props?.amount ?? props?.payAsyougoAmount ?? 0}`
                      )}
                    </button>
                  </div>
                </div>
                {errorMsg && <div className="text-danger mt-2">{errorMsg}</div>}
              </div>
            </form>

            <br />
            <br />
            <br />
            {/* <p>
              <a >
                <img src={ArrowIcon} alt="arrow" />
              </a>
            </p> */}
          </div>
          <div className="col-lg-4 col-md-3 col-sm-2" />
        </div>
      </div>
    </section>
  ) : (
    <div className="d-flex justify-content-center align-items-center LoaderCenter">
      {" "}
      <div className="block">
        <span
          className="spinner-grow spinner-grow-lg mx-2"
          role="status"
          aria-hidden="true"
        />
        <h6 className="LoaderText">Processing...</h6>
      </div>
    </div>
  );
}

export default SavedCard;
