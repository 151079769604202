import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import brand from '../../../assets/images/logo-icon-blk.png';
import Button from 'react-bootstrap/Button';
import ArrowIcon from '../../../assets/images/right-arrow.png';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import UpdateImge from '../../../assets/images/edit-icon.png';
import { useSelector } from 'react-redux';
import {
  API_GET_SEEKER_DETAIL,
  API_POST_SEEKER_UPDATE_BIO,
  API_PERSONAL_INFO,
  postApi,
} from '../../../utils';

function ProfileComplete() {
  const navigation = useNavigate();
  const userToken = useSelector(state => state.UserAuth.userData.payload.token);
  const userId = useSelector(state => state.UserAuth.userData.payload.user._id);

  const [show, setShow] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [jobRole, setJobRole] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [desireIndustry, setDesireIndustry] = useState('');
  const [eduLevel, setEduLevel] = useState('');
  const [skillLevel, setSkillLevel] = useState('');
  const [location, setLocation] = useState('');
  const [companySize, setCompanySize] = useState('');
  const [employmentType, setEmploymentType] = useState('');
  const [expectedPay, setExpectedPay] = useState('');
  const [softSkill, setSoftSkill] = useState('');
  const [hardSkill, setHardSkill] = useState('');
  const [userImage, setUserImage] = useState('');
  const [personalBio, setpersonalBio] = useState('');
  const [Bio, setFromBio] = useState('');
  const { state } = useLocation();
  const [personInfo, setpersonInfo] = useState([]);
  //******Watch the current Index changes
  useEffect(() => {
    setUserImage(state.imagePath);
    // console.log('get state data ----------', state.imagePath);
    requestDataFromServer();
    requestDataFromServer1();
  }, []);
  const requestDataFromServer = () => {
    setLoading(true);
    const obj = {
      usertype: 1,
      userId: userId,
    };

    postApi(API_GET_SEEKER_DETAIL, obj, userToken, successFunction, errorFunction);
  };

  const successFunction = response => {
    setLoading(false);
    if (response.status === 200) {
      const result = response?.data?.result[0];

      if (result) {
        setCompanySize(result?.campanySize);
        setExpectedPay(result?.employmentType);
        setEmploymentType(result?.employmentMode);
        setEduLevel(result?.education[0]?.option);
        setDesireIndustry(result?.industry[0]?.option);
        setJobTitle(result?.jobtitle[0]?.option);
        setJobRole(result?.role[0]?.option);
        setSkillLevel(result?.skilllevel[0]?.option);
        setpersonalBio(result?.personalbio);
        setLocation(result.locationName ? result.locationName : '');

        const soSkill = result?.softSkill.map(item => {
          return ' ' + item.skill.option + ' (' + `${item.skill.exp}` + ')';
        });
        const hdSkill = result?.hardSkill.map(item => {
          return ' ' + item.skill.option + ' (' + `${item.skill.exp}` + ')';
        });
        setSoftSkill(soSkill.toString());
        setHardSkill(hdSkill.toString());
        console.log('get new value-----', soSkill.toString().replace(',', ''));
      }
    }
  };
  const errorFunction = error => {
    setLoading(false);
    console.log('get Error response', error);
  };

  const requestDataFromServer1 = () => {
    // setLoading(true);
    const obj = { usertype: 1, userId: userId };
    postApi(API_PERSONAL_INFO, obj, userToken, successFunction1, errorFunction1);
  };
  const successFunction1 = res => {
    setpersonInfo(res.data.seekerInfo);
  };
  const errorFunction1 = errr => {
    console.log(errr, 'erorr');
  };

  const bioShow = () => {
    const event = show !== true;
    setShow(event);
  };

  const requestDataBio = () => {
    setLoading(true);
    const obj = { bio: Bio, id: userId };
    postApi(API_POST_SEEKER_UPDATE_BIO, obj, userToken, successBio, errorBio);
  };

  const successBio = res => {
    setLoading(false);
    bioShow();
    setpersonalBio(res.data.result);
  };
  const errorBio = er => {
    console.log(er, 'error');
  };

  return (
    <React.Fragment>
      {isLoading && (
        <div className='d-flex justify-content-center align-items-center LoaderCenter'>
          {' '}
          <div className='block'>
            <span className='spinner-grow spinner-grow-lg mx-2' role='status' aria-hidden='true' />
            <h6 className='LoaderText'>Processing...</h6>
          </div>
        </div>
      )}
      {!isLoading && (
        <section id='feel-suite-best'>
          <div className='container'>
            <div className='row d-flex algin-items-center justify-content-center'>
              <div className='col-lg-9 text-center'>
                <img src={brand} alt='brand' className='w-80px' />
                <h3 style={{ fontSize: '2rem', lineHeight: '2.5rem' }}>
                  Here's what the job posters will see
                </h3>
              </div>
            </div>

            <div className='row' style={{ marginTop: '-7%' }}>
              <div className='col-lg-12 upload-your-profile text-center'>
                {/* <h4>Add a photo</h4> */}
                <div className='profile-img'>
                  <img
                    className='img-fluid'
                    src={userImage}
                    alt=''
                    style={{ width: 200, height: 200, borderRadius: 100, objectFit: 'cover' }}
                  />
                </div>
                <h4 className='text-capitalize'>{personInfo.fullName}</h4>
                {/* <h4>Consider Jane</h4> */}

                <div className='row'>
                  <div className='col-lg-12 col-sm-12 col-xs-12 col-12 text-left-bio-content personal-bio-cnt'>
                    <h6>
                      Personal summary
                      <span className='p-1' onClick={() => bioShow()}>
                        <img src={UpdateImge} alt='update' />
                      </span>
                    </h6>
                    {show === true ? (
                      <>
                        <Form id='BioForm'>
                          <InputGroup>
                            <InputGroup.Text>Bio</InputGroup.Text>
                            <Form.Control
                              as='textarea'
                              aria-label='With textarea'
                              defaultValue={personalBio}
                              onChange={e => {
                                setFromBio(e.target.value);
                              }}
                            />
                          </InputGroup>
                          <Button
                            variant='warning'
                            className='mt-3'
                            onClick={() => requestDataBio()}
                          >
                            Submit
                          </Button>
                        </Form>
                      </>
                    ) : (
                      <>
                        <p>{personalBio}</p>
                      </>
                    )}
                  </div>
                </div>

                <div className='row'>
                  <div className='col-lg-4 col-sm-6 col-xs-6 col-12 text-left personal-bio-cnt'>
                    <h6>Desired Job role</h6>
                    <p className={'capitalize'}>{jobRole}</p>
                  </div>

                  <div className='col-lg-4 col-sm-6 col-xs-6 col-12 text-left personal-bio-cnt'>
                    <h6>Desired Job title</h6>
                    <p className={'capitalize'}>{jobTitle}</p>
                  </div>

                  <div className='col-lg-4 col-sm-6 col-xs-6 col-12 text-left personal-bio-cnt'>
                    <h6>Desired industry</h6>
                    <p className={'capitalize'}>{desireIndustry}</p>
                  </div>

                  <div className='col-lg-4 col-sm-6 col-xs-6 col-12 text-left personal-bio-cnt'>
                    <h6>Education levels</h6>
                    <p className={'capitalize'}>{eduLevel}</p>
                  </div>

                  <div className='col-lg-4 col-sm-6 col-xs-6 col-12 text-left personal-bio-cnt'>
                    <h6>Skill level</h6>
                    <p className={'capitalize'}>{skillLevel}</p>
                  </div>

                  <div className='col-lg-4 col-sm-6 col-xs-6 col-12 text-left personal-bio-cnt'>
                    <h6>Location</h6>
                    <p className={'capitalize'}>{location}</p>
                  </div>

                  <div className='col-lg-4 col-sm-6 col-xs-6 col-12 text-left personal-bio-cnt'>
                    <h6>Prefered Company size</h6>
                    <p className={'capitalize'}>{companySize}</p>
                  </div>

                  <div className='col-lg-4 col-sm-6 col-xs-6 col-12 text-left personal-bio-cnt'>
                    <h6>Type of employment</h6>
                    <p className={'capitalize'}>{employmentType}</p>
                  </div>

                  <div className='col-lg-4 col-sm-6 col-xs-6 col-12 text-left personal-bio-cnt'>
                    <h6>Minimum expected pay</h6>
                    <p className={'capitalize'}>{expectedPay}</p>
                  </div>

                  <div className='col-lg-4 col-sm-6 col-xs-6 col-12 text-left personal-bio-cnt'>
                    <h6>Soft skills</h6>
                    <p className={'capitalize'}>
                      {softSkill}
                    </p>
                  </div>

                  <div className='col-lg-4 col-sm-6 col-xs-6 col-12 text-left personal-bio-cnt'>
                    <h6>Hard Skills</h6>
                    <p className={'capitalize'}>
                      {hardSkill}
                    </p>
                  </div>
                </div>
                <br />
                <br />
                <p>
                  {' '}
                  <a
                    href='javascript:void(0)'
                    onClick={() => navigation('../profileCompleteCongrats')}
                    className='add1'
                  >
                    {' '}
                    <img src={ArrowIcon} alt='right' />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
}

export default ProfileComplete;
