export const  getPercentageImage = (_per) => {
    if (_per < 0 || _per > 100) {
        return "Invalid percentage";
    } else if (_per >= 0 && _per < 20) {
        return { 'image': "Very Low" };
    } else if (_per >= 50 && _per < 55) {
        return { 'image': "EGG", 'text': <span>Incubating</span>, 'color': '#42d9ff', 'name': <span>SUNNY-SIDE <span style={{ color: '#42d9ff' }}>EGG</span></span> };
    } else if (_per >= 56 && _per < 60) {
        return { 'image': "sparrow", 'text': <span>Strong Match</span>, 'color': '#ff3300', 'name': <span>HOUSE <span style={{ color: '#ff3300' }}>SPARROW</span></span> };
    } else if (_per >= 61 && _per < 70) {
        return { 'image': "CHICKEN", 'text': <span>Good Match</span>, 'color': '#ffcc80', 'name': <span>FIGHTING <span style={{ color: '#ffcc80' }}>ROOSTER</span></span> };
    } else if (_per >= 71 && _per < 79) {
        return { 'image': "CROW", 'text': <span>Great Match</span>, 'color': '#42d9ff', 'name': <span>CUNNING <span style={{ color: '#42d9ff' }}>CROW</span></span> };
    } else if (_per >= 80 && _per < 89) {
        return { 'image': "PENGUIN", 'text': <span>Excellent Match</span>, 'color': '#3366ff', 'name': <span>PENGUIN <span style={{ color: '#3366ff' }}>KING</span></span> };
    } else if (_per >= 90 && _per <= 100) {
        return { 'image': "eagle", 'text': <span>Top Rated Match</span>, 'color': '#cc0000', 'name': <span>MIGHTY <span style={{ color: '#cc0000' }}>EAGLE</span></span> };
    }else{
        return null
    }
}

export const getPercentageData = (_per) => {
    if (_per < 0 || _per > 100) {
        return "Invalid percentage";
    }else if(_per >= 0 && _per < 20){
        return { 'image': "Very Low" };
    }else if(_per >= 50 && _per < 55){
        return {
            'image': 'EGG', 
            'title': <span>Incubating</span>, 
            'title_color': '#42d9ff',
            'name': <span>SUNNY-SIDE <span style={{ color: '#42d9ff' }}>EGG</span></span>, 
            'context': 'Just starting out and ready to grow.',
            'description': 'These candidates are in the early stages of their careers, showing great potential and eagerness to learn. They may not yet have all the required skills but possess strong foundational attributes and a willingness to grow. With proper mentoring and training, they can develop into invaluable team members. Their enthusiasm and fresh perspective can bring new ideas and energy to your organisation. Review their profiles to see how they can be nurtured to meet your specific needs.' 
        };
    }else if(_per >= 56 && _per < 60){
        return {
            'image': 'sparrow', 
            'title': <span>Strong Match</span>, 
            'title_color': '#ff3300',
            'name': <span>HOUSE <span style={{ color: '#ff3300' }}>SPARROW</span></span>, 
            'context': 'Steady and dependable, these candidates are on their way to becoming strong contributors.',
            'description': 'These candidates have proven resilience and reliability, demonstrating a strong foundation of skills and experience. While they may need some further development, their potential is evident. They are motivated and committed to advancing their careers, making them solid contributors to your team. Their ability to adapt and learn quickly positions them as future leaders. Explore their profiles to understand how their strengths can complement your team and contribute to your long-term goals.' 
        };
    }else if(_per >= 61 && _per < 70){
        return {
            'image': 'CHICKEN', 
            'title': <span>Good Match</span>, 
            'title_color': '#ffcc80',
            'name': <span>FIGHTING <span style={{ color: '#ffcc80' }}>ROOSTER</span></span>, 
            'context': 'These candidates are among the top 20% of our matches, distinguished by their exceptional skills, experience, preferences and personality traits.',
            'description': 'Fighting Roosters are highly motivated individuals with a strong desire to advance and make a significant impact. Their energetic and determined nature makes them ideal potential additions to your team. Review their profiles to discover how their unique strengths align with your needs and can drive your organisation forward.' 
        };
    }else if(_per >= 71 && _per < 79){
        return {
            'image': 'CROW', 
            'title': <span>Great Match</span>, 
            'title_color': '#42d9ff',
            'name': <span>CUNNING <span style={{ color: '#42d9ff' }}>CROW</span></span>, 
            'context': 'These candidates rank in the top 15% of our matches.',
            'description': 'Like the clever Crow, they showcase outstanding intelligence, resourcefulness and quick thinking. They excel in their fields and can effectively navigate complex challenges. Their proactive approach and ability to innovate make them highly valuable to any team. Their profiles reveal a combination of expertise and creativity that can significantly enhance your organisation’s performance and strategic initiatives.' 
        };
    }else if(_per >= 80 && _per < 89){
        return {
            'image': 'PENGUIN', 
            'title': <span>Excellent Match</span>, 
            'title_color': '#3366ff',
            'name': <span>PENGUIN <span style={{ color: '#3366ff' }}>KING</span></span>, 
            'context': 'These candidates are in the top 10% of our matches, characterised by their dedication, dependability and superior work ethic',
            'description': 'Like the Penguin king, they carry themselves with a sense of diligence and commitment. They consistently deliver high-quality results and are committed to making a lasting impact on your business. Their profiles demonstrate a blend of excellence and professionalism that ensures they will be strong, reliable assets to your team, driving both immediate and long-term success.' 
        };
    }else if(_per >= 90 && _per <= 100){
        return {
            'image': 'eagle', 
            'title': <span>Top Rated Match</span>, 
            'title_color': '#cc0000',
            'name': <span>MIGHTY <span style={{ color: '#cc0000' }}>EAGLE</span></span>, 
            'context': 'These candidates are elite performers within the top 5% of our matches, known for their unparalleled talents and exceptional achievements.',
            'description': 'Like the mighty Eagle, they soar above the rest with their extraordinary talents and commitment to achieving greatness. They bring a high level of expertise, commitment to excellence and a proven track record of delivering outstanding results. Their profiles showcase their ability to lead and innovate, making them indispensable to any organisation aiming for the highest standards of success. ' 
        };
    }else{
        return null
    }
}