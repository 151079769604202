import React, { useState, useEffect } from 'react';
import brand from '../../../assets/images/logo-icon-blk.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  API_SEEKER_JOB_ROLE_OPTIONS,
  API_SEEKER_JOB_ROLE_TITLE,
  API_SEEKER_ADD_JOB_ROLE,
  API_COMMON_ONBOARDING_STEPS,
  postApi,
  postApiPoster,
} from '../../../utils';

import { toast } from 'react-toastify';
import GoBack from '../../../components/GoBack';
import { saveUserInfo } from '../../../redux/actions';
import axios from "axios";


function JobTitle() {
  //*******Getting router passed data***************************/
  const { state } = useLocation();
  const jobId = state.jobId;
  //*******useState to store values*************************** */
  const [roleSelected, setRoleSelected] = useState([]);
  const [jobTitle, setJobTitle] = useState('');
  const [isFetchedData, setFetchedData] = useState(false);
  const [jobListed, setJobLisited] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [userCreatedRole, setUserCreatedRole] = useState('');
  const [jobTitleForTextField, setjobTitleForTextField] = useState('');

  const userType = useSelector(state => state.userType.userType);
  const userToken = useSelector(state => state.UserAuth.userData.payload.token);
  const userId = useSelector(state => state.UserAuth.userData.payload.user._id);
  const [jobRolesForSuggestions, setjobRolesForSuggestions] = useState([]);
  const [selectedFromSuggestion, setSelectedFromSuggestion] = useState('');
  const [suggestionOff, setSuggestionOff] = useState(false);

  const navigation = useNavigate();

  //******************************calling api to get list and title**************************
  const requestDataFromServer = () => {
    setLoading(true);
    const obj = {
      category: 'jobtitle',
    };
    if (userType.payload === 'jobSeeker') {
      postApi(API_SEEKER_JOB_ROLE_TITLE, obj, userToken, successFunction, errorFunction);
      postApi(
        API_SEEKER_JOB_ROLE_OPTIONS,
        obj,
        userToken,
        successFunctionOptions,
        errorFunctionOptions
      );
    } else {
      postApiPoster(API_SEEKER_JOB_ROLE_TITLE, obj, userToken, successFunction, errorFunction);
      postApiPoster(
        API_SEEKER_JOB_ROLE_OPTIONS,
        obj,
        userToken,
        successFunctionOptions,
        errorFunctionOptions
      );
    }
  };

  const successFunction = response => {
    if (response.status === 200) {
      setJobTitle(response.data.result);
    }
  };

  const errorFunction = error => {
    console.log(error, 'drerer');
  };

  //****We will close the loader if and only if list items is fetched
  const successFunctionOptions = response => {
    setLoading(false);
    setFetchedData(true);
    if (response.status === 200) {
      const adminData = response?.data?.result.filter(item => {
        return item.addedby === '3';
      });
      setjobRolesForSuggestions(adminData);
      setJobLisited(adminData.slice(0, 9));
    }

    // console.log(response);
  };

  const errorFunctionOptions = error => {
    setLoading(false);
    setFetchedData(true);
    console.log(error);
  };


  useEffect(() => {
    if (!isFetchedData) {
      requestDataFromServer();
    }
  }, [isFetchedData]);
  //*******Save user data in server and naviagte to another page***********************************//

  const savingInfo = async (mode) => {

    // if (roleSelected.length > 0) {
    //   const [id] = roleSelected;
    //   setLoading(true);
    //   updateOnBoardingSteps(id);

    // } else {
    //   toast.error('Please choose any of the option or type manually!', {
    //     position: 'top-right',
    //     autoClose: 1000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
    setLoading(true);
    let obj = {};
    if (userType.payload === "jobSeeker") {
      obj = {
        option: userCreatedRole,
        category: "jobrole",
        usertype: 1,
        status: "2",
        userId: userId,
        addedby: "1",
      };
    } else {
      obj = {
        option: userCreatedRole,
        category: "jobrole",
        usertype: 2,
        status: "2",
        userId: userId,
        addedby: "2",
      };
    }

    let axiosConfig = {
      headers: {
        type: "front-end",
        mode: mode == null ? 1 : mode,
        Authorization: "Bearer " + userToken,
      },
    };

    try {
      const data = await axios.post(API_SEEKER_ADD_JOB_ROLE, obj, axiosConfig);
      updateOnBoardingSteps(data?.data?.data?._id);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const updateOnBoardingSteps = (id) => {

    const obj = {
      jobId: jobId,
      "userId": userId,
      "jobTitleId": id,
      "userType": userType.payload,
      "stepNumber": 2,
      // "_id": "642d1ab16fa8534d104c5a20"
    };
    postApi(API_COMMON_ONBOARDING_STEPS, obj, userToken, successFunctionOnboardingSteps, errorSavingData);


  }
  const userAuthInfo = useSelector(state => state.UserAuth);
  const dispatch = useDispatch();

  const successFunctionOnboardingSteps = res => {

    setLoading(false);

    if (res.status === 200) {

      // dispatch the selfAssessmentstep
      var existingUserInfo = userAuthInfo.userData?.payload;
      existingUserInfo.user.selfAssessmentResultSteps = 2;

      dispatch(
        saveUserInfo({
          payload: existingUserInfo,
        })
      );

      navigation('../chooseIndustry', {state: {jobId: jobId}});


    }

  }


  //!error occured in saving data
  const errorSavingData = err => {
    toast.error(err.data.message, {
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <React.Fragment>
      {isLoading && (
        <div className='d-flex justify-content-center align-items-center LoaderCenter'>
          {' '}
          <div className='block'>
            <span className='spinner-grow spinner-grow-lg mx-2' role='status' aria-hidden='true' />
            <h6 className='LoaderText'>Processing...</h6>
          </div>
        </div>
      )}
      {!isLoading && (
        <section id='job-role-steps'>
          <div className='discover-personality-top-cnt'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12 text-center'>
                  <img src={brand} alt='brand' className='w-80px' />
                </div>
              </div>
            </div>
          </div>
          {/* <!--discover-personality-top-cnt--> */}

          <div className='container'>
            <GoBack />
            <div className='row justify-content-center'>
              <div className='col-lg-2' />
              <div className='col-lg-8'>
                <div className='card' style={{ borderWidth: 0 }}>
                  <form id='msform'>
                    {/* <!-- progressbar --> */}

                    <fieldset>
                      <div className='form-card'>
                        {userType.payload === 'jobSeeker'
                          ? jobTitle &&
                          jobTitle.map(data => (
                            <h3 key={data._id} className='job-que'>
                              {data.question}
                            </h3>
                          ))
                          : jobTitle &&
                          jobTitle.map(data => (
                            <h3 key={data._id} className='job-que'>
                              {data.question}
                            </h3>
                          ))}
                        <div className='role-wrapper red-radio-btn'>
                          {jobListed &&
                            jobListed.map((data, i) => (
                              <div key={i + 1} className='role-col'>
                                <div key={i + 2} className='inputGroup'>
                                  <input
                                    key={i + 3}
                                    id={data?._id}
                                    readOnly
                                    // onChange={() => setRoleSelected(data._id)}
                                    checked={roleSelected.includes(data._id)}
                                    name='radio'
                                    type='radio'
                                  />
                                  <label
                                    key={i + 4}
                                    className='text-capitalize'
                                    htmlFor={data?._id}
                                    onClick={() => {
                                      setRoleSelected([data._id])
                                      setjobTitleForTextField('');
                                      setUserCreatedRole(data.option);
                                    }}
                                  >
                                    {data.option}
                                  </label>
                                </div>
                              </div>
                            ))}
                        </div>
                        {/* <!--role-wrapper--> */}

                        <div className='enter-job-role job-role-desc position-relative'>
                          {userType.payload === 'jobSeeker' ? (
                            <h5>Don’t see the job title you looking for?</h5>
                          ) : (
                            <h5>Don’t see the job title you hiring for?</h5>
                          )}
                          <input
                            type='text'
                            id='name'
                            name='name'
                            className='job-input'
                            value={jobTitleForTextField}
                            onFocus={event => {
                              event.target.setAttribute('autocomplete', 'off');
                            }}
                            placeholder='Type your job title here...'
                            onChange={e => {
                              // e.target.value = e.target.value.replace(/[^a-z]/gi, '');
                              const { value } = e.target;
                              const regex = /^[a-zA-Z\s]*$/; // Regex pattern that matches only alphabets and spaces

                              if (regex.test(value)) {
                                setSelectedFromSuggestion('');
                                if (e.target.value === '') {
                                  setSuggestionOff(false);
                                }
                                setRoleSelected([]);
                                setUserCreatedRole(e.target.value);
                                setjobTitleForTextField(e.target.value);
                              }
                            }}
                          />

                        </div>
                      </div>

                      <input
                        type='button'
                        name='next'
                        className='next action-button'
                        value=''
                        onClick={savingInfo}
                      />
                      <div className='row'>
                        <div className='col-12'>
                          <h2
                            className='steps'
                            style={{ fontSize: '1.2rem', margin: '0rem 0rem 1rem' }}
                          >
                            Step 2/8
                          </h2>
                        </div>
                      </div>
                    </fieldset>
                  </form>

                  <div className='progress'>
                    <div
                      className='progress-bar'
                      role='progressbar'
                      style={{ width: '25%' }}
                      aria-valuenow='25'
                      aria-valuemin='0'
                      aria-valuemax='100'
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-2' />
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
}

export default JobTitle;
