import { TYPES } from './actionTypes';
// action for calling set userType in reducer
const saveUserInfo = (payload) => {
	if (payload.isRemember) {
		localStorage.setItem('UserInfo', JSON.stringify(payload));
		return {
			type: TYPES.USER_LOGIN,
			payload
		};
	} else {
		sessionStorage.setItem('UserSession', JSON.stringify(payload));

		return {
			type: TYPES.USER_LOGIN,
			payload
		};
	}
};

export { saveUserInfo };
