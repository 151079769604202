import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

function PrivateJobPosterRoute({ isAuthenticated, userType }) {
    if (!isAuthenticated) return (<Navigate to="/" />)
    if (userType !== 'jobPoster') {
        return (<Navigate to="/homepage"/>);
    }
    return <Outlet />
}

export default PrivateJobPosterRoute