import React, { useState, useEffect } from 'react';
import brand from '../../../assets/images/logo-icon-blk.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import {
  API_SEEKER_JOB_ROLE_OPTIONS,
  API_SEEKER_JOB_ROLE_TITLE,
  API_COMMON_ONBOARDING_STEPS,
  getApi,
  postApi,
  postApiPoster,
} from '../../../utils';
import GoBack from '../../../components/GoBack';
import { saveUserInfo } from '../../../redux/actions';


function RoleStepSeven() {
  const navigation = useNavigate();
  //*******Getting router passed data***************************/
  const { state } = useLocation();
  const jobId = state.jobId;
  const [companySize, setCompanySize] = useState([]);

  const [jobTitle, setJobTitle] = useState('');
  const [listedCompanySize, setListedCompanySize] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isFetchedData, setFetchedData] = useState(false);
  const userType = useSelector(state => state.userType.userType);
  const userToken = useSelector(state => state.UserAuth.userData.payload.token);
  const userId = useSelector(state => state.UserAuth.userData.payload.user._id);

  const savingInfo = () => {
    if (companySize.length > 0) {
      const sizeOfCompony = company.filter(el => el.id.includes(companySize));
      const copy = Object.assign({}, ...sizeOfCompony);
      const { size } = copy;
      var one = size;
      var whiteSpace = ' ';
      const withString = one.concat(whiteSpace, 'employee');
      setLoading(true);
      updateOnBoardingSteps(withString);

      // navigation('../salary', {
      //   state: { ...routerData, campanySize: withString },
      // });
    } else {
      toast.error('Please choose any of the option or type manually!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  //Comapany size array
  const company = [
    { id: '1', size: '1-20' },
    { id: '2', size: '21-50' },
    { id: '3', size: '51-100' },
    { id: '4', size: '101-250' },
    { id: '5', size: '251+' },
  ];
  //call api
  const requestDataFromServer = () => {
    setLoading(true);

    const obj = {
      category: 'companysize',
    };
    if (userType.payload === 'jobSeeker') {
      postApi(API_SEEKER_JOB_ROLE_TITLE, obj, userToken, successFunction, errorFunction);
      // postApi(API_SEEKER_JOB_ROLE_OPTIONS, obj, userToken, successFunctionOptions, errorFunctionOptions);
    } else {
      postApiPoster(API_SEEKER_JOB_ROLE_TITLE, obj, userToken, successFunction, errorFunction);
      // postApiPoster(API_SEEKER_JOB_ROLE_OPTIONS, obj, userToken, successFunctionOptions, errorFunctionOptions);
    }
  };
  const successFunction = response => {
    setFetchedData(true);
    setLoading(false);
    if (response.status === 200) {
      setJobTitle(response.data.result);
      setListedCompanySize(company);
    }
  };
  const errorFunction = error => {
    setLoading(false);
    setFetchedData(true);
  };

  useEffect(() => {
    if (!isFetchedData) {
      requestDataFromServer();
    }
  }, [isFetchedData]);

  const updateOnBoardingSteps = (id) => {

    const obj = {
      jobId: jobId,
      "userId": userId,
      "campanySize": id,
      "userType": userType.payload,
      "stepNumber": 7,
      // "_id": "642d1ab16fa8534d104c5a20"
    };
    postApi(API_COMMON_ONBOARDING_STEPS, obj, userToken, successFunctionOnboardingSteps, errorSavingData);


  }
  const userAuthInfo = useSelector(state => state.UserAuth);
  const dispatch = useDispatch();

  const successFunctionOnboardingSteps = res => {

    setLoading(false);

    if (res.status === 200) {

      // dispatch the selfAssessmentstep
      var existingUserInfo = userAuthInfo.userData?.payload;
      existingUserInfo.user.selfAssessmentResultSteps = 7;

      dispatch(
        saveUserInfo({
          payload: existingUserInfo,
        })
      );

      navigation('../salary', {state: {jobId: jobId}});


    }

  }

  const errorSavingData = err => {
    toast.error(err.data.message, {
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <React.Fragment>
      {isLoading && (
        <div className='d-flex justify-content-center align-items-center LoaderCenter'>
          {' '}
          <div className='block'>
            <span className='spinner-grow spinner-grow-lg mx-2' role='status' aria-hidden='true' />
            <h6 className='LoaderText'>Processing...</h6>
          </div>
        </div>
      )}
      {!isLoading && (
        <section id='job-role-steps' className='bg-position-1'>
          <GoBack />
          <div className='discover-personality-top-cnt'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12 text-center'>
                  <img src={brand} alt='brand' className='w-80px' />
                </div>
              </div>
            </div>
          </div>
          {/* <!--discover-personality-top-cnt--> */}

          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-lg-2' />
              <div className='col-lg-8'>
                <div className='card' style={{ borderWidth: 0 }}>
                  <form id='msform'>
                    {/* <!-- progressbar --> */}

                    <fieldset>
                      <div className='form-card'>
                        {userType.payload === 'jobSeeker'
                          ? jobTitle &&
                          jobTitle.map(data => (
                            <h3 key={data._id} className='job-que'>
                              {data.question}
                            </h3>
                          ))
                          : jobTitle &&
                          jobTitle.map(data => (
                            <h3 key={data._id} className='job-que'>
                              {data.question}
                            </h3>
                          ))}
                        <div className='role-wrapper'>
                          {listedCompanySize &&
                            listedCompanySize.map((data, i) => (
                              <div key={i} className='role-col'>
                                <div key={i + 2} className='inputGroup'>
                                  <input
                                    key={i + 3}
                                    id={data?._id}
                                    name='radio'
                                    type='radio'
                                    readOnly
                                    checked={companySize.includes(data.id)}
                                  // onChange={() => setCompanySize([ data.id ])}
                                  />
                                  <label
                                    key={i + 4}
                                    onClick={() => setCompanySize([data.id])}
                                    htmlFor={data?._id}
                                  >
                                    {data.size}&nbsp;employees
                                  </label>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>

                      <input
                        type='button'
                        name='next'
                        className='next action-button mt-2'
                        value=''
                        onClick={savingInfo}
                      />
                      <div className='row'>
                        <h2 className='steps' style={{ fontSize: '1.2rem' }}>
                          Step 7/8
                        </h2>
                      </div>
                    </fieldset>
                  </form>

                  <div className='progress'>
                    <div
                      className='progress-bar'
                      role='progressbar'
                      style={{ width: '87.5%' }}
                      aria-valuenow='25'
                      aria-valuemin='0'
                      aria-valuemax='100'
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-2' />
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
}

export default RoleStepSeven;
