const PROXY_ENDPOINT = process.env.REACT_APP_PROXY_V2_ENDPOINT
// export const BASE_URL = "https://api.perspectv.app/";
// export const BASE_URL_SOCKET = "https://api.perspectv.app/";

// export const BASE_URL = "https://uat-api.perspectv.app/";
// export const BASE_URL_SOCKET = "https://uat-api.perspectv.app/";

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_URL_SOCKET = process.env.REACT_APP_BASE_URL_SOCKET;

// STAGING SERVER
// export const BASE_URL = "http://54.204.160.242/";
// export const BASE_URL_SOCKET = "http://54.204.160.242/";

export const Profile_image_seeker = `${BASE_URL}images/jobposter/`;
export const Profile_image_poster = `${BASE_URL}images/jobseeker/`;
export const Chat_image_file = `${BASE_URL}images/chat/`;

export const SUCCESS_STATUS = 200;
export const API_SEEKER_SIGN_UP = BASE_URL + "api/jobseeker/signup";

// export const API_SEEKER_LOGIN = BASE_URL + "api/jobseeker/signin";
export const API_SEEKER_LOGIN = BASE_URL + "api/jobposter/login_jobposter_jobseeker";
export const API_SEEKER_SEND_OTP = BASE_URL + "api/jobseeker/sendotp";
export const API_SEEKER_UPDATE_PASSWORD = BASE_URL + "api/jobseeker/updatepassword";
export const API_SEEKER_CHANGE_PASSWORD = BASE_URL + "api/jobseeker/changePassword";

export const API_COMMON_ONBOARDING_STEPS = BASE_URL + "api/jobposter/update-onboarding-steps";
export const API_UPDATE_POSTER_JOB_ID = BASE_URL + "api/jobposter/update-poster-recentJob-id";

export const API_SEEKER_VERIFY_OTP = BASE_URL + "api/jobseeker/verifyaccount";
export const API_SEEKER_FORGOT_PASSWORD = BASE_URL + "api/jobseeker/forgotpassword";
export const API_SEEKER_ADD_VIDEO_RESUME = BASE_URL + "api/jobseeker/videoresume";
///**********************JOB SEEKR QUESTION URL******************** */
export const API_SEEKER_JOB_ROLE_OPTIONS = BASE_URL + "api/admin/onboarding/getjobroleoptions";

export const API_SEEKER_JOB_ROLE_TITLE = BASE_URL + "api/admin/onboarding/getjobrole";
//*****************************ADD JOB ROLE JOB SEKKER******************************** */
export const API_SEEKER_ADD_JOB_ROLE = BASE_URL + "api/admin/onboarding/addjobroles";
///*****************************GET SELF ASSESMENT CATEGORY********************************************* */
export const API_SELF_ASSESMENT_CATEGORY = BASE_URL + "api/admin/getallselfassessmentcategory";
//***************************************SELF ASSESMENT CATEGORY OPRIONS************************* */
export const API_SELF_ASSESMENT_CATEGORY_OPTIONS = BASE_URL + "api/admin/self/getallselfassesmentcategory";
//*******************************SAVE USER DATA*********************************** */
export const API_SUBMIT_JOBPOSTER_DATA = BASE_URL + "api/jobposter/updateprofiledata";
export const API_UPDATE_JOB_DATA = BASE_URL + "api/jobposter/updateJobData";

export const API_SUBMIT_JOBSEEKER_DATA = BASE_URL + "api/jobposter/updateprofiledata";
//*******************************ADD IMAGE AND DOCUMENT****************************** */
export const API_ADD_IMAGE_DOCUMNET_JOBSEEKER = BASE_URL + "api/jobposter/updateinfo";
export const API_ADD_COMPANY_LOGO_DESC_JOBSEEKER = BASE_URL + "api/jobposter/jobposter/updateinfo";

export const API_ADD_DOCUMNET_JOBSEEKER = BASE_URL + "api/jobseeker/updateinfo";
export const API_JOBPOSTER_COMPANY_ADD_OR_EDIT = BASE_URL + "api/jobposter/company/addorupdate";
export const API_JOBPOSTER_COMPANY_DELETE = BASE_URL + "api/jobposter/company/delete";
export const API_JOBPOSTER_COMPANY_GET_JOB_LIST = BASE_URL + "api/jobposter/company/jobs";
export const API_JOBPOSTER_COMPANY_GET = BASE_URL + "api/jobposter/company/info";

//********************************ADD LOCATION API************************************** */
export const API_ADD_LOCATION = BASE_URL + "api/updatelocation";
export const API_POSTER_SIGN_UP = BASE_URL + "api/jobposter/signup";
export const API_POSTER_LOGIN = BASE_URL + "api/jobposter/login_jobposter_jobseeker";
export const API_POSTER_SEND_OTP = BASE_URL + "api/jobposter/sendotp";
export const API_POSTER_VERIFY_OTP = BASE_URL + "api/jobposter/verifyaccount";
export const API_POSTER_FORGOT_PASSWORD = BASE_URL + "api/jobposter/forgotpassword";
export const API_POSTER_RESET_PASSWORD = BASE_URL + "api/jobposter/updatepassword";
export const API_JOPOSTER_CHANGE_PASSWORD = BASE_URL + "api/jobposter/changePassword";
export const API_GET_SEEKER_DETAIL = BASE_URL + "api/jobposter/fetchjobposter";
export const API_CURRENT_USER = BASE_URL + "api/jobposter/getprofile";
export const API_UPDATE_USER_PROFILE = BASE_URL + "api/jobposter/updatePosterProfile";
export const API_UPDATE_SEEKER_PROFILE = BASE_URL + "api/jobseeker/updateSeekerProfile";

export const API_POST_SEEKER_UPDATE_BIO = BASE_URL + "api/jobposter/updateBio";
//!I Don't know why these api endpoints are same
export const API_GET_POSTER_DETAIL = BASE_URL + "api/jobposter/fetchjobposter";
export const API_GET_POSTER_COMPANIES = BASE_URL + "api/jobposter/companies";

export const API_GET_SUBSCRIPTION_LIST = BASE_URL + "api/plan/list";
export const API_JOBSEEKER_MATCH = BASE_URL + "api/common/matches";

export const API_GET_POINT_INFO_DATA = BASE_URL + "api/common/fetchPointInfoData"
export const API_SET_POINTS = BASE_URL + "api/jobposter/setPercentageAndPointsForAllJobs";

//***** */ Update intersts api********************
export const API_UPDATE_INTEREST = BASE_URL + "api/personal/updateinterest";
export const API_UPDATE_FEEDBACK = BASE_URL + "api/personal/savefeedback";

export const API_PERSONAL_INFO = BASE_URL + "api/personal/info";
export const API_CHANGEPHONE_OTP = BASE_URL + "api/jobposter/ChangeEmailPhoneOtp";
export const API_CHANGEVERIFYPHONE_OTP = BASE_URL + "api/jobposter/ChangeEmailVerifyOtp";
export const API_CHANGERESEND_OTP = BASE_URL + "api/jobposter/changeResendOtp";
export const API_All_NOTIFICATION = BASE_URL + "api/personal/getNotification";

export const API_HARD_STEP_DATA = BASE_URL + "api/jobseeker/getresultkeywordlist";

export const API_UPDATE_ACTIVE_STATUS = BASE_URL + "api/jobseeker/updateStatus";

export const API_UPDATE_ACTIVE_STATUS_FOR_JOB = BASE_URL + "api/jobposter/jobpurpose/updatePostersJobStatus";

//***** */ Payment api********************

export const API_PERSONAL_BUYPROFILE = BASE_URL + "api/personal/buyProfile";
export const API_PERSONAL_BUY_MULTIPLE_PROFILE = BASE_URL + "api/personal/buy-multiple-profile";
export const API_JOB_POSTER_CREDIT = BASE_URL + "api/payment/credit-history";
export const API_JOB_SEEKER_UPGRADE_PROFILE = BASE_URL + "api/payment/upgradeSeekerUser";

export const API_JOB_LIST_WISHLIST = BASE_URL + "api/personal/get-wishlist";
export const API_JOB_ADD_WISHLIST = BASE_URL + "api/personal/add-wishList";
export const API_JOB_DELETE_WISHLIST = BASE_URL + "api/personal/delete-wishList";

export const API_PAYMENT_INTENT_SECRET = BASE_URL + "api/payment/intent-secret";
export const API_PAYMENT_SAVE_PAYMENT = BASE_URL + "api/payment/savePayment";
export const API_PAYMENT_REMOVE_DUPLICATE = BASE_URL + "api/payment/remove-duplicate-card";
export const API_PAYMENT_GET_CARDS_AND_PAYMENT_HISTORY = BASE_URL + "api/payment/get-cards";

// GET SOFT AND HARD SKILLS
export const SOFT_AND_HARD_SKILLS_LIST = BASE_URL + "api/jobseeker/getSkillKeywords";

// DELETE JOB
export const DELETE_JOB = BASE_URL + "api/jobposter/jobpurpose/delete-job";

// DELETE JOB
export const SEEKER_DELETE_JOB = BASE_URL + "api/jobposter/jobpurpose/seeker-delete-job";

export const POSTER_DELETE_JOB = BASE_URL + "api/jobposter/jobpurpose/poster-delete-job";
