import { TYPES } from './actionTypes';
// action for calling set userType in reducer
const handelUserLogout = () => {
	localStorage.removeItem('UserInfo');
	sessionStorage.removeItem('UserSession');
	//localStorage.removeItem('LoginUserInfo');
	return {
		type: TYPES.USER_LOGOUT
	};
};

export { handelUserLogout };
