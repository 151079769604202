import React, { useState, useEffect } from "react";
import brand from "../../../assets/images/logo-icon-blk.png";
import {
  API_SEEKER_JOB_ROLE_OPTIONS,
  API_SEEKER_JOB_ROLE_TITLE,
  API_SEEKER_ADD_JOB_ROLE,
  API_COMMON_ONBOARDING_STEPS,
  postApi,
  postApiPoster,
} from "../../../utils";

import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import GoBack from "../../../components/GoBack";
import { saveUserInfo } from "../../../redux/actions";
import axios from "axios";

function RoleStepFour() {
  //*******Getting router passed data***************************/
  const { state } = useLocation();
  const jobId = state.jobId;
  const navigation = useNavigate();
  const [isFetchedData, setFetchedData] = useState(false);
  const [jobTitle, setJobTitle] = useState("");
  const [jobEucationListed, setEducationLisited] = useState([]);
  const [educationLevel, setEducationLevel] = useState([]);
  const [educationSelectedForInput, setEducationSelectedForInput] =
    useState("");
  const [isLoading, setLoading] = useState(false);
  const [userCreatedRole, setUserCreatedRole] = useState("");

  const userType = useSelector((state) => state.userType.userType);
  const userToken = useSelector(
    (state) => state.UserAuth.userData.payload.token
  );
  const userId = useSelector(
    (state) => state.UserAuth.userData.payload.user._id
  );

  const [jobRolesForSuggestions, setjobRolesForSuggestions] = useState([]);
  const [selectedFromSuggestion, setSelectedFromSuggestion] = useState("");
  const [suggestionOff, setSuggestionOff] = useState(false);
  //call api
  const requestDataFromServer = () => {
    setLoading(true);

    const obj = {
      category: "educationlevel",
    };
    if (userType.payload === "jobSeeker") {
      postApi(
        API_SEEKER_JOB_ROLE_TITLE,
        obj,
        userToken,
        successFunction,
        errorFunction
      );
      postApi(
        API_SEEKER_JOB_ROLE_OPTIONS,
        obj,
        userToken,
        successFunctionOptions,
        errorFunctionOptions
      );
    } else {
      postApiPoster(
        API_SEEKER_JOB_ROLE_TITLE,
        obj,
        userToken,
        successFunction,
        errorFunction
      );
      postApiPoster(
        API_SEEKER_JOB_ROLE_OPTIONS,
        obj,
        userToken,
        successFunctionOptions,
        errorFunctionOptions
      );
    }
  };
  const successFunction = (response) => {
    if (response.status === 200) {
      // console.log(response.data.result, 'tiltterere');
      setJobTitle(response.data.result);
    }
  };
  const errorFunction = (error) => {
    setLoading(false);
    setFetchedData(true);
    console.log(error, "drerer");
  };
  const successFunctionOptions = (response) => {
    setLoading(false);
    setFetchedData(true);

    if (response.status === 200) {
      // setEducationLisited(response.data.result);
      const adminData = response?.data?.result.filter((item) => {
        return item.addedby === "3";
      });
      setjobRolesForSuggestions(adminData);

      setEducationLisited(adminData.slice(0, 9));
    }

    // console.log(response);
  };
  const errorFunctionOptions = (error) => {
    setLoading(false);
    setFetchedData(true);
    console.log(error);
  };
  useEffect(() => {
    console.log("get user data-------");
    if (!isFetchedData) {
      requestDataFromServer();
    }
  }, [isFetchedData]);
  //*******Save user data in server and naviagte to another page***********************************//
  const savingInfo = async (mode) => {
    // if (educationLevel.length > 0) {
    //   const [id] = educationLevel;
    //   setLoading(true);
    //   updateOnBoardingSteps(id);
    // }


    setLoading(true);
    let obj = {};
    if (userType.payload === "jobSeeker") {
      obj = {
        option: userCreatedRole,
        category: "educationlevel",
        usertype: "1",
        status: "2",
        userId: userId,
        addedby: "1",
      };
    } else {
      obj = {
        option: userCreatedRole,
        category: "educationlevel",
        usertype: "2",
        status: "2",
        userId: userId,
        addedby: "2",
      };
    }

    let axiosConfig = {
      headers: {
        type: "front-end",
        mode: mode == null ? 1 : mode,
        Authorization: "Bearer " + userToken,
      },
    };

    try {
      const data = await axios.post(API_SEEKER_ADD_JOB_ROLE, obj, axiosConfig);
      updateOnBoardingSteps(data?.data?.data?._id);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    // else if (userCreatedRole) {
    //   //*************************User created job manually*****************************/
    //   //********************************On loader when user saves data******************************
    //   setLoading(true);
    //   //*******Save info Api
    //   if (userType.payload === 'jobSeeker') {
    //     const obj = {
    //       option: userCreatedRole,
    //       category: 'educationlevel',
    //       usertype: '1',
    //       status: '2',
    //       userId: userId,
    //       addedby: '1',
    //     };
    //     postApi(API_SEEKER_ADD_JOB_ROLE, obj, userToken, saveDataSuccessFunction, errorSavingData);
    //   } else {
    //     const obj = {
    //       option: userCreatedRole,
    //       category: 'educationlevel',
    //       usertype: '2',
    //       status: '2',
    //       userId: userId,
    //       addedby: '2',
    //     };
    //     postApiPoster(
    //       API_SEEKER_ADD_JOB_ROLE,
    //       obj,
    //       userToken,
    //       saveDataSuccessFunction,
    //       errorSavingData
    //     );
    //   }
    // }
    // else {
    //   toast.error('Please choose any of the option or type manually!', {
    //     position: 'top-right',
    //     autoClose: 1000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
  };
  //If data is saved suceessfully
  const saveDataSuccessFunction = (res) => {
    setLoading(false);
    if (res.status === 200) {
      const customId = res.data.data._id;

      setLoading(true);
      updateOnBoardingSteps(customId);
      // navigation('../skillLevel', {
      //   state: { ...routerData, educationLevelId: customId },
      // });
    }
  };
  //!error occured in saving data
  const errorSavingData = (err) => {
    toast.error(err.data.message, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const updateOnBoardingSteps = (id) => {
    const obj = {
      jobId: jobId,
      userId: userId,
      educationLevelId: id,
      userType: userType.payload,
      stepNumber: 4,
      // "_id": "642d1ab16fa8534d104c5a20"
    };
    postApi(
      API_COMMON_ONBOARDING_STEPS,
      obj,
      userToken,
      successFunctionOnboardingSteps,
      errorSavingData
    );
  };
  const userAuthInfo = useSelector((state) => state.UserAuth);
  const dispatch = useDispatch();

  const successFunctionOnboardingSteps = (res) => {
    setLoading(false);

    if (res.status === 200) {
      // dispatch the selfAssessmentstep
      var existingUserInfo = userAuthInfo.userData?.payload;
      existingUserInfo.user.selfAssessmentResultSteps = 4;

      dispatch(
        saveUserInfo({
          payload: existingUserInfo,
        })
      );

      navigation("../skillLevel", {state: {jobId: jobId}});
    }
  };

  return (
    <React.Fragment>
      {isLoading && (
        <div className="d-flex justify-content-center align-items-center LoaderCenter">
          {" "}
          <div className="block">
            <span
              className="spinner-grow spinner-grow-lg mx-2"
              role="status"
              aria-hidden="true"
            />
            <h6 className="LoaderText">Processing...</h6>
          </div>
        </div>
      )}
      {!isLoading && (
        <section id="job-role-steps">
          <div className="discover-personality-top-cnt">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <img src={brand} alt="brand" className="w-80px" />
                </div>
              </div>
            </div>
          </div>
          {/* <!--discover-personality-top-cnt--> */}

          <div className="container">
            <GoBack />
            <div className="row justify-content-center">
              <div className="col-lg-2" />
              <div className="col-lg-8">
                <div className="card" style={{ borderWidth: 0 }}>
                  <form id="msform">
                    {/* <!-- progressbar --> */}

                    <fieldset>
                      <div className="form-card">
                        {userType.payload === "jobSeeker"
                          ? jobTitle &&
                          jobTitle.map((data) => (
                            <h3 key={data._id} className="job-que">
                              {data.question}
                            </h3>
                          ))
                          : jobTitle &&
                          jobTitle.map((data) => (
                            <h3 key={data._id} className="job-que">
                              {data.question}
                            </h3>
                          ))}
                        <div className="role-wrapper">
                          {jobEucationListed &&
                            jobEucationListed.map((data, i) => (
                              <div key={i} className="role-col">
                                <div key={i + 1} className="inputGroup">
                                  <input
                                    key={i + 2}
                                    id={data?._id}
                                    name="radio"
                                    checked={educationLevel.includes(data._id)}
                                    readOnly
                                    type="radio"
                                  />
                                  <label
                                    key={i + 3}
                                    htmlFor={data?._id}
                                    className="text-capitalize"
                                    onClick={() => {
                                      setEducationSelectedForInput("");
                                      setEducationLevel([data._id]);
                                      setUserCreatedRole(data.option);
                                    }}
                                  >
                                    {data.option}
                                  </label>
                                </div>
                              </div>
                            ))}
                        </div>
                        {/* <!--role-wrapper--> */}

                        <div className="enter-job-role job-role-desc position-relative">
                          {userType.payload === "jobSeeker" ? (
                            <h5>
                              Don’t see the qualification you're looking for?
                            </h5>
                          ) : (
                            <h5>
                              Don’t see the qualification you're hiring for?
                            </h5>
                          )}

                          <input
                            type="text"
                            id="name"
                            name="name"
                            className="job-input"
                            placeholder="Type your qualification field here..."
                            required=""
                            value={educationSelectedForInput}
                            onFocus={(event) => {
                              event.target.setAttribute("autocomplete", "off");
                              console.log(event.target.autocomplete);
                            }}
                            onChange={(e) => {
                              // e.target.value = e.target.value.replace(/[^a-z]/gi, '');
                              const { value } = e.target;
                              const regex = /^[a-zA-Z\s]*$/; // Regex pattern that matches only alphabets and spaces

                              if (regex.test(value)) {
                                setSelectedFromSuggestion("");
                                setEducationLevel([]);
                                setUserCreatedRole(e.target.value);
                                setEducationSelectedForInput(e.target.value);
                                if (e.target.value === "") {
                                  setSuggestionOff(false);
                                  // setSelectedFromSuggestion('');
                                }
                              }
                            }}
                          />

                          {/* {userCreatedRole &&
                            !suggestionOff &&
                            !selectedFromSuggestion &&
                            jobRolesForSuggestions?.filter(skill =>
                              skill.option.toLowerCase().includes(userCreatedRole.toLowerCase())
                            ).length > 0 && (
                              <div className='position-absolute  jobs-dropdown'>
                                {jobRolesForSuggestions
                                  ?.filter(skill =>
                                    skill.option
                                      .toLowerCase()
                                      .includes(userCreatedRole.toLowerCase())
                                  )
                                  ?.slice(0, 5)
                                  ?.map(skill => (
                                    <div
                                      style={{
                                        padding: '5px 0',
                                        borderRadius: '5px',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => {

                                        const result = jobRolesForSuggestions.find((item, index) => {
                                          if (item.option == skill.option) {
                                            item.index = index;
                                            return true;
                                          }
                                        });

                                        if (result.index > 8) {
                                          console.log("selected more then 8th ", skill.option)
                                          const newArr = [...jobEucationListed];
                                          newArr[8] = {
                                            "option": result.option,
                                            "_id": result._id,
                                          }
                                          setEducationLisited(newArr)
                                        }

                                        setUserCreatedRole(skill.option);
                                        setSelectedFromSuggestion(skill.option);
                                        setSuggestionOff(false);
                                        setEducationLevel([skill._id]);
                                        setEducationSelectedForInput('');
                                        // setJobRoleSelected([skill._id])
                                      }}
                                    >
                                      {skill.option}
                                    </div>
                                  ))}
                              </div>
                            )} */}
                        </div>
                      </div>
                      {/* <!--form-card--> */}

                      <input
                        type="button"
                        name="next"
                        className="next action-button"
                        value=""
                        onClick={savingInfo}
                      />
                      <div className="row">
                        <h2
                          className="steps"
                          style={{
                            fontSize: "1.2rem",
                            margin: "0rem 0rem 1rem",
                          }}
                        >
                          Step 4/8
                        </h2>
                      </div>
                    </fieldset>
                  </form>

                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "50%" }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-2" />
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
}

export default RoleStepFour;
