import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import brand from '../../../assets/images/logo-icon-blk.png';
import ArrowIcon from '../../../assets/images/right-arrow.png';

import { useSelector } from 'react-redux';
function PurchaseComplete() {
  const {state} = useLocation();
  const {paymentIntent} = state;
  const navigation = useNavigate();
  const userType = useSelector(state => state.userType.userType);
  const nextPage = () => {
    if (userType.payload === 'jobPoster') {
      navigation('../jobPoster/companies');
    } else if (userType.payload === 'jobSeeker') {
      navigation('../mainMenu');
      //navigation('../mainMenu');
    }
    // if (userType.payload === 'jobPoster') {
    // 	navigation('../currentJob');
    // } else if (userType.payload === 'jobSeeker') {
    // 	navigation('../mainMenu');
    // }
  };
  const dataLayer = window.dataLayer;
  if (dataLayer) {
    dataLayer.push({
      'transactionTotal': (paymentIntent.amount / 100).toFixed(2),
      'transactionCurrency': paymentIntent.currency.toUpperCase(),
      'transactionID': paymentIntent.id,
      'transactionPromoCode': null,
      'event': "awin.dl.ready",
      'pagePath': window.location.href,
      'pageUrl': window.location.href,
      'pageTitle': document.title
    });
  } else {
    console.error("dataLayer not found :(")
  }
  return (
    // style="padding:50px 0;"
    <section id='red-graphics-bg'>
      <div className='container'>
        <div className='row d-flex algin-items-center justify-content-center'>
          <div className='col-lg-9 text-center'>
            <img src={brand} alt='brand' className='w-80px' />
            <h3 style={{ fontSize: '2rem', lineHeight: '2.5rem' }}>Success</h3>
          </div>
        </div>

        <div className='row' style={{ marginTop: '-10%' }}>
          <div className='col-lg-2 col-md-2 col-sm-2' />
          <div className='col-lg-8 col-md-8 col-sm-8 text-center payment-success'>
            <h4>
              <span className='text-white'>Payment confirmed</span>
              <br />
              <span className='text-white' style={{fontSize:'.8em'}}>{paymentIntent.currency.toUpperCase()}&nbsp;{(paymentIntent.amount/100).toFixed(2)}</span>
              <br />
              <span style={{ fontSize: '1.5rem' }}>
                {userType.payload === 'jobSeeker' && 'Fantastic, your video will be shown to the Job matches'}
                {userType.payload === 'jobPoster' &&
                  'Continue to your home page and see your job matches ...'}
              </span>
            </h4>

            <br />
            <br />
            <p>
              <a href='javascript:void(0)' onClick={nextPage}>
                {' '}
                <img src={ArrowIcon} alt='right' />
              </a>
            </p>
          </div>

          <div className='col-lg-2 col-md-2 col-sm-2' />
        </div>
      </div>
    </section>
  );
}

export default PurchaseComplete;
