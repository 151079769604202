import React, {useEffect, useState} from "react";
import {useNavigate, useLocation, Link} from "react-router-dom";

import {toast} from "react-toastify";
import {useSelector} from "react-redux";

import ArrowIcon from "../../../assets/images/right-arrow.png";
import moment from "moment";
import axios from "axios";

import EditIcon from "../../../assets/images/edit-icon.png";

import {
	API_GET_POSTER_DETAIL,
	API_PERSONAL_INFO,
	// API_SUBMIT_JOBPOSTER_DATA,
	// API_SUBMIT_JOBSEEKER_DATA,
	API_UPDATE_ACTIVE_STATUS_FOR_JOB,
	BASE_URL,
	postApi,
	postApiPoster,
	API_HARD_STEP_DATA,
	API_SET_POINTS,
	API_UPDATE_JOB_DATA,
	DELETE_JOB,
} from "../../../utils";
import HeaderMenu from "../../../components/HeaderMenu/headerMenu";
import SideMenu from "../../../components/SideMenu/sideMenu";
import JobTitleUpdate from "../ProfileView/updateModal/JobTitleUpdate";
import JobRoleUpdate from "../ProfileView/updateModal/JobRoleUpdate";
import IndustryUpdate from "../ProfileView/updateModal/IndustryUpdate";
import EducationUpdate from "../ProfileView/updateModal/EducationUpdate";
import LocationUpdate from "../ProfileView/updateModal/LocationUpdate";
import CompanySizeUpdate from "../ProfileView/updateModal/CompanySizeUpdate";
import MinPayUpdate from "../ProfileView/updateModal/MinPayUpdate";
import SoftSkillUpdate1 from "../ProfileView/updateModal/SoftSkillUpdate1";
import HardSkillUpdate1 from "../ProfileView/updateModal/HardSkillUpdate1";
import Alert from "sweetalert2";
import GoBack from "../../../components/GoBack";

export default function JobDescription() {
	const [isLoading, setLoading] = useState(false);
	const {state} = useLocation();

	var jobId_data = localStorage.getItem("jobId");
	if (state) {
		if (state._id !== null) {
			jobId_data = state._id;
		}
	}

	const jobId = jobId_data;
	// console.log(jobId, 'jobId');
	const navigation = useNavigate();
	const userToken = useSelector(
		(state) => state.UserAuth.userData.payload.token
	);
	// console.log(userToken,"TOKEN");
	const userType = useSelector((state) => state.userType.userType);
	const userId = useSelector(
		(state) => state.UserAuth.userData.payload.user._id
	);
	const [jobData, setjobData] = useState([]);
	const [personInfo, setpersonInfo] = useState([]);
	//******Watch the current Index changes
	useEffect(() => {
		if (userType.payload === "jobSeeker") {
			requestDataFromServer1();
		} else {
			requestDataFromServer();
		}
	}, []);
	const requestDataFromServer = () => {
		setLoading(true);
		const obj = {
			usertype: 2,
			userId: userId,
			jobId: jobId,
		};
		postApiPoster(
			API_GET_POSTER_DETAIL,
			obj,
			userToken,
			successFunction,
			errorFunction
		);
	};
	const successFunction = (res) => {
		// console.log(res, "response____");
		if (res.status === 200) {
			setLoading(false);
			let data = res.data.result;
			// console.log(data, "dataa___");
			data?.map((item) => {
				if (item._id === jobId) {
					if (item?.poster[0].planCredit > 0) {
						setNext("../jobPoster/companies");
					}

					setjobData([item]);
					setJobDataForUpdate(item);
					setPrevSoftSkill(item?.softSkill);
					setPrevHardSkill(item?.hardSkill);
					setelevatorPitchValue(item?.companyDesc);
					setjobDescValue(item?.jobDesc);

					const obj = {
						result: item?.selfAssessmentResult,
					};
					postApi(
						API_HARD_STEP_DATA,
						obj,
						userToken,
						successFunctionForkeywords,
						errorFunction1
					);
				}
			});
		} else {
			setLoading(false);
			toast.error("Oops something went wrong!");
		}
	};
	// console.log(jobData, 'jobdayta');
	const errorFunction = (errr) => {
		setLoading(false);
		toast.error(errr.message);
	};

	const [keywords1, setkeywords1] = useState([]);
	const [keywords2, setkeywords2] = useState([]);

	const successFunctionForkeywords = (res) => {
		// console.log('this is the success result ', res.data);

		setkeywords1(res?.data?.ptb1);

		setkeywords2(res?.data?.whb1 ?? "");
	};
	const [next, setNext] = useState("../paymentOption");
	// console.log('Next Page', next);

	const nextPage = () => {
		navigation(next);
	};

	const requestDataFromServer1 = () => {
		setLoading(true);
		const obj = {
			usertype: 2,
			userId: userId,
		};
		postApiPoster(
			API_PERSONAL_INFO,
			obj,
			userToken,
			successFunction1,
			errorFunction1
		);
	};
	const successFunction1 = (res) => {
		if (res.status === 200) {
			setLoading(false);
			let data = res.data.result;

			data?.map((item) => {
				if (item._id === jobId) {
					setjobData([item]);
				}
			});
		} else {
			setLoading(false);
			toast.error("Oops something went wrong!");
		}
	};
	const errorFunction1 = (errr) => {
		setLoading(false);
		toast.error(errr?.message);
	};

	const [jobDataForUpdate, setJobDataForUpdate] = useState({});

	const [jobRoleModal, setJobRoleModal] = useState(false);
	const [jobTitleModal, setJobTitleModal] = useState(false);
	const [industryModal, setIndustryModal] = useState(false);
	const [educationModal, setEducationModal] = useState(false);
	const [skillLevelModal, setSkillLevelModal] = useState(false);
	const [locationModal, setLocationModal] = useState(false);
	const [companySizeModal, setCompanySizeModal] = useState(false);
	const [employmentTypeModal, setEmploymentTypeModal] = useState(false);
	const [minPayModal, setMinPayModal] = useState(false);
	const [hard1Modal, setHard1Modal] = useState(false);
	const [hard2Modal, setHard2Modal] = useState(false);
	const [soft1Modal, setSoft1Modal] = useState(false);
	const [soft2Modal, setSoft2Modal] = useState(false);
	const [prevSoftSkill, setPrevSoftSkill] = useState([]);
	const [prevHardSkill, setPrevHardSkill] = useState([]);

	const [editElevatorPitch, seteditElevatorPitch] = useState(false);
	const [editJobDesc, seteditJobDesc] = useState(false);

	const updatedSuccesfully = (response) => {
		// console.log("UPDATED SUCCESSFUL::::::", response);
		setLoading(false);
		seteditElevatorPitch(false);
		seteditJobDesc(false);
		const obj = {
			userId: userId,
			id: jobDataForUpdate._id,
		};
		if (response.status === 200) {
			if (userType.payload === "jobSeeker") {
				postApi(API_SET_POINTS, obj, userToken, () => {
				}, () => {
				});
			} else {
				postApiPoster(API_SET_POINTS, obj, userToken, () => {
				}, () => {
				});
			}
			setLoading(false);
			setTimeout(() => {
				requestDataFromServer();
			}, 1000);
		} else {
			setLoading(false);
		}
	};
	const errorWhileUpdate = (response) => {
		console.log("errorWhileUpdate::::::", response);
	};

	const updateProfileFunc = (field, value, field2, value2) => {
		setLoading(true);
		postApi(
			API_UPDATE_JOB_DATA,
			{[field]: value, [field2]: value2, _id: jobId},
			// { ...jobDataForUpdate, [field]: value, [field2]: value2 },
			userToken,
			updatedSuccesfully,
			errorWhileUpdate
		);
	};

	const [elevatorPitchValue, setelevatorPitchValue] = useState("");

	const updateElevatorPitch = () => {
		setLoading(true);
		postApi(
			BASE_URL + "api/jobposter/updateinfo",
			{userId, companyDesc: elevatorPitchValue},
			userToken,
			updatedSuccesfully,
			errorWhileUpdate
		);
	};

	const [jobDescValue, setjobDescValue] = useState("");

	const updateJobDesc = (id) => {
		setLoading(true);
		postApi(
			BASE_URL + "api/jobposter/updateJobDescofJob",
			{userId, id, jobDesc: jobDescValue},
			userToken,
			updatedSuccesfully,
			errorWhileUpdate
		);
	};

	const statusUpdated = (res) => {
		console.log("statusUpdated", res);

		toast("User Status Updated", {
			type: "success",
		});
	};
	const statusError = (error) => {
		console.log("statusError", error);
		toast("User Status Not Updated", {
			type: "error",
		});
	};

	const handleStatusChange = (isChecked) => {
		console.log("isChecked", isChecked);
		postApi(
			API_UPDATE_ACTIVE_STATUS_FOR_JOB,
			{jobId, status: isChecked},
			userToken,
			statusUpdated,
			statusError
		);
	};

	//delete job
	const deleteJob = async (jobId) => {
		Alert.fire({
			title: "Are you sure?",
			text: "You want to delete this job",
			icon: "question",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, Delete it!",
		}).then(async (result) => {
			if (result.isConfirmed) {
				const axiosConfig = {
					headers: {
						type: "front-end",
						Authorization: "Bearer " + userToken,
					},
				};
				try {
					const response = await axios.delete(
						`${DELETE_JOB}/${jobId}`,
						axiosConfig
					);
					console.log("response", response)
					if (response.status == 200) {
						Alert.fire("Deleted!", "Your data has been deleted.", "success");
					} else {
						Alert.fire("Oops", "Some error...", "error");
					}
					navigation("/homepage");
				} catch (error) {
					Alert.fire("Oops", "Some error...", "error");
				}
			}
		});
	};

	const renderItem = (item) => {
		//console.log('get item value', item._id);

		if (item._id === jobId) {
			const soft_skills = item.softSkill.map((data) => {
				return (
					<li
						style={{
							textAlign: "left",
							listStyle: "none",
						}}
					>
						{data.skill.option} ({data.skill.exp})
					</li>
				);
			});

			const hard_skills = item.hardSkill.map((data) => {
				return (
					<li style={{textAlign: "left", listStyle: "none"}}>
						{data.skill.option} ({data.skill.exp})
					</li>
				);
			});

			return (
				<React.Fragment>
					<GoBack/>
					<div className="col-lg-12 col-sm-12 col-xs-12 mircroshoft-cnt">
						<div className="row">
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<div className="mircroshoft-heading">
									<h4>
										{/* {item.poster[0]?.companyName} */}
										{/*Microsoft Development*/}
										{/* <i className="fa fa-heart" aria-hidden="true" /> */}
									</h4>
									<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
										<label className="steps-checkbox" style={{float: "right"}}>
											<span>Active</span>
											<input
												onChange={(e) => handleStatusChange(e.target.checked)}
												defaultChecked={item?.status}
												type="checkbox"
											/>
											<span className="checkmark"></span>
										</label>
									</div>
									<h6>{item.jobtitle[0]?.option} </h6>
									<p>

									</p>
								</div>
							</div>

							{/* <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="mircroshoft-logo text-right">
                <img
                  className="jobdetail_logo"
                  src={item?.poster[0]?.destination}
                />
              </div>
            </div> */}

							<br/>
							<br/>
							{/*<div className="col-lg-12 mt-4">*/}
							{/*	<h5>*/}
							{/*		Company Description*/}
							{/*		<span onClick={() => seteditElevatorPitch(!editElevatorPitch)}>*/}
							{/*			<img*/}
							{/*				src={EditIcon}*/}
							{/*				style={{height: "20px", objectFit: "contain"}}*/}
							{/*			/>*/}
							{/*		</span>*/}
							{/*		{editElevatorPitch ? (*/}
							{/*			<div>*/}
							{/*				<textarea*/}
							{/*					type="text"*/}
							{/*					defaultValue={item?.companyDesc}*/}
							{/*					onChange={(e) => setelevatorPitchValue(e.target.value)}*/}
							{/*					style={{width: "100%", minHeight: "100px"}}*/}
							{/*				/>*/}
							{/*				<button*/}
							{/*					className="form-btn mt-4"*/}
							{/*					onClick={() => updateElevatorPitch()}*/}
							{/*				>*/}
							{/*					{" "}*/}
							{/*					Update{" "}*/}
							{/*				</button>*/}
							{/*			</div>*/}
							{/*		) : (*/}
							{/*			<p>{item?.companyDesc}</p>*/}
							{/*		)}*/}
							{/*	</h5>*/}
							{/*</div>*/}

							<div className="col-lg-12 mt-4">
								<h5>
									Job description
									<span onClick={() => seteditJobDesc(!editJobDesc)}>
										<img
											src={EditIcon}
											style={{height: "20px", objectFit: "contain"}}
										/>
									</span>
									{editJobDesc ? (
										<div>
											<textarea
												type="text"
												defaultValue={item?.jobDesc}
												onChange={(e) => setjobDescValue(e.target.value)}
												style={{width: "100%", minHeight: "100px"}}
											/>
											<button
												className="form-btn mt-4"
												onClick={() => updateJobDesc(item._id)}
											>
												{" "}
												Update{" "}
											</button>
										</div>
									) : (
										<p>{item?.jobDesc}</p>
									)}
								</h5>
							</div>

							<div className="col-lg-12 mt-4">
								<h5>
									What kind of culture are you hiring for?{" "}
									<span onClick={() => setJobRoleModal(true)}>
										<img
											src={EditIcon}
											style={{height: "20px", objectFit: "contain"}}
										/>
									</span>
								</h5>
								<p>{item?.role[0].option}</p>
								<JobRoleUpdate
									jobRole={item?.role[0].option}
									jobRoleModal={jobRoleModal}
									setJobRoleModal={setJobRoleModal}
									updateProfileFunc={updateProfileFunc}
								/>
							</div>
							<div className="col-lg-12 mt-4">
								<h5>
									What job title best suits the postion{" "}
									<span onClick={() => setJobTitleModal(true)}>
										<img
											src={EditIcon}
											style={{height: "20px", objectFit: "contain"}}
										/>
									</span>
								</h5>
								<p>{item?.jobtitle[0].option}</p>
								<JobTitleUpdate
									prevJobTitle={item?.jobtitle[0].option}
									jobTitleModal={jobTitleModal}
									setJobTitleModal={setJobTitleModal}
									updateProfileFunc={updateProfileFunc}
								/>
							</div>
							<div className="col-lg-12 mt-4">
								<h5>
									Industry{" "}
									<span onClick={() => setIndustryModal(true)}>
                  <img
					  src={EditIcon}
					  style={{height: "20px", objectFit: "contain"}}
				  />
                </span>
								</h5>
								<p>{item?.industry[0]?.option}</p>
								<IndustryUpdate
									prevIndustry={item?.industry[0]?.option}
									industryModal={industryModal}
									setIndustryModal={setIndustryModal}
									updateProfileFunc={updateProfileFunc}
								/>
							</div>
							<div className="col-lg-12 mt-4">
								<h5>
									Education{" "}
									<span onClick={() => setEducationModal(true)}>
                  <img
					  src={EditIcon}
					  style={{height: "20px", objectFit: "contain"}}
				  />
                </span>
								</h5>
								<p>{item?.education[0]?.option}</p>
								<EducationUpdate
									prevEducation={item?.education[0].option}
									educationModal={educationModal}
									setEducationModal={setEducationModal}
									updateProfileFunc={updateProfileFunc}
								/>
							</div>
							<div className="col-lg-12 mt-4">
								<h5>
									Location{" "}
									<span onClick={() => setLocationModal(true)}>
                  <img
					  src={EditIcon}
					  style={{height: "20px", objectFit: "contain"}}
				  />
                </span>
								</h5>
								<p className={'capitalize'}>
									{`${item?.locationName ?? ''}`}
								</p>
								<LocationUpdate
									prevLocation={`${item?.locationName ?? ''}`}
									locationModal={locationModal}
									setLocationModal={setLocationModal}
									updateProfileFunc={updateProfileFunc}
								/>
							</div>

							{/*<div className="col-lg-12 mt-4">*/}
							{/*	<h5>*/}
							{/*		Company size{" "}*/}
							{/*		<span onClick={() => setCompanySizeModal(true)}>*/}
							{/*			<img*/}
							{/*				src={EditIcon}*/}
							{/*				style={{height: "20px", objectFit: "contain"}}*/}
							{/*			/>*/}
							{/*		</span>*/}
							{/*	</h5>*/}
							{/*	<p>{item.campanySize}</p>*/}
							{/*	<CompanySizeUpdate*/}
							{/*		prevCompanySize={item.campanySize}*/}
							{/*		companySizeModal={companySizeModal}*/}
							{/*		setCompanySizeModal={setCompanySizeModal}*/}
							{/*		updateProfileFunc={updateProfileFunc}*/}
							{/*	/>*/}
							{/*</div>*/}

							<div className="col-lg-12 mt-4">
								<h5>
									Maximum Salary{" "}
									<span onClick={() => setMinPayModal(true)}>
                  <img
					  src={EditIcon}
					  style={{height: "20px", objectFit: "contain"}}
				  />
                </span>
								</h5>
								<p>{item.employmentMode}</p>
								<MinPayUpdate
									prevMinPay={item.employmentMode}
									minPayModal={minPayModal}
									setMinPayModal={setMinPayModal}
									updateProfileFunc={updateProfileFunc}
								/>
							</div>

							{/* <div className="col-lg-3 col-md-4 col-xs-6 mt-4">
              <h5>Start date</h5>
              <p>
                {moment(item.startDate).format("YYYY/MM/DD")}
              </p>
            </div> */}

							<div className="col-lg-12 mt-2">
								<h5>
									Soft skills
									<span onClick={() => setSoft1Modal(true)}>
                  <img
					  src={EditIcon}
					  style={{height: "20px", objectFit: "contain"}}
				  />
                </span>
								</h5>
								<ul style={{paddingLeft: "0px"}}>{soft_skills}</ul>
								<SoftSkillUpdate1
									prevSoftSkill={prevSoftSkill}
									soft1Modal={soft1Modal}
									setSoft1Modal={setSoft1Modal}
									updateProfileFunc={updateProfileFunc}
								/>
							</div>

							<div className="col-lg-12 mt-2">
								<h5>
									Hard Skills
									<span onClick={() => setHard1Modal(true)}>
                  <img
					  src={EditIcon}
					  style={{height: "20px", objectFit: "contain"}}
				  />
                </span>
								</h5>
								<ul style={{paddingLeft: "0px"}}>{hard_skills}</ul>
								<HardSkillUpdate1
									prevHardSkill={prevHardSkill}
									hard1Modal={hard1Modal}
									setHard1Modal={setHard1Modal}
									updateProfileFunc={updateProfileFunc}
								/>
							</div>

							<div className="col-lg-12 mt-4">
								<h5>Job Persona</h5>
							</div>

							<div className="col-lg-8 mt-2">
								<h5>Description</h5>
								<p>
									Common character traits for individuals who fit the{" "}
									{item?.selfAssessmentResult}. Personality Type Includes:{" "}
									{keywords1?.map((e) => e + ",")}
									They are strong in {keywords2[0] && ""}.
								</p>
							</div>

							<div className="col-lg-8 mt-2">
								<h5>
									<div className="container">
										<div>
											<button
												className="form-btn mt-4 videoUploadBtn btn-warning"
												style={{background: "#ffe923"}}
												onClick={() => {
													navigation("../trackFeedback", {
														state: {feedback: item?.feedback},
													});
												}}
												// onClick={() => navigation('../boostProfile')}
											>
												Track User Feedback
											</button>
										</div>
										<div>
											<button
												className="form-btn mt-4 btn-warning"
												style={{background: "#ff0e0e"}}
												onClick={() => {
													deleteJob(jobId);
												}}
											>
												Delete Job
											</button>
										</div>
									</div>
									{/* <h3  onClick={() => {
                  navigation('../trackFeedback', {
                    state: { feedback: item?.feedback },
                  });
                }} className='text-dark'>
                  Track User Feedback
                </h3> */}
								</h5>
								{/*{item?.feedback?.map((e) => (*/}
								{/*	<h5>{e.seekerFeedBack} </h5>*/}
								{/*))}*/}
							</div>
						</div>
					</div>
				</React.Fragment>
			);
		}
	};

	return (
		<React.Fragment>
			{isLoading && (
				<div className="d-flex justify-content-center align-items-center LoaderCenter">
					{" "}
					<div className="block">
            <span
				className="spinner-grow spinner-grow-lg mx-2"
				role="status"
				aria-hidden="true"
			/>
						<h6 className="LoaderText">Processing...</h6>
					</div>
				</div>
			)}
			{!isLoading && (
				<>
					<HeaderMenu/>

					<section id="dashboard-cnt-area">
						<div className="container">
							<div className="row dashboard-cnt-row">
								{/* <img className="img-fluid eagle-man" src={EagleMan} /> */}

								{jobData && jobData?.map((item) => renderItem(item))}
							</div>
							<div className="row">
								<div className="col-lg-12 text-center">
									<a  onClick={() => nextPage()}>
										{/* onClick={nextPage} */}{" "}
										{/* <img src={ArrowIcon} alt="right" /> */}
									</a>
								</div>
							</div>
						</div>
					</section>
				</>
			)}
		</React.Fragment>
	);
}
