import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ArrowIcon from '../../../assets/images/right-arrow.png';
import Blk from '../../../assets/images/logo-icon-blk.png';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { saveUserInfo } from '../../../redux/actions';
import {
  API_HARD_STEP_DATA, getApi, postApi,
  API_COMMON_ONBOARDING_STEPS
} from '../../../utils';
import GoBack from '../../../components/GoBack';

function HardStepTwo() {
  const navigation = useNavigate();
  const [checkedItems, setCheckedItems] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const { state } = useLocation();
  const jobId = state.jobId;
  const userToken = useSelector(state => state.UserAuth.userData.payload.token);
  const userType = useSelector(state => state.userType.userType);
  const userId = useSelector(state => state.UserAuth.userData.payload.user._id);

  //***************************Get checked list items************************* */
  const getChekedItems = item => {
    // console.log(item, 'itemsss');
    if (checkedItems.includes(item)) {
      const index = checkedItems.indexOf(item);
      checkedItems.splice(index, 1);

      setCheckedItems([...checkedItems]);
    } else {
      if (checkedItems.length < 3) {
        setCheckedItems([...checkedItems, item]);
      } else {
        toast.error('You can select only 3 options!', {
          position: 'top-right',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
  const [stepData, setstepData] = useState([]);
  useEffect(() => {
    requestDataFromServer1();
  }, [checkedItems]);
  const redirectToNextPage = () => {
    if (checkedItems.length === 3) {
      updateOnBoardingSteps(checkedItems)
      // navigation('../hardStep3', {
      //   state: { ...routerData, feelsuits2: checkedItems },
      // });
    } else {
      toast.error('Opps! you forgot to select items', {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // Get step data...........

  const user_result = sessionStorage.getItem('UserResult');
  //console.log(user_result,'result=====');

  const requestDataFromServer1 = () => {
    const obj = {
      result: user_result,
    };
    postApi(API_HARD_STEP_DATA, obj, userToken, successFunction1, errorFunction1);
  };
  const successFunction1 = res => {
    setstepData(res.data.ptb2);
  };
  const errorFunction1 = errr => {
    console.log(errr, 'erorr');
  };


  const updateOnBoardingSteps = (feelsuits2) => {

    const obj = {
      jobId: jobId,
      "userId": userId,
      "feelsuits2": feelsuits2,
      "userType": userType.payload,
      "stepNumber": 13,
      // "_id": "642d1ab16fa8534d104c5a20"
    };
    postApi(API_COMMON_ONBOARDING_STEPS, obj, userToken, successFunctionOnboardingSteps, errorSavingData);


  }
  const userAuthInfo = useSelector(state => state.UserAuth);
  const dispatch = useDispatch();

  const successFunctionOnboardingSteps = res => {

    setLoading(false);

    if (res.status === 200) {

      // dispatch the selfAssessmentstep
      var existingUserInfo = userAuthInfo.userData?.payload;
      existingUserInfo.user.selfAssessmentResultSteps = 13;

      dispatch(
        saveUserInfo({
          payload: existingUserInfo,
        })
      );

      navigation('../hardStep3', {state: {jobId: jobId}});

    }

  }

  const errorSavingData = err => {
    toast.error(err.data.message, {
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };


  const renderItem = (item, index) => {
    return (
      <div className='col-lg-4 col-sm-6 col-12'>
        <div className='inputGroup'>
          <input
            id={`check${index + 1}`}
            name='checkbox'
            type='checkbox'
            readOnly
            checked={checkedItems.includes(item)}
          />
          <label htmlFor={`check${index + 1}`} onClick={() => getChekedItems(item)}>
            {item}
          </label>
        </div>
      </div>
    );
  };

  return (
    <section id='feel-suite-best'>
      <GoBack />
      <div className='container'>
        <div className='row d-flex algin-items-center justify-content-center'>
          <div className='col-lg-6 text-center'>
            <img src={Blk} alt='blk' className='w-80px' />
            <h3 style={{ fontSize: '2rem', lineHeight: '2.5rem' }}>
              Select 3 that you feel suits you the best
            </h3>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-12 suite-best-wrapper yellow-hvr-checkbox'>
            <div className='row text-capitalize d-flex justify-content-center'>
              {stepData.map((item, index) => renderItem(item, index))}
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-12 text-center'>
            <a href='javascript:void(0)' onClick={redirectToNextPage}>
              {' '}
              <img src={ArrowIcon} alt='right' />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HardStepTwo;
