import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { persistStore } from "redux-persist";
import rootReducers from "../redux/reducers"
import { composeWithDevTools } from 'redux-devtools-extension';

const middlewares = [thunk];
if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger);
}
const store = createStore(rootReducers, composeWithDevTools(applyMiddleware(...middlewares)));
const persistor = persistStore(store);

export { store, persistor };
