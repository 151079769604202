import HeaderMenu from "../../../components/HeaderMenu/headerMenu";
import FooterSection from "../../../components/Footer/footerSection";
import {Outlet} from "react-router-dom";

function JobPoster() {

	return (
		<div>
			<HeaderMenu/>
			<div>
				<Outlet/>
			</div>
			<FooterSection/>
		</div>
	);
}

export default JobPoster;