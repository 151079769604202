import React, { useState } from 'react';

import { Modal } from 'react-bootstrap';
import LoginPopUpImage from '../../../assets/images/login-popup-img-1.png';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
export default function ModalStepOne(props) {
  const navigate = useNavigate();
  const choosenOption = useSelector(state => state.optionSelected.optionSelected);

  const stepTwo = () => {
    props.nextstep();
  };
  const skipAndNavigate = () => {
    props.hideone();
    if (choosenOption.payload === 'Sign Up') {
      navigate('../signup');
    } else {
      navigate('../login');
    }
  };
  return (
    <Modal
      style={{ paddingBottom: '20px' }}
      show={props.showone}
      onHide={props.hideone}
      size='lg'
      backdrop='static'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton className='modal-close-stepone' />
      <Modal.Body style={{ marginTop: '-20px', padding: '0px' }}>
        <div>
          <div>
            <form id='msform'>
              <fieldset className='login-pop-step-1'>
                <div className='form-card'>
                  <div className='login-pop-cnt'>
                    <div className='imageContainer'>
                      <img className='img-fluid' src={LoginPopUpImage} />
                    </div>
                    <div className='modal-content-wrapper'>
                      <h3>We look beyond your skills and experience</h3>
                      <p className='modal-text'>
                        Your personality is what makes you special and unique. Knowing what
                        personality type you are helps us identify the ideal job and work
                        environment for you.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='background-match'>
                  <input
                    type='button'
                    name='next'
                    className='next action-button'
                    onClick={stepTwo}
                  />
                  <br />
                  <label
                    type='button'
                    className='modal-btn'
                    style={{ fontSize: '0.8rem' }}
                    onClick={skipAndNavigate}
                  >
                    Skip
                  </label>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
