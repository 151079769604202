import HeaderMenu from './../../../components/HeaderMenu/headerMenu';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { API_GET_POSTER_DETAIL, postApiPoster } from '../../../utils';
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
// import SideMenu from '../../../';
import SideMenu from '../../../components/SideMenu/sideMenu';
import PlusBtn from '../../../assets/images/plus-btn.png';


export default function JobsHomePage() {
    const navigate = useNavigate();

    const [addedJobList, setaddedJobList] = useState([]);
    const jobId = localStorage.getItem('jobId');
    const [loading, setLoading] = useState(true);

    const userToken = useSelector(state => state.UserAuth.userData.payload.token);
    const userId = useSelector(state => state.UserAuth.userData.payload.user._id);

    const requestDataFromServer = () => {
        const obj = {
            usertype: 2,
            userId: userId,
            jobId: jobId,
        };
        postApiPoster(API_GET_POSTER_DETAIL, obj, userToken, successFunction, errorFunction);
    };
    const successFunction = res => {
        console.log(res, 'res');
        setLoading(false);
        if (res.status === 200) {
            setaddedJobList(res.data.result);
        }
        setaddedJobList(res.data.result);
    };
    const errorFunction = errr => {
        toast.error('Oops! something went wrong!', {
            position: 'top-right',
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    useEffect(() => {
        requestDataFromServer();
    }, []);


    return (
        <div>
            <HeaderMenu />
            {loading ? (
                <div className='d-flex justify-content-center align-items-center LoaderCenter'>
                    {' '}
                    <div className='block'>
                        <span className='spinner-grow spinner-grow-lg mx-2' role='status' aria-hidden='true' />
                        <h6 className='LoaderText'>Processing...</h6>
                    </div>
                </div>
            ) : (
                <>
                    <section id='dashboard-cnt-area' className='dashboard-bg'>
                        <div className='container'>
                            <div className='row dashboard-cnt-row d-flex align-items-center justify-content-center'>


                                <div className='col-lg-9 col-sm-8 col-xs-12 mircroshoft-cnt '>
                                    <div className='row '>
                                        <div className='col-lg-12 recent-page-heading-jp mb-4'>
                                            <br />
                                            <a href='javascript:void(0)'>Manage Current Jobs</a>
                                        </div>
                                    </div>

                                    <div className='row mt-5 pb-5'>
                                        {addedJobList?.length > 0 &&
                                            addedJobList?.map((res, i) => (
                                                <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-4'>

                                                    <div
                                                        className='current-job-col'
                                                        onClick={() => navigate('../jobDescription', { state: res })}
                                                    >
                                                        <div className='current-job-col-text'>
                                                            {res.jobtitle.map(tl => (
                                                                <h5>{tl.option}</h5>
                                                            ))}
                                                            <p className={'capitalize'}>{res.locationName ?? ''}</p>
                                                        </div>
                                                    </div>


                                                </div>
                                            ))}
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 mt-5 text-center mb-5'>
                                            <a href='javascript:void(0)' onClick={() => navigate('../jobRole')}>
                                                <img src={PlusBtn} />{' '}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )
            }
        </div >
    )


}
