import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

function PrivateJobSeekerRoute({ isAuthenticated, userType }) {
    if (!isAuthenticated) return (<Navigate to="/" />)
    if (userType !== 'jobSeeker') {
        return (<Navigate to="/jobPoster/companies"/>);
    }
    return <Outlet />
}

export default PrivateJobSeekerRoute