import { TYPES } from './actionTypes';
// action for calling set userType in reducer
const userType = (payload) => {

	return {
		type: TYPES.USER_TYPE,
		payload,
	}


};


export { userType };
