import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import brand from "../../../assets/images/logo-icon-blk.png";
import { useSelector, useDispatch } from "react-redux";
import {
  API_SEEKER_JOB_ROLE_OPTIONS,
  API_SEEKER_JOB_ROLE_TITLE,
  API_SEEKER_ADD_JOB_ROLE,
  postApi,
  postApiPoster,
  getApi,
  SOFT_AND_HARD_SKILLS_LIST,
  API_COMMON_ONBOARDING_STEPS,

} from "../../../utils";
import { toast } from "react-toastify";
import GoBack from "../../../components/GoBack";
import { saveUserInfo } from '../../../redux/actions';
import Fuse from 'fuse.js';

function SoftSkillTwo() {
  const { state } = useLocation();
  const jobId = state.jobId;
  const [softSkillsList, setSoftSkillsList] = useState([]);
  const [jobSeekerTitle, setJobSeekerTitle] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isFetchedData, setFetchedData] = useState(false);
  const navigation = useNavigate();
  const [userCreatedRole, setUserCreatedRole] = useState("");
  const userToken = useSelector(
    (state) => state.UserAuth.userData.payload.token
  );
  const userType = useSelector((state) => state.userType.userType);
  const [isAddButton, setAddButton] = useState(false);
  const [currentAddedSkill, setCurrentAddedSkill] = useState([]);
  const [softSkillSelectedForInputUI, setSoftSkillSelectedForInputUI] =
    useState("");
  const [onTapSuggestionCalled, setonTapSuggestionCalled] = useState(false);
  const userId = useSelector(state => state.UserAuth.userData.payload.user._id);

  const fuseOptions = {
    keys: ['name'], // Define the property to search within the objects (here we use 'name')
    threshold: 0.3, // Adjust the threshold for fuzzy matching (0.0 to 1.0)
  };

  //********************************************call api*************************************************************//
  const requestDataFromServer = () => {
    setLoading(true);

    const obj = {
      category: "softskill",
    };
    if (userType.payload === "jobSeeker") {
      postApi(
        API_SEEKER_JOB_ROLE_TITLE,
        obj,
        userToken,
        successFunction,
        errorFunction
      );
      postApi(
        API_SEEKER_JOB_ROLE_OPTIONS,
        obj,
        userToken,
        successFunctionOptions,
        errorFunctionOptions
      );
    } else {
      postApiPoster(
        API_SEEKER_JOB_ROLE_TITLE,
        obj,
        userToken,
        successFunction,
        errorFunction
      );
      postApiPoster(
        API_SEEKER_JOB_ROLE_OPTIONS,
        obj,
        userToken,
        successFunctionOptions,
        errorFunctionOptions
      );
    }
  };
  const successFunction = (response) => {
    setFetchedData(true);
    setLoading(false);
    if (response.status === 200) {
      console.log(response.data.result, "title");
      setJobSeekerTitle(response.data.result.question);
    }
  };
  const errorFunction = (error) => {
    setLoading(false);
    setFetchedData(true);
    console.log(error, "drerer");
  };
  const successFunctionOptions = (response) => {
    console.log(response, "companySize");
    setLoading(false);
    setFetchedData(true);

    if (response.status === 200) {
      let dataWithExperienceValue = [];
      let dataComingIntial = response.data.result;
      dataComingIntial.map((data) =>
        dataWithExperienceValue.push({
          _id: data._id,
          option: data.option,
          experience: 0,
        })
      );
      setSoftSkillsList(dataWithExperienceValue);
    }

    // console.log(response);
  };
  const errorFunctionOptions = (error) => {
    setLoading(false);
    setFetchedData(true);
    console.log(error);
  };

  useEffect(() => {
    if (!isFetchedData) {
      requestDataFromServer();
    }
  }, [isFetchedData]);
  //*******Save user data in server and naviagte to another page***********************************//
  const savingInfo = () => {
    if (currentAddedSkill.length > 0) {
      if (currentAddedSkill.length > 0) {
        const skill = [];
        currentAddedSkill.map((data) =>
          skill.push({
            id: data._id,
            skill: {
              explevel: data.level,
              option: data.option,
              exp: data.experience + " years",
            },
          })
        );
        // console.log('get iamge-----', skill);
        updateOnBoardingSteps(skill);
        // navigation("../roleStepCompleted", {
        //   state: { ...routerData, softSkill: skill },
        // });
      }
    } else {
      toast.error("Please add skill!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const getValue = (data, value) => {
    let newData = [];
    //Update with new value
    var index = currentAddedSkill.findIndex((item) => item.option === data);
    currentAddedSkill.map((el) => {
      if (el.option == data) {
        if (value <= 2) {
          currentAddedSkill[index].level = "Entry";
          const userValue = 2;
          const range = "0-";
          const append = range.concat(userValue);

          currentAddedSkill[index].experience = append;
          currentAddedSkill[index].scrollValue = 2;
        } else if (value > 2 && value < 5) {
          currentAddedSkill[index].level = "Mid";
          const userValue = 5;
          const range = "3-";
          const append = range.concat(userValue);
          currentAddedSkill[index].experience = append;
          currentAddedSkill[index].scrollValue = 6;
        } else if (value > 5 && value < 9) {
          currentAddedSkill[index].level = "Senior";
          const userValue = 9;
          const range = "6-";
          const append = range.concat(userValue);
          currentAddedSkill[index].experience = append;
          currentAddedSkill[index].scrollValue = 9;
        } else if (value > 10) {
          currentAddedSkill[index].level = "Expert";
          currentAddedSkill[index].experience = "10+";
          currentAddedSkill[index].scrollValue = 12;
        }
        setCurrentAddedSkill([...currentAddedSkill]);
      }
    });
  };


  useMemo(() => {
    if (userCreatedRole !== "") {
      const userSearch = userCreatedRole.toLowerCase();
      const data = softSkillsList.filter(
        (data) => data.option.toLowerCase() === userSearch
      );
      // setUserCreatedRole('');
      if (data.length === 0) {
        setAddButton(true);
      } else {
        setAddButton(false);
        const check = currentAddedSkill.findIndex(
          (e) => e.option.toLowerCase() === userSearch.toLowerCase()
        );
        if (check > -1) {
          toast.error("This skill is already in list!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          setCurrentAddedSkill([...currentAddedSkill, ...data]);
        }
      }
    }
  }, [onTapSuggestionCalled]);
  //***********************************Add new skills*****************************************************///
  const addNewSkill = (e) => {
    e.preventDefault();
    console.log(userCreatedRole, userType.payload, "add skill");
    console.log("currentAddedSkill", currentAddedSkill)
    if (currentAddedSkill.filter((skill) => skill.option == userCreatedRole.toLowerCase()).length == 0) {
      if (userCreatedRole) {
        setLoading(true);
        if (userType.payload === "jobSeeker") {
          const obj = {
            option: userCreatedRole,
            category: "softskill",
            usertype: 1,
            status: "2",
            userId: userType.payload._id,
            addedby: "2",
          };
          postApi(
            API_SEEKER_ADD_JOB_ROLE,
            obj,
            userToken,
            addHardSkillSuccess,
            addHardSkillError
          );
        } else {
          const obj = {
            option: userCreatedRole,
            category: "softskill",
            usertype: 2,
            status: "2",
            userId: userType.payload._id,
            addedby: "2",
          };
          postApi(
            API_SEEKER_ADD_JOB_ROLE,
            obj,
            userToken,
            addHardSkillSuccess,
            addHardSkillError
          );
        }
      }
      setSoftSkillSelectedForInputUI("");
    } else {
      setLoading(false);
      toast.error("skill already added", {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const addHardSkillSuccess = (res) => {
    if (res.status === 200) {
      setLoading(false);

      toast.success("Your skill added succefully!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      const userValue = 2;
      const range = "0-";
      const append = range.concat(userValue);
      const data = {
        addedby: "3",
        category: "hardskill",
        name: "Unknown",
        option: userCreatedRole,
        status: 1,
        usertype: "1",
        _id: res.data.data._id,
        level: "Entry",
        experience: append,
        scrollValue: 0,
      };
      setCurrentAddedSkill([...currentAddedSkill, data]);
      setUserCreatedRole("");
      setAddButton(false);

      setSoftSkillsList([...softSkillsList, data]);
    }
  };
  const addHardSkillError = (err) => {
    setLoading(false);
    toast.error(err.response.message, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const successCallback = (data) => {
    var skills = [];
    for (var i = 0; i < data.data.result.length; i++) {
      skills.push(data?.data?.result[i].option);
    }
    setSoftSkillLists(skills);

    // setHardSkillLists(data?.data?.hardskills);
    // setSoftSkillLists(data?.data?.softskills);
  };
  const errorCallback = (error) => {
    console.log("error-------", error);
  };

  const handleKeyDown = event => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }

  const [softSkillLists, setSoftSkillLists] = useState([]);
  const [selectedFromSuggestion, setSelectedFromSuggestion] = useState("");
  const [suggestionOff, setSuggestionOff] = useState(false);


  // useEffect(() => {
  //   const obj = {
  //     category: "softskill",
  //   };
  //   postApi(
  //     API_SEEKER_JOB_ROLE_OPTIONS,
  //     obj,
  //     userToken,
  //     successCallback,
  //     errorCallback
  //   );
  // }, []);

  useEffect(() => {
    if (userCreatedRole) {
      setSelectedFromSuggestion("");
    }
  }, [userCreatedRole, selectedFromSuggestion]);

  // const fuse = new Fuse(softSkillLists, fuseOptions);

  // const [matchesSkills, setmatchesSkills] = useState([]);


  // useEffect(() => {

  //   if (userCreatedRole != '') {
  //     const searchResults = userCreatedRole ? fuse.search(userCreatedRole) : softSkillLists;
  //     var temp = [];
  //     for (var i = 0; i < searchResults?.length; i++) {
  //       temp.push(searchResults[i].item);
  //     }
  //     setmatchesSkills(temp)
  //   }

  // }, [userCreatedRole])


  const updateOnBoardingSteps = (softskills) => {
    const obj = {
      jobId: jobId,
      "userId": userId,
      "softSkill": softskills,
      "userType": userType.payload,
      "stepNumber": 10,
      // "_id": "642d1ab16fa8534d104c5a20"
    };
    postApi(API_COMMON_ONBOARDING_STEPS, obj, userToken, successFunctionOnboardingSteps, errorSavingData);
  }
  const userAuthInfo = useSelector(state => state.UserAuth);
  const dispatch = useDispatch();

  const successFunctionOnboardingSteps = res => {

    setLoading(false);

    if (res.status === 200) {

      // dispatch the selfAssessmentstep
      var existingUserInfo = userAuthInfo.userData?.payload;
      existingUserInfo.user.selfAssessmentResultSteps = 10;

      dispatch(
        saveUserInfo({
          payload: existingUserInfo,
        })
      );

      navigation('../roleStepCompleted', {state: {jobId: jobId}});


    }

  }

  const errorSavingData = err => {
    toast.error(err.data.message, {
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };




  return (
    <React.Fragment>
      {isLoading && (
        <div className="d-flex justify-content-center align-items-center LoaderCenter">
          {" "}
          <div className="block">
            <span
              className="spinner-grow spinner-grow-lg mx-2"
              role="status"
              aria-hidden="true"
            />
            <h6 className="LoaderText">Processing...</h6>
          </div>
        </div>
      )}
      {!isLoading && (
        <section id="soft-skills-steps">
          <GoBack />
          <div className="discover-personality-top-cnt">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center">
                <div className="col-lg-8  text-center skills-step-des">
                  <img src={brand} alt="brand" className="w-80px" />
                  <h3>
                    Add up to 10 soft skills and rate your proficiency in them.
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-4">
            <div className="row justify-content-center">
              <div className="col-lg-2" />
              <div className="col-lg-8">
                <div className="card" style={{ borderWidth: 0 }}>
                  {/* <form id="msform" > */}
                  <form id="msform" onSubmit={addNewSkill}>
                    <fieldset>
                      <div className="form-card">
                        <div className="enter-hard-skills">
                          <h5>
                            <strong>Soft skills</strong> are the personal habits
                            and traits that shape how you work on your own and
                            with others.
                          </h5>
                          <div
                            className="d-flex"
                            style={{ position: "relative" }}
                          >
                            <input
                              type="text"
                              id="name"
                              name="name"
                              placeholder="Type your soft skills here..."
                              required=""
                              value={softSkillSelectedForInputUI}
                              onFocus={(event) => {
                                event.target.setAttribute(
                                  "autocomplete",
                                  "off"
                                );
                              }}
                              onKeyDown={handleKeyDown}
                              onChange={(e) => {

                                const { value } = e.target;
                                const regex = /^[a-zA-Z\s]*$/;
                                console.log("value", value);

                                if (regex.test(value)) {
                                  setAddButton(true);
                                  setUserCreatedRole(e.target.value);
                                  setSuggestionOff(false);
                                  setSoftSkillSelectedForInputUI(e.target.value);
                                }
                                if (value === '') {
                                  setAddButton(false);
                                }


                              }}
                            />

                            {isAddButton && (
                              <button
                                className="mx-1 btn btn-outline-primary"
                                type="submit"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-plus-square-dotted"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M2.5 0c-.166 0-.33.016-.487.048l.194.98A1.51 1.51 0 0 1 2.5 1h.458V0H2.5zm2.292 0h-.917v1h.917V0zm1.833 0h-.917v1h.917V0zm1.833 0h-.916v1h.916V0zm1.834 0h-.917v1h.917V0zm1.833 0h-.917v1h.917V0zM13.5 0h-.458v1h.458c.1 0 .199.01.293.029l.194-.981A2.51 2.51 0 0 0 13.5 0zm2.079 1.11a2.511 2.511 0 0 0-.69-.689l-.556.831c.164.11.305.251.415.415l.83-.556zM1.11.421a2.511 2.511 0 0 0-.689.69l.831.556c.11-.164.251-.305.415-.415L1.11.422zM16 2.5c0-.166-.016-.33-.048-.487l-.98.194c.018.094.028.192.028.293v.458h1V2.5zM.048 2.013A2.51 2.51 0 0 0 0 2.5v.458h1V2.5c0-.1.01-.199.029-.293l-.981-.194zM0 3.875v.917h1v-.917H0zm16 .917v-.917h-1v.917h1zM0 5.708v.917h1v-.917H0zm16 .917v-.917h-1v.917h1zM0 7.542v.916h1v-.916H0zm15 .916h1v-.916h-1v.916zM0 9.375v.917h1v-.917H0zm16 .917v-.917h-1v.917h1zm-16 .916v.917h1v-.917H0zm16 .917v-.917h-1v.917h1zm-16 .917v.458c0 .166.016.33.048.487l.98-.194A1.51 1.51 0 0 1 1 13.5v-.458H0zm16 .458v-.458h-1v.458c0 .1-.01.199-.029.293l.981.194c.032-.158.048-.32.048-.487zM.421 14.89c.183.272.417.506.69.689l.556-.831a1.51 1.51 0 0 1-.415-.415l-.83.556zm14.469.689c.272-.183.506-.417.689-.69l-.831-.556c-.11.164-.251.305-.415.415l.556.83zm-12.877.373c.158.032.32.048.487.048h.458v-1H2.5c-.1 0-.199-.01-.293-.029l-.194.981zM13.5 16c.166 0 .33-.016.487-.048l-.194-.98A1.51 1.51 0 0 1 13.5 15h-.458v1h.458zm-9.625 0h.917v-1h-.917v1zm1.833 0h.917v-1h-.917v1zm1.834-1v1h.916v-1h-.916zm1.833 1h.917v-1h-.917v1zm1.833 0h.917v-1h-.917v1zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                </svg>
                              </button>
                            )}
                          </div>
                        </div>

                        <div className="exp-range-slider">
                          {currentAddedSkill &&
                            currentAddedSkill.map((data, i) => (
                              <div key={i} className="skill-range-slider">
                                <div key={i + 1} className="slidecontainer">
                                  <h6 key={i + 2}>
                                    <button
                                      className="btn btn-danger"
                                      onClick={(e) => {
                                        var skills = [...currentAddedSkill];
                                        skills.splice(i, 1);

                                        setCurrentAddedSkill(skills);
                                        setSoftSkillSelectedForInputUI("");
                                      }}
                                    >
                                      <i className="bi bi-trash-fill"></i> X
                                    </button>
                                    &nbsp;
                                    {data.option}
                                  </h6>
                                  <input
                                    key={i + 3}
                                    type="range"
                                    min="0"
                                    max="12"
                                    step={4}
                                    defaultValue={
                                      data.scrollValue ? data.scrollValue : 0
                                    }
                                    onChange={(e) => {
                                      getValue(data.option, e.target.value);
                                    }}
                                    className="slider form-range"
                                    id="customRange2"
                                  />
                                  <p key={i + 4}>
                                    {data.level} (<span key={i + 5} id="demo" />
                                    {data.experience}&nbsp;years)
                                  </p>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>

                      <input type="button" name="next" className="next action-button" value="" onClick={savingInfo}/>
                    </fieldset>
                  </form>
                  <p className="text-start opacity-40 fs-6">
                    <ul>
                      <li>Communication: The ability to express ideas clearly and listen actively.</li>
                      <li>Teamwork: Working well with others to achieve a common goal.</li>
                      <li>Problem-solving: Finding solutions to challenges in a creative and efficient way.</li>
                      <li>Adaptability: Being flexible and open to change or new ways of doing things.</li>
                    </ul>
                  </p>
                </div>
              </div>
              <div className="col-lg-2" />
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
}

export default SoftSkillTwo;
