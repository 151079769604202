import React, { useState } from "react";
import FooterSection from "./../../../../components/Footer/footerSection";
import PlusBtn from "../../../../assets/images/plus-btn.png";
import SideMenu from "../../../../components/SideMenu/sideMenu";
import HeaderMenu from "./../../../../components/HeaderMenu/headerMenu";
import CardJobMatches from "../../../../components/cardJopPosterMatches/cardJobMatches";
import { useLocation } from "react-router-dom";
export default function CurrentMatches() {
  const { state } = useLocation();

  const [data] = useState(state);

  return (
    <div>
      <HeaderMenu />
      <section id="dashboard-cnt-area">
        <div className="container">
          <div className="row dashboard-cnt-row">
            {/* <img className="img-fluid eagle-man" src={Landscape} /> */}
            <SideMenu />

            <div className="col-lg-9 col-sm-8 col-xs-12 mircroshoft-cnt">
              <div className="row">
                <div className="col-lg-12 recent-page-heading-jp mb-1 text-left">
                  Current Matches 2
                </div>
              </div>

              <div className="row mt-5 pb-5">
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-4 ">
                  <CardJobMatches />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-4 ">
                  <CardJobMatches />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-4 ">
                  <CardJobMatches />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-4 ">
                  <CardJobMatches />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-4 ">
                  <CardJobMatches />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-4 ">
                  <CardJobMatches />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterSection />
    </div>
  );
}
