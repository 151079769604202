import React, { useState } from 'react';

import { Modal } from 'react-bootstrap';
import LoginPopUpImage from '../../../assets/images/login-popup-img-3.png';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
export default function ModalStepThree(props) {
  const navigate = useNavigate();
  const choosenOption = useSelector(state => state.optionSelected.optionSelected);

  const skipAndNavigate = () => {
    props.hidethree();
    if (choosenOption.payload === 'Sign Up') {
      navigate('../signup');
    } else {
      navigate('../login');
    }
  };
  return (
    <Modal
      style={{ paddingBottom: '20px' }}
      show={props.showthree}
      onHide={props.hidethree}
      size='lg'
      backdrop='static'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className='three-last-modal'
    >
      <Modal.Header closeButton className='modal-close' />
      <Modal.Body style={{ marginTop: '-20px', padding: '0px' }}>
        <div>
          <form id='msform'>
            <fieldset className='hand-logo' style={{ display: 'block', opacity: '1' }}>
              <div className='form-card'>
                <div className='login-pop-cnt'>
                  <img className='img-fluid' src={LoginPopUpImage} />
                  <div className='modal-content-wrap-two'>
                    <h3>Find your perfect job with Perspectv</h3>
                    <p className='modal-text'>
                      Our sophisticated algorithm uses your unique profile, skills, experience and
                      personality traits to find the perfect jobs for you
                    </p>
                  </div>
                </div>
              </div>
              <div className='py-3'>
                <input
                  type='button'
                  name='next'
                  className='next action-button'
                  value=''
                  onClick={props.showfour}
                />
                <br />
                <label
                  type='button'
                  className='modal-btn'
                  style={{ fontSize: '0.8rem' }}
                  onClick={skipAndNavigate}
                >
                  Skip
                </label>
              </div>
            </fieldset>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
