import React, { useState } from 'react';
import brand from '../../../assets/images/brand-logo.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API_POSTER_RESET_PASSWORD, API_SEEKER_UPDATE_PASSWORD, postApiWithoutHeader, postApiPosterWithoutHeader } from '../../../utils';
import { useSelector } from 'react-redux';

function ConfirmPassword() {
	const navigation = useNavigate();
	const [ password, setPassword ] = useState('');
	const state = useLocation();
	const [ userInfo ] = useState(state);
	const [ confirmPassword, setConfirmPassword ] = useState('');
	const [ isLoading, setLoading ] = useState(false);

	const userType = useSelector((state) => state.userType.userType);

	const changePaswword = (e) => {
		e.preventDefault();

		if (password !== '' && confirmPassword !== '') {
			if (password === confirmPassword) {
				const { email ,_id } = userInfo.state.obj;
					let obj = {
						email: email,
						_id:_id,
						password:password
					};
					console.log(obj);
				if (userType.payload === 'jobSeeker') {
					postApiWithoutHeader(API_SEEKER_UPDATE_PASSWORD, obj, successCallBack, errorCallBack);
				} else {
					postApiPosterWithoutHeader(API_POSTER_RESET_PASSWORD, obj, successCallBack, errorCallBack);
				}
			} else {
				toast.error('Password should be same!', {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined
				});
			}
		}
		toast.error('Please enter password and confirm password!', {
			position: 'top-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	};
	const successCallBack = (response) => {
		toast.success(response.data.message, {
			autoClose: 2000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
		navigation('../forgotSuccess');
	};

	const errorCallBack = (error) => {
		setLoading(false);

		toast.error(error.response.data.message, {
			position: 'top-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	};
	return (
		<section id="login-page">
			<div className="container">
				<div className="row">
					<div className="col-lg-2" />
					<div className="col-lg-8 text-center">
						<div className="brand-logo">
							<img className="img-fluid" src={brand} alt="brand" />
						</div>

						<form onSubmit={changePaswword}>
							<input
								className="form-control"
								type="password"
								placeholder="New Password"
								onChange={(e) => setPassword(e.target.value)}
							/>
							<input
								className="form-control"
								type="password"
								placeholder="Confirm Password"
								onChange={(e) => setConfirmPassword(e.target.value)}
							/>
							<h6>Your ID has been verified you can now change your password.</h6>
							<button className="form-btn" type="submit">
								Confirm
							</button>&gt;
						</form>

						<p className="login-signup-txt">
							Don’t have an account? <strong onClick={() => navigation('/signup')}>sign up</strong>
						</p>
					</div>
					<div className="col-lg-2" />
				</div>
			</div>
		</section>
	);
}

export default ConfirmPassword;
