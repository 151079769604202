import React, { useEffect, useState } from "react";
import FooterSection from "./../../../../components/Footer/footerSection";
import PlusBtn from "../../../../assets/images/plus-btn.png";
import EditButton from "../../../../assets/images/edit-icon.png";
import logo from "../../../../assets/images/right-arrow.png";
import SideMenu from "../../../../components/SideMenu/sideMenu";
import HeaderMenu from "./../../../../components/HeaderMenu/headerMenu";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { saveUserInfo } from "../../../../redux/actions";

import {
  API_GET_POSTER_DETAIL,
  postApiPoster,
  API_JOBSEEKER_MATCH,
  postApi,
  API_UPDATE_POSTER_JOB_ID,
} from "../../../../utils";
import { toast } from "react-toastify";

export default function MatchesFoundJobPoster() {
  const navigate = useNavigate();
  const jobId = localStorage.getItem("jobId");

  const [addedJobList, setaddedJobList] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigation = useNavigate();
  const userToken = useSelector(
    (state) => state.UserAuth.userData.payload.token
  );
  // console.log(userToken,"TOKEN");
  const userType = useSelector((state) => state.userType.userType);
  const userId = useSelector(
    (state) => state.UserAuth.userData.payload.user._id
  );
  const { state } = useLocation();

  const getInfo = (selectedCurrentJob) => {
    sessionStorage.setItem("showPerspectiveBtn", true);
    sessionStorage.removeItem("sidemenuselection");
    sessionStorage.removeItem("currentJobSelectedForPoster");
    sessionStorage.setItem("sidemenuselection", "0");
    sessionStorage.setItem("currentJobSelectedForPoster", selectedCurrentJob);
    navigation(`../mainMenu/${selectedCurrentJob?._id}`, {
      state: selectedCurrentJob,
    });
  };
  const completePendingJob = (JobID, stepNumber) => {
    setLoading(true);
    const obj = {
      userId: userId,
      jobID: JobID,
    };
    localStorage.setItem("jobId", JobID);
    postApi(
      API_UPDATE_POSTER_JOB_ID,
      obj,
      userToken,
      successFunctionOnboardingSteps,
      errorSavingData
    );
    var existingUserInfo = userAuthInfo.userData?.payload;
    existingUserInfo.user.selfAssessmentResultSteps = stepNumber;
    existingUserInfo.user.selfAssessmentResult = false;

    dispatch(
      saveUserInfo({
        payload: existingUserInfo,
      })
    );
  };

  const requestDataFromServer = () => {
    const obj = {
      usertype: 2,
      userId: userId,
      jobId: jobId,
    };
    postApiPoster(
      API_GET_POSTER_DETAIL,
      obj,
      userToken,
      successFunction,
      errorFunction
    );
  };
  const successFunction = (res) => {
    console.log(res, "res");
    setLoading(false);
    if (res.status === 200) {
      setaddedJobList(res.data.result);
    }
    setaddedJobList(res.data.result);
  };
  const errorFunction = (errr) => {
    toast.error("Oops! something went wrong!", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const userAuthInfo = useSelector((state) => state.UserAuth);
  const dispatch = useDispatch();

  const successFunctionOnboardingSteps = (res) => {
    navigation(`../homepage`);
  };

  //!error occured in saving data
  const errorSavingData = (err) => {
    toast.error(err.data.message, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    requestDataFromServer();
  }, []);

  const [dataList, setDataList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [paginatedObjects, setpaginatedObjects] = useState([]);

  useEffect(() => {
    const data = addedJobList.sort((a, b) =>
      a?.jobtitle?.[0]?.option < b?.jobtitle?.[0]?.option
        ? -1
        : b?.jobtitle?.[0]?.option < a?.jobtitle?.[0]?.option
          ? 1
          : 0
    );
    setDataList(data);
  }, [addedJobList]);

  const pageSize = 10;
  useEffect(() => {
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    // Sorting value based on input
    // let newSortedValue = [];
    // if (sortValue == "alphabet") {
    //   newSortedValue = addedJobList.sort((a, b) =>
    //     a?.jobtitle?.[0]?.option < b?.jobtitle?.[0]?.option
    //       ? -1
    //       : b?.jobtitle?.[0]?.option < a?.jobtitle?.[0]?.option
    //       ? 1
    //       : 0
    //   );
    // }
    // else {
    //   newSortedValue = addedJobList.sort(
    //     (a, b) => new Date(b?.jobtitle?.[0]?.createdAt) - new Date(a?.jobtitle?.[0]?.createdAt)
    //   );
    // }
    setpaginatedObjects(dataList.slice(startIndex, endIndex));
  }, [pageNumber, dataList]);

  return (
    <div>
      <HeaderMenu />
      {loading ? (
        <div className="d-flex justify-content-center align-items-center LoaderCenter">
          {" "}
          <div className="block">
            <span
              className="spinner-grow spinner-grow-lg mx-2"
              role="status"
              aria-hidden="true"
            />
            <h6 className="LoaderText">Processing...</h6>
          </div>
        </div>
      ) : (
        <>
          <section id="dashboard-cnt-area" className="dashboard-bg">
            <div className="container">
              <div className="row dashboard-cnt-row d-flex align-items-center justify-content-center">

                <div className="col-lg-9 col-sm-8 col-xs-12 mircroshoft-cnt main-menu">
                  <div className="row ">
                    <div className="col-lg-12  recent-page-heading-jp mb-4 landing-current-job-btn">
                      <div className="col-lg-4">
                        <br />
                        <a >Current Jobs</a>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-5 pb-5">
                    {paginatedObjects?.length > 0 &&
                      paginatedObjects?.map((res, i) =>
                        res.isJobCreated ? (
                          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 pb-2">
                            <div className="current-job-col">
                              <div className="col-12 d-flex justify-content-between">
                                <img
                                  className=""
                                  style={{ height: "20px" }}
                                  src={EditButton}
                                  onClick={() =>
                                    navigate("../jobDescription", {
                                      state: res,
                                    })
                                  }
                                />
                                <div className="col-10 ">
                                  {res.jobtitle.map((tl) => (
                                    <h5>{tl.option}</h5>
                                  ))}
                                  <p className={'capitalize'}>
                                    {res?.locationName ?? ''}
                                  </p>
                                </div>

                                <img
                                  className=""
                                  style={{ height: "20px" }}
                                  src={logo}
                                  onClick={() => getInfo(res)}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 pb-2">
                            <div
                              className="current-job-col"
                              onClick={() =>
                                completePendingJob(
                                  res._id,
                                  res.selfAssessmentResultSteps
                                )
                              }
                            >
                              <div className="col-12 d-flex justify-content-between ">
                                <div className="col-10 ">
                                  {res.jobtitle.map((tl) => (
                                    <h5>{tl.option}</h5>
                                  ))}

                                  <p style={{ color: "red" }}>Draft</p>
                                </div>
                                <img
                                  className=""
                                  style={{ height: "20px" }}
                                  src={logo}
                                />
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    <div className="col">
                      {pageNumber > 1 && (
                        <button
                          className="matching-button col-4"
                          style={{
                            color: "white",
                            backgroundColor: "#ffdb59",
                            transform: "scale(0.8)",
                            marginTop: "-1%",
                          }}
                          onClick={() => {
                            window.scrollTo(0, 0);
                            setPageNumber(pageNumber - 1);
                          }}
                        >
                          Previous
                        </button>
                      )}

                      {paginatedObjects.length / pageSize > pageNumber && (
                        <button
                          className="matching-button col-4"
                          style={{
                            color: "white",
                            backgroundColor: "#1ec991",
                            transform: "scale(0.8)",
                            marginTop: "-1%",
                          }}
                          onClick={() => {
                            window.scrollTo(0, 0);
                            setPageNumber(pageNumber + 1);
                          }}
                        >
                          Next
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 mt-5 text-center mb-5">
                      <a  onClick={() => navigate("../jobRole")}>
                        <img src={PlusBtn} />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <FooterSection />
        </>
      )}
    </div>
  );
}
