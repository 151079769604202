import { TYPES } from '../actions';

const getUser = localStorage.getItem('UserInfo');
let userSavedInfo = JSON.parse(getUser);

const sessionUser = sessionStorage.getItem('UserSession');
const tempUser = JSON.parse(sessionUser);

const initialState = {
	userData: userSavedInfo ? userSavedInfo : tempUser
};

const UserAuth = (state = initialState, { type, payload }) => {
	switch (type) {
		case TYPES.USER_LOGIN:
			return {
				...state,
				userData: payload
			};
		
				case TYPES.USER_LOGOUT:
					
					return {
						...state,
						userData:null
					}
		

		default:
			return { ...state };
	}
};

export { UserAuth };
