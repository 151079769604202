import React, { useEffect, useState } from "react";
import Blk from "../../../../assets/images/logo-icon-blk.png";
import ArrowIcon from "../../../../assets/images/right-arrow-icon.jpg";
import { API_GET_SUBSCRIPTION_LIST, getApi } from "../../../../utils";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import GoBack from "../../../../components/GoBack";
import { toast } from 'react-toastify';

export default function PaymentPlanScreen() {
  const userToken = useSelector((state) => state.UserAuth.userData.payload.token);
  const [planList, setPlanList] = useState([]);
  const [active, setActivePlan] = useState([]);
  const [planType, setPlanType] = useState(0);
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    getPlanList();
    setPlanType(state?.planType || 0);
  }, [state]);

  const getPlanList = () => {
    const axiosConfig = {
      headers: {
        type: "front-end",
        mode: 1,
        Authorization: `Bearer ${userToken}`,
      },
    };
    getApi(API_GET_SUBSCRIPTION_LIST, axiosConfig, onSuccess, onFailure);
  };

  const onSuccess = (res) => {
    let availablePlans = []
    if(['/paymentOption/new'].includes(window.location.pathname)){
      availablePlans = res.data.result.filter((plan) => plan.credit !== 1);
      availablePlans = availablePlans.filter((plan) => plan.credit !== -1);
    }else{
      availablePlans = res.data.result.filter((plan) => plan.credit > 0);
    }
    console.log('availablePlans: ', availablePlans)
    setPlanList(availablePlans);
  };

  const onFailure = (err) => {
    console.error("Error fetching plans:", err);
  };

  const goToNextPage = () => {
    navigate("../jobPoster/companies");
  };

  const goToPaymentPage = () => {
    const selectedPlan = planList.find((plan) => plan._id === active);
    
    if (selectedPlan) {
      if (selectedPlan.credit === 0) {
        goToNextPage();
      } else {
        navigate("../paymentMethod", { state: { planInfo: selectedPlan } });
      }
    } else {
      toast.error('Please choose a plan', {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <section id="feel-suite-best" style={{ backgroundPosition: "top :-66px center" }}>
      <div className="container">
        <GoBack />
        <div className="row">
          <div className="col-lg-12 text-center">
            <img src={Blk} alt="Logo" />
            <h3>Choose a payment plan that suits you</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 text-center payment-plan">
            <p className="w-50 mx-auto">
              We offer {planType === 1 ? 2 : 3} flexible, affordable payment plans. Pick whichever one suits you best.
            </p>
          </div>
        </div>
        <div className="row mt-5">
          {planList.map((plan, i) => (
            (planType === 1 && plan.credit !== null) || planType === 0 ? (
              <div key={i} className="col-lg-4 col-md-4 col-sm-12 col-12 text-center mb-4">
                <div
                  className={`payment-plan-col ${active.includes(plan._id) ? "payment-plan-col-active" : ""}`}
                  onClick={() => setActivePlan(plan._id)}
                >
                  <h5>{plan.title}</h5>
                  <p>{plan.description}</p>
                  <h5>£{plan.offerprice}</h5>
                  {plan.credit && (
                    <>
                      <span>Credits {plan.credit}</span>
                      <br />
                      <span>per profile</span>
                    </>
                  )}
                </div>
              </div>
            ) : null
          ))}
        </div>
        <div className="row">
          <div className="col-lg-12 text-center">
            <a onClick={goToPaymentPage} className="perspectv-button mb-2">
              Proceed
              <img className="ms-2" style={{ height: '20px' }} src={ArrowIcon} alt="Arrow Icon" />
            </a>
            <p className="mt-2">
              <a onClick={goToNextPage} className="opacity-60" style={{ fontSize: "17px", textDecoration: "none", color: "#262626", 'cursor': 'pointer' }}>
                Skip Page
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}