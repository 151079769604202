import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DefaultImage from "../../../../assets/images/defaultProfile.png"
import { getUser } from "../../../../utils/ChatAPi/ChatRequests";
import Logo from "../../../../assets/images/logo-icon-blk.png";
import "../LogoSearch/LogoSearch.css";
import { UilSearch } from "@iconscout/react-unicons";

const Conversation = ({ data, currentUser, online ,currentUserChat ,search }) => {

  const [userData, setUserData] = useState(null)
  const dispatch = useDispatch()
  const userType = useSelector((state) => state.userType.userType);

  if(userType.payload === 'jobPoster'){
    var ImageUrl =  userData?.imagePath;
   }else{
     var ImageUrl =  userData?.destination;
   }

  useEffect(()=> {
    const userId = data.members.find((id)=>id !== currentUser)
    const getUserData = async ()=> {
      try
      {
         const {data} =await getUser(userId,userType.payload)
         setUserData(data)
         dispatch({type:"SAVE_USER", data:data ,userType:userType})
      }
      catch(error)
      {
        console.log(error)
      }
    }

    getUserData();
  }, [])
  return (
    <>
  
         <div className={ data._id === currentUserChat?._id ? "follower conversation active" : "follower conversation"}>
        <div>
          {online && <div className="online-dot"></div>}
          <img
            src={ImageUrl ? ImageUrl : DefaultImage} alt="Profile"
            width="50px" height={'50px'} className="rounded-circle followerImage"
          />
          <div className="name" style={{fontSize: '0.8rem'}}>
            <span>{userData?.fullName} </span>
            <span style={{color: online?"#51e200":""}}>{online? "Online" : "Offline"}</span>
          </div>
        </div>
      </div>
      <hr style={{ width: "85%", border: "0.1px solid #ececec" }} />
    </>
  );
};

export default Conversation;
