import React, { useState } from "react";
import topLogo from "../../../assets/images/logo-icon-blk.png";
import bottomLogo from "../../../assets/images/people-graphics-bottom.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { choosenOption } from "../../../redux/actions";
import arrowIcon from "../../../assets/images/right-arrow.png";
import DocumentMeta from 'react-document-meta';
import { Helmet } from 'react-helmet';

function LandingPage() {
  const navigation = useNavigate();
  const [optionSelected, setOptionSelected] = useState("Sign Up");
  const dispatch = useDispatch();

  const getUserSelection = (value) => {
    setOptionSelected(value);
    dispatch(choosenOption({ payload: "Sign Up" }));
    console.log("this is the data in it -----", value);
    if (value === "Login") {
      navigation("../login");
    } else {
      navigation("../app");
    }
  };
  <Helmet>
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-D7WXCSN3P2">
    </script>
    <script>
      window.dataLayer = window.dataLayer ;
      function gtag(dataLayer.push(arguments);
      gtag('is', new Date());
      gtag/'config', 'G-D7WXCSN3P2');
    </script>
  </Helmet >
  const meta = {
    title: 'Sign In to Your Perspectv Account | Register for Free Today',
    description: "Sign in to your Perspectv account or register for free online to access our AI-powered recruitment platform. Define what you're looking for and let us help you find your ideal job or candidate",
    // canonical: 'http://example.com/path/to/page',
    // meta: {
    //   charset: 'utf-8',
    //   name: {
    //     keywords: 'react,meta,document,html,tags'
    //   }
    // }
  };

  return (
    <DocumentMeta {...meta}>
      <section id="perspectv-login-screen">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <img src={topLogo} style={{ width: "80px" }} />
              <h1 className="landing-heading font-space-mono">
                Welcome to Perspectv!
              </h1>
              <div className="ms-2">
                <p className="subtitle-text">
                  The smart new way to hire and get hired.
                </p>
              </div>
              <div className="login-screen-btn tabs-btn">
                <ul>
                  <li>
                    <a
                      className={optionSelected === "Sign Up" && "active"}
                      onMouseEnter={() => setOptionSelected("")}
                      onClick={() => getUserSelection("Sign Up")}
                      onMouseLeave={() => setOptionSelected("Sign Up")}
                    >
                      Sign up
                    </a>
                  </li>

                  <li>
                    <a
                      className={optionSelected === "Login" && "active"}
                      onMouseEnter={() => setOptionSelected("")}
                      onClick={() => getUserSelection("Login")}
                      onMouseLeave={() => setOptionSelected("Login")}
                    >
                      Login
                    </a>
                  </li>
                </ul>
              </div>
              {/* <button
              type="button"
              className="btn btn-primary"
              onClick={getUserSelection}
            >
              <img src={arrowIcon} alt="arrowIcon" />
            </button> */}
            </div>
          </div>
        </div>
        <br />
        <img className="img-fluid people-vector mt-5" src={bottomLogo} />
      </section>
    </DocumentMeta>
  );
}

export default LandingPage;
