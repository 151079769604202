import React, { useState, useEffect } from "react";
import brand from "../../../../assets/images/logo-icon-blk.png";
import currency from "../../../../assets/images/pound-icon.png";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
    API_SEEKER_JOB_ROLE_OPTIONS,
    API_SEEKER_JOB_ROLE_TITLE,
    getApi,
    postApi,
} from "../../../../utils";
import { Modal, ModalBody } from "react-bootstrap";

function EmploymentTypeUpdate({
    prevEmploymentType,
    prevMinPay,
    employmentTypeModal,
    setEmploymentTypeModal,
    updateProfileFunc,
}) {
    //*******Getting router passed data***************************/
    const { state } = useLocation();
    const [routerData] = useState(state);
    const navigation = useNavigate();
    const [isRadioChecked, setRadioChecked] = useState(
        "hourly" === prevMinPay.split(":")[0]
            ? 1
            : "monthly" === prevMinPay.split(":")[0]
                ? 2
                : 3
    );
    const [rangeValueHourly, setRangeValueHourly] = useState(
        "hourly" === prevMinPay.split(":")[0]
            ? Number(prevMinPay.split(":")[1].substring(1))
            : 10
    );
    const [rangeValueMonthly, setRangeValueMonthly] = useState(
        "monthly" === prevMinPay.split(":")[0]
            ? Number(prevMinPay.split(":")[1].substring(1))
            : 1000
    );
    const [rangeValueAnnual, setRangeValueAnnual] = useState(
        "yearly" === prevMinPay.split(":")[0]
            ? Number(prevMinPay.split(":")[1].substring(1))
            : 20000
    );
    const [employmentLength, setEmploymentLength] = useState([]);
    const [jobTitle, setJobTitle] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [isFetchedData, setFetchedData] = useState(false);
    const userType = useSelector((state) => state.userType.userType);
    const userToken = useSelector(
        (state) => state.UserAuth.userData.payload.token
    );

    // console.log("get user selected data till now", routerData);
    //Todo: work is this component pending due to API dependecy
    const savingInfo = () => {
        if (employmentLength !== "") {
            if (!isRadioChecked) {
                toast.error("Please select minium asking for!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                if (isRadioChecked === 1) {
                    // if (userType.payload === 'jobSeeker') {
                    const range = rangeValueHourly;
                    const type = "hour:€";
                    const resullt = type.concat(range);
                    setEmploymentTypeModal(false);
                    if (prevEmploymentType && prevMinPay) {
                        updateProfileFunc(
                            "employmentType",
                            employmentLength,
                            "employmentMode",
                            resullt
                        );
                    } else {
                        updateProfileFunc("employmentMode", resullt);
                    }
                } else if (isRadioChecked === 2) {
                    const range = rangeValueMonthly;
                    const type = "monthly:€";
                    const resullt = type.concat(range);

                    setEmploymentTypeModal(false);
                    if (prevEmploymentType && prevMinPay) {
                        updateProfileFunc(
                            "employmentType",
                            employmentLength,
                            "employmentMode",
                            resullt
                        );
                    } else {
                        updateProfileFunc("employmentMode", resullt);
                    }
                } else if (isRadioChecked === 3) {
                    // if (userType.payload === 'jobSeeker') {
                    const range = rangeValueAnnual;
                    const type = "annual:€";
                    const resullt = type.concat(range);
                    // navigation("../roleStepComplete", {
                    //   state: {
                    //     ...routerData,
                    //     employmentType: employmentLength,
                    //     employmentMode: resullt,
                    //   },
                    // });
                    // } else {
                    // 	navigation('../roleStepComplete', { state: { ...routerData } });
                    // }

                    setEmploymentTypeModal(false);
                    if (prevEmploymentType && prevMinPay) {
                        updateProfileFunc(
                            "employmentType",
                            employmentLength,
                            "employmentMode",
                            resullt
                        );
                    } else {
                        updateProfileFunc("employmentMode", resullt);
                    }
                }
            }
        } else {
            toast.error("Please choose any of the option or type manually!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };
    // *******************************call api fucntion *********************************
    const requestDataFromServer = () => {
        setLoading(true);
        const obj = {
            category: "salary",
        };
        postApi(
            API_SEEKER_JOB_ROLE_TITLE,
            obj,
            userToken,
            successFunction,
            errorFunction
        );
    };
    const successFunction = (response) => {
        if (response.status === 200) {
            setLoading(false);
            // console.log(response.data.result, "title");
            setJobTitle(response.data.result);
        }
    };
    const errorFunction = (error) => {
        setLoading(false);
        setFetchedData(true);
        // console.log(error, "drerer");
    };

    useEffect(() => {
        if (!isFetchedData) {
            requestDataFromServer();
        }
    }, [isFetchedData]);
    //Todo : Work pending this component api is not correct
    return (
        <Modal show={employmentTypeModal}>
            <ModalBody>
                <button
                    className="position-absolute"
                    style={{
                        width: "100px",
                        backgroundColor: "transparent",
                        top: "20px",
                        left: "0px",
                        fontSize: "30px",
                        zIndex: "333",
                        border: "none",
                    }}
                    onClick={() => setEmploymentTypeModal(false)}
                >
                    <i className="fa fa-arrow-circle-left" aria-hidden="true"></i>
                </button>
                {isLoading && (
                    <div className="d-flex justify-content-center align-items-center LoaderCenter">
                        {" "}
                        <div className="block">
                            <span
                                className="spinner-grow spinner-grow-lg mx-2"
                                role="status"
                                aria-hidden="true"
                            />
                            <h6 className="LoaderText">Processing...</h6>
                        </div>
                    </div>
                )}
                {!isLoading && (
                    <section id="job-role-steps">
                        <div className="discover-personality-top-cnt">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 text-center">
                                        <img src={brand} alt="brand" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--discover-personality-top-cnt--> */}

                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-2" />
                                <div className="col-lg-12">
                                    <div className="card" style={{ borderWidth: 0 }}>
                                        <form id="msform">
                                            {/* <!-- progressbar --> */}
                                            <fieldset>
                                                <div className="form-card">

                                                    {userType.payload === "jobSeeker" &&
                                                        jobTitle &&
                                                        jobTitle.map((data) => (
                                                            <h3 key={data._id}>{data.question}</h3>
                                                        ))}
                                                    <div className="role-wrapper skyblue-radio-btn">
                                                        {userType.payload === "jobSeeker" && (
                                                            <>
                                                                <div className="role-col">
                                                                    <div className="inputGroup">
                                                                        <input
                                                                            id="radio49"
                                                                            name="eradio"
                                                                            type="radio"
                                                                            onChange={() =>
                                                                                setEmploymentLength("Permanent")
                                                                            }
                                                                            defaultChecked={
                                                                                "Permanent" === prevEmploymentType
                                                                            }
                                                                        />
                                                                        <label htmlFor="radio49">Permanent</label>
                                                                    </div>
                                                                </div>
                                                                {/* <!--role-col--> */}
                                                                <div className="role-col">
                                                                    <div className="inputGroup">
                                                                        <input
                                                                            id="radio50"
                                                                            name="eradio"
                                                                            type="radio"
                                                                            onChange={() =>
                                                                                setEmploymentLength("Contract")
                                                                            }
                                                                            defaultChecked={
                                                                                "Contract" === prevEmploymentType
                                                                            }
                                                                        />
                                                                        <label htmlFor="radio50">Contract</label>
                                                                    </div>
                                                                </div>
                                                                {/* <!--role-col--> */}
                                                                <div className="role-col">
                                                                    <div className="inputGroup">
                                                                        <input
                                                                            id="radio51"
                                                                            name="eradio"
                                                                            type="radio"
                                                                            onChange={() =>
                                                                                setEmploymentLength("Freelance")
                                                                            }
                                                                            defaultChecked={
                                                                                "Freelance" === prevEmploymentType
                                                                            }
                                                                        />
                                                                        <label htmlFor="radio51">Freelance</label>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="role-col">
                                  <div className="inputGroup">
                                    <input
                                      id="radio52"
                                      name="eradio"
                                      type="radio"
                                      onChange={() =>
                                        setEmploymentLength(
                                          "Junior (1-2 years)"
                                        )
                                      }
                                      defaultChecked={
                                        "Junior (1-2 years)" ===
                                        prevEmploymentType
                                      }
                                    />
                                    <label htmlFor="radio52">
                                      Junior (1-2 years)
                                    </label>
                                  </div>
                                </div> */}
                                                            </>
                                                        )}
                                                        {/* <!--role-col--> */}

                                                        {/* <!--role-col--> */}
                                                    </div>


                                                </div>
                                                {/* <!--form-card--> */}

                                                <input
                                                    type="button"
                                                    name="next"
                                                    className="form-btn mt-4"
                                                    value="update"
                                                    onClick={savingInfo}
                                                />
                                            </fieldset>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-lg-2" />
                            </div>
                        </div>
                    </section>
                )}
            </ModalBody>
        </Modal>
    );
}

export default EmploymentTypeUpdate;
