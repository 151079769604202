import {
  API_PAYMENT_SAVE_PAYMENT
} from "../utils";

export const stripePaymentMethodHandler = async (data, cb) => {
  const { result } = data

  if (result.error) {
    // show error in payment form
    cb(result)
  } else {
    const paymentResponse = await stripePayment({
      name: data.userName,
      transactionId: result.paymentMethod.id,
      userId: data.userId,
      usertype: 2,
      amount: data.amount,
      planId: '2',
      currency: 'USD',
      paymentMode: result.paymentMethod.card.brand,
      cardNo: result.paymentMethod.card.last4,
      status: 'Done',
    })
    console.log(paymentResponse, 'pay res')
    cb(paymentResponse)
  }

}
// place backend API call for payment
const stripePayment = async (data) => {
  const res = await fetch(`${API_PAYMENT_SAVE_PAYMENT}`, {
    method: 'POST',
    headers: {
      type: 'front-end',
      mode: 2,
      Authorization: 'Bearer ' + data.userToken,
    },

    body: JSON.stringify(data),
  })
  return await res.json()
}
