import React, { useState } from "react";

import { Modal } from "react-bootstrap";
import LogoRight from "../../../assets/images/right-arrow.png";
import SpeedEye from "../../../assets/images/speed-eye-img.png";
import IconBlack from "../../../assets/images/logo-icon-blk.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
export default function JobPosterFirstModal(props) {
  const navigate = useNavigate();
  const choosenOption = useSelector(
    (state) => state.optionSelected.optionSelected
  );
  console.log(choosenOption, "jj");
  const stepTwo = () => {
    props.nextstep();
  };
  if (choosenOption.payload === "Login") {
    props.hideone();
  }
  const skipAndNavigate = () => {
    props.hideone();
    if (choosenOption.payload === "Sign Up") {
      navigate("../signup");
    } else {
      navigate("../login");
    }
  };
  return (
    <Modal
      show={props.showone}
      onHide={props.hideone}
      size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="four-first-modal"
    >
      <Modal.Header closeButton className="modal-close-stepone-jp" />
      <Modal.Body style={{ marginTop: "-20px", padding: "0px" }}>
        <form id="msform">
          <fieldset style={{ backgroundColor: "#00c5c6" }}>
            <div className="discover-main-screen-cnt-jp login-pop-step-1-jp">
              <img src={IconBlack} />
              <div className="modal-content-wrapper pt-5 pb-5">
                <h3 className="modal-header-text">Designed for speed</h3>
                <p className="modal-text">
                  Traditional recruitment methods are slow and outdated.
                  Perspectiv can screen thousands of candidates, identify ideal
                  matches and produce weighted results, all within seconds.
                </p>
              </div>
              {/* <br /> */}
              {/* <br /> */}
              <div className="eye-icon-jp">
                <img src={SpeedEye} />
              </div>
              <div className="modal-skip-button-jp">
                <a  onClick={stepTwo}>
                  <img src={LogoRight} />{" "}
                </a>

                <p className="pt-3">
                  {" "}
                  <a className="skip-text"  onClick={skipAndNavigate}>
                    Skip
                  </a>
                </p>
              </div>
            </div>
          </fieldset>
        </form>
      </Modal.Body>
    </Modal>
  );
}
