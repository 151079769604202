import React, { useState, useEffect } from "react";
import brand from "../../../../assets/images/logo-icon-blk.png";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  API_SEEKER_JOB_ROLE_OPTIONS,
  API_SEEKER_JOB_ROLE_TITLE,
  getApi,
  postApi,
  postApiPoster,
} from "../../../../utils";
import { Modal, ModalBody } from "react-bootstrap";

function CompanySizeUpdate({
  prevCompanySize,
  companySizeModal,
  setCompanySizeModal,
  updateProfileFunc,
}) {
  const navigation = useNavigate();
  //*******Getting router passed data***************************/
  const { state } = useLocation();
  const [routerData] = useState(state);
  const [companySize, setCompanySize] = useState([]);

  const [jobTitle, setJobTitle] = useState("");
  const [listedCompanySize, setListedCompanySize] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isFetchedData, setFetchedData] = useState(false);
  const userType = useSelector((state) => state.userType.userType);
  const userToken = useSelector(
    (state) => state.UserAuth.userData.payload.token
  );
  //Todo: work in this function is not complete api rsult is not coming right
  const savingInfo = () => {
    if (companySize.length > 0) {
      const sizeOfCompony = company.filter((el) => el.id.includes(companySize));
      const copy = Object.assign({}, ...sizeOfCompony);
      const { size } = copy;
      var one = size;
      var whiteSpace = " ";
      const withString = one.concat(whiteSpace, "employee");
      // console.log(withString, "withString");
      // navigation("../salary", {
      //   state: { ...routerData, campanySize: withString },
      // });
      setCompanySizeModal(false);
      updateProfileFunc("campanySize", withString);
      // updateProfileFunc("skillLevelId", "6362d9b8b562bbafb5a7cf77");
    } else {
      toast.error("Please choose any of the option or type manually!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  //Comapany size array
  const company = [
    { id: "1", size: "1-20" },
    { id: "2", size: "21-50" },
    { id: "3", size: "51-100" },
    { id: "4", size: "101-250" },
    { id: "5", size: "251+" },
  ];
  //call api
  const requestDataFromServer = () => {
    setLoading(true);

    const obj = {
      category: "companysize",
    };
    if (userType.payload === "jobSeeker") {
      postApi(
        API_SEEKER_JOB_ROLE_TITLE,
        obj,
        userToken,
        successFunction,
        errorFunction
      );
      // postApi(API_SEEKER_JOB_ROLE_OPTIONS, obj, userToken, successFunctionOptions, errorFunctionOptions);
    } else {
      postApiPoster(
        API_SEEKER_JOB_ROLE_TITLE,
        obj,
        userToken,
        successFunction,
        errorFunction
      );
      // postApiPoster(API_SEEKER_JOB_ROLE_OPTIONS, obj, userToken, successFunctionOptions, errorFunctionOptions);
    }
  };
  const successFunction = (response) => {
    setFetchedData(true);
    setLoading(false);
    if (response.status === 200) {
      setJobTitle(response.data.result);
      setListedCompanySize(company);
    }
  };
  const errorFunction = (error) => {
    setLoading(false);
    setFetchedData(true);
  };
  // const successFunctionOptions = (response) => {
  // 	console.log(response, 'companySize');
  // 	setLoading(false);
  // 	setFetchedData(true);

  // 	if (response.status === 200) {
  // 		setListedCompanySize(response.data.result);
  // 	}

  // 	// console.log(response);
  // };
  // const errorFunctionOptions = (error) => {
  // 	setLoading(false);
  // 	setFetchedData(true);
  // 	console.log(error);
  // };
  useEffect(() => {
    if (!isFetchedData) {
      requestDataFromServer();
    }
  }, [isFetchedData]);

  return (
    <Modal show={companySizeModal}>
      <ModalBody>
        <button
          className="position-absolute"
          style={{
            width: "100px",
            backgroundColor: "transparent",
            top: "20px",
            left: "0px",
            fontSize: "30px",
            zIndex: "333",
            border: "none",
          }}
          onClick={() => setCompanySizeModal(false)}
        >
          <i className="fa fa-arrow-circle-left" aria-hidden="true"></i>
        </button>
        {isLoading && (
          <div className="d-flex justify-content-center align-items-center LoaderCenter">
            {" "}
            <div className="block">
              <span
                className="spinner-grow spinner-grow-lg mx-2"
                role="status"
                aria-hidden="true"
              />
              <h6 className="LoaderText">Processing...</h6>
            </div>
          </div>
        )}
        {!isLoading && (
          <section id="job-role-steps">
            <div className="discover-personality-top-cnt">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <img src={brand} alt="brand" />
                  </div>
                </div>
              </div>
            </div>
            {/* <!--discover-personality-top-cnt--> */}

            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-2" />
                <div className="col-lg-12">
                  <div className="card" style={{ borderWidth: 0 }}>
                    <form id="msform">
                      {/* <!-- progressbar --> */}

                      <fieldset>
                        <div className="form-card">
                          {userType.payload === "jobSeeker"
                            ? jobTitle &&
                              jobTitle.map((data) => (
                                <h3 key={data._id}>{data.question}</h3>
                              ))
                            : jobTitle &&
                              jobTitle.map((data) => (
                                <h3 key={data._id}>{data.question}</h3>
                              ))}
                          <div className="role-wrapper">
                            {listedCompanySize &&
                              listedCompanySize.map((data, i) => (
                                <div key={i} className="role-col">
                                  <div key={i + 2} className="inputGroup">
                                    <input
                                      key={i + 3}
                                      id="radio44"
                                      name="radio"
                                      type="radio"
                                      readOnly
                                      checked={
                                        companySize.length > 0
                                          ? companySize.includes(data.id)
                                          : data.size.split("-" || "+")[0] ===
                                            prevCompanySize.split("-" || "+")[0]
                                      }
                                      // onChange={() => setCompanySize([ data.id ])}
                                    />
                                    <label
                                      key={i + 4}
                                      onClick={() => setCompanySize([data.id])}
                                      htmlFor="radio44"
                                    >
                                      {data.size}&nbsp;employees
                                    </label>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>

                        <input
                          type="button"
                          name="next"
                          className="form-btn mt-4"
                          value="update"
                          onClick={savingInfo}
                        />
                      </fieldset>
                    </form>
                  </div>
                </div>
                <div className="col-lg-2" />
              </div>
            </div>
          </section>
        )}
      </ModalBody>
    </Modal>
  );
}

export default CompanySizeUpdate;
