import React, { useState } from 'react';

import { Modal } from 'react-bootstrap';
import LogoRight from '../../../assets/images/right-arrow.png';
import DiscoverHand from '../../../assets/images/discover-hand.png';
import IconBlack from '../../../assets/images/logo-icon-blk.png';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
export default function JobPosterSecondModal(props) {
  const navigate = useNavigate();
  const choosenOption = useSelector(state => state.optionSelected.optionSelected);

  const stepThree = () => {
    props.stepthree();
  };
  const skipAndNavigate = () => {
    props.hidetwo();
    if (choosenOption.payload === 'Sign Up') {
      navigate('../signup');
    } else {
      navigate('../login');
    }
  };
  return (
    <Modal
      style={{ paddingBottom: '20px' }}
      show={props.showtwo}
      onHide={props.hidetwo}
      size='lg'
      backdrop='static'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className='four-second-modal'
    >
      <Modal.Header closeButton className='modal-close-stepone-jp' />
      <Modal.Body style={{ marginTop: '-20px', padding: '0px' }}>
        <form id='msform'>
          <fieldset className='login-pop-step-2-jp'>
            <div className='discover-main-screen-cnt'>
              <img src={IconBlack} />
              <div className='modal-content-wrapper pt-5 pb-5 '>
                <h3 className='modal-header-text'>Designed for quality</h3>

                <p className='modal-text'>
                  Our software uses specifc data markers when screening thousands of job seekers to
                  find the perfect candidates for you.
                </p>
              </div>
              {/* <br />
							<br /> */}
              <div className='discover-hand-icon'>
                <img src={DiscoverHand} />
              </div>
              <a href='javascript:void(0)' onClick={stepThree}>
                <img src={LogoRight} />{' '}
              </a>

              <p>
                {' '}
                <a href='javascript:void(0)' onClick={skipAndNavigate}>
                  Skip
                </a>
              </p>
            </div>
          </fieldset>
        </form>
      </Modal.Body>
    </Modal>
  );
}
