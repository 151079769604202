import React, { useState, useEffect } from "react";
import Blk from "../../../assets/images/logo-icon-blk.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getApi, postApi, postApiPoster } from "../../../utils";
import {
  API_SELF_ASSESMENT_CATEGORY,
  API_SELF_ASSESMENT_CATEGORY_OPTIONS,
  API_SET_POINTS,
  API_SUBMIT_JOBPOSTER_DATA,
  API_COMMON_ONBOARDING_STEPS,
} from "../../../utils/APIConstant";
import GoBack from "../../../components/GoBack";
import { saveUserInfo } from "../../../redux/actions";

function SelfAssestOne() {
  const navigation = useNavigate();
  const { state } = useLocation();
  const jobId = state.jobId;

  // State variables
  const [radioButton, setRadioButton] = useState([]);
  const [isChecked, setChecked] = useState(false);
  const [isFetchedData, setFetchedData] = useState(false);
  // const [jobTitle, setJobTitle] = useState("");
  const [selfAssesmentData, setselfAssesmentData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [isCategoryFetched, setCategoryFetched] = useState(false);
  const [quetionAsked, setQuetionAsked] = useState("");
  const [optionOne, setOptionOne] = useState([]);
  const [isActive, setActive] = useState([0]);
  const userToken = useSelector((state) => state.UserAuth.userData.payload.token);
  const userType = useSelector((state) => state.userType.userType);
  const userId = useSelector((state) => state.UserAuth.userData.payload.user._id);
  const userAuthInfo = useSelector((state) => state.UserAuth);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [percentageComplete, setPercentageComplete] = useState(0);
  const [selfAssesmenAdded, setSelfAssesmentAdded] = useState([]);
  // const [newItemAdded, setItemAdded] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const [selectedAllOptionArray, setSelectedAllOptionArray] = useState([]);

  const dispatch = useDispatch();

  // Save user information and navigate to next step
  const saveInfo = () => {
    if (!isChecked) {
      toast.error("Oops! You forgot to select the checkbox", {
        position: "top-right",
        autoClose: 1000,
      });
    } else {
      let dataLength = selfAssesmentData.length - 1;
      const combineStr = pickFirstChar([...selectedAllOptionArray, ...selectedOption]);
      console.log('selectedAllOptionArray: ', selectedAllOptionArray)
      console.log('selectedOption: ', selectedOption)
      console.log('combineStr: ', combineStr)
      if (currentIndex < dataLength) {
        setChecked(false);
        setCurrentIndex(currentIndex + 1);
        setSelectedAllOptionArray([...selectedAllOptionArray, ...selectedOption]);
      } else {
        const combineStr = pickFirstChar([...selectedAllOptionArray, ...selectedOption]);
        // console.log('combineStr: ', combineStr)
        sessionStorage.removeItem("UserResult");
        sessionStorage.setItem("UserResult", combineStr);
        if (userType.payload === "jobSeeker") {
          updateOnBoardingSteps(
            selfAssesmenAdded.map((data) => data._id),
            combineStr
          );
        } else if (userType.payload === "jobPoster") {
          updateOnBoardingSteps(
            selfAssesmenAdded.map((data) => data._id),
            combineStr
          );
        }
      }
    }
  };

    // Function to pick the first character of each selected option
    const pickFirstChar = (selfArray) => {
      // const categoryId = categoryList;
      // let concatString = "";
      // const arrangeObjArray = categoryId.map((item) => {
      //   const filterArray = selfArray.filter((subItem) => subItem.category_id === item._id);
      //   return filterArray;
      // });
      // EDWARD DID THIS ALL IN ONE FOR-LOOP
      let full_text = ''
      for(const x in selfArray){
        const obj = selfArray[x]
        let text = getCharFromOption(obj['result'])
        full_text = full_text + text.substring(0, 1)
      }
  
      // arrangeObjArray.forEach((item) => {
      //   let selectedOpt = "";
      //   let selectedPer = 0;
      //   item.forEach((subItem, index) => {
      //     if (index === 0) {
      //       selectedOpt = subItem.result;
      //       selectedPer = subItem.percentage;
      //     } else {
      //       if (selectedOpt !== subItem.result) {
      //         if (selectedPer > subItem.percentage) {
      //           selectedPer -= subItem.percentage;
      //         } else {
      //           selectedPer = subItem.percentage - selectedPer;
      //           selectedOpt = subItem.result;
      //         }
      //       } else {
      //         selectedPer += subItem.percentage;
      //       }
      //     }
      //   });
      //   const pickChar = getCharFromOption(selectedOpt);
      //   concatString += pickChar;
      // });
      // if(concatString === ''){
      //   const key = selfArray[0]['result']
      //   concatString = getCharFromOption(key);
      // }
      
      return full_text;
    };
  
    // Helper function to get the first character of an option
    const getCharFromOption = (option) => {
      switch (option) {
        case "Extrovert": return "E";
        case "Extroverted": return "E";
        case "Introverted": return "I";
        case "Observant": return "S";
        case "Sensors": return "S";
        case "Intuitive": return "N";
        case "Thinking": return "T";
        case "Feeling": return "F";
        case "Judging": return "J";
        case "Prospecting": return "P";
        default: return option.substring(0, 1);
      }
    };

  // Update onboarding steps for job seeker or job poster
  const updateOnBoardingSteps = (selfAssessmentData, selfAssessmentResult) => {
    const obj = {
      jobId: jobId,
      userId: userId,
      selfAssessmentData: selfAssessmentData,
      selfAssessmentResult: selfAssessmentResult,
      userType: userType.payload,
      stepNumber: 11,
    };

    if (userType.payload === "jobSeeker") {
      postApi(
        API_COMMON_ONBOARDING_STEPS,
        obj,
        userToken,
        successFunctionOnboardingSteps,
        errorSavingData
      );
    } else {
      postApi(
        API_COMMON_ONBOARDING_STEPS,
        obj,
        userToken,
        saveDataSuccessFunction,
        errorSavingData
      );
    }
  };

  // Success function for onboarding steps
  const successFunctionOnboardingSteps = (res) => {
    setLoading(false);
    if (res.status === 200) {
      const existingUserInfo = { ...userAuthInfo.userData?.payload };
      existingUserInfo.user.selfAssessmentResultSteps = 11;
      dispatch(saveUserInfo({ payload: existingUserInfo }));
      navigation("../hardStep1", { state: { jobId: jobId } });
    }
  };

  // Success function for saving data
  const saveDataSuccessFunction = (res) => {
    setLoading(false);
    const unknown_id = res.data.user._id
    localStorage.setItem("jobId", unknown_id); // TODO check out this logic
    setTimeout(() => {
      navigation("../hardStep5", { state: { jobId: jobId } });
    }, 1000)
    postApiPoster(
      API_SET_POINTS,
      { userId: res.data.user.userId, id: unknown_id },
      userToken,
      updateBackendSuccess,
      errorSavingData
    );
  };

  // Update backend success handler
  const updateBackendSuccess = (response) => {
    setLoading(false);
    toast.success(response.data.message, {
      position: "top-right",
      autoClose: 1000,
    });
    // navigation("../hardStep5", { state: { jobId: jobId } });
  };

  // Error handler for saving data
  const errorSavingData = (err) => {
    setLoading(false);
    toast.error(err.message, {
      position: "top-right",
      autoClose: 1000,
    });
  };

  useEffect(() => {
    if (currentIndex > 0) {
      setSelfAssesmentAdded([...selfAssesmenAdded, ...radioButton]);
      const currentQuestion = selfAssesmentData[currentIndex];
      setQuetionAsked(currentQuestion.question);
      setOptionOne(mapAnswersToOptions(currentQuestion.answers, currentQuestion.category));
      setRadioButton([currentQuestion.answers[0]]);
      setActive([0]);
      updatePercentageComplete();
    }
  }, [currentIndex]);

  useEffect(() => {
    setSelectedOption(radioButton);
  }, [radioButton]);

  // Map answers to options with levels
  const mapAnswersToOptions = (answers, category) => {
    const levels = ["A", "B", "C", "D"];
    return answers.map((answer, index) => ({
      ...answer,
      level: levels[index],
      category_id: category,
    }));
  };

  // Update the percentage completion
  const updatePercentageComplete = () => {
    const percentile = 100 / selfAssesmentData.length;
    if (currentIndex === 0) {
      setPercentageComplete(50);
    }
    setPercentageComplete((prev) => prev + percentile);
  };

  // Request data from server
  const requestDataFromServer = () => {
    setLoading(true);
    const obj = { mode: userType.payload === "jobSeeker" ? 1 : 2 };
    const apiCall = userType.payload === "jobSeeker" ? postApi : postApiPoster;
    apiCall(API_SELF_ASSESMENT_CATEGORY_OPTIONS, obj, userToken, successFunction, errorFunction);
  };

  // Success function for fetching data
  const successFunction = (response) => {
    setLoading(false);
    setFetchedData(true);
    if (response.status === 200) {
      updateDataArray(response.data.result);
      initializeFirstQuestion(response.data.result);
    }
  };

  // Initialize the first question
  const initializeFirstQuestion = (data) => {
    const firstQuestion = data[0];
    setQuetionAsked(firstQuestion.question);
    setOptionOne(mapAnswersToOptions(firstQuestion.answers, firstQuestion.category));
    setRadioButton([firstQuestion.answers[0]]);
    setActive([0]);
  };

  // Update data array with category and level
  const updateDataArray = (data) => {
    const updatedList = data.map((item) => ({
      ...item,
      answers: item.answers.map((subItem, index) => ({
        ...subItem,
        category_id: item.category,
        level: String.fromCharCode(65 + index), // "A" to "I"
      })),
    }));
    setselfAssesmentData(updatedList);
  };

  // Error function for fetching data
  const errorFunction = (error) => {
    setLoading(false);
    setFetchedData(true);
    console.log(error);
  };

  // Fetch self-assessment categories
  const getSelfAssesmentCategory = () => {
    setLoading(true);
    const mode = userType.payload === "jobSeeker" ? 1 : 2;
    const axiosConfig = {
      headers: {
        type: "front-end",
        mode,
        Authorization: `Bearer ${userToken}`,
      },
    };
    getApi(API_SELF_ASSESMENT_CATEGORY, axiosConfig, getCategorySuccess, getCategroyFailure);
  };

  const getCategorySuccess = (res) => {
    setLoading(false);
    if (res.status === 200) {
      setCategoryList(res.data.category);
      requestDataFromServer();
    }
  };

  const getCategroyFailure = (err) => {
    setLoading(false);
    console.log(err);
  };

  useEffect(() => {
    if (!isCategoryFetched) {
      getSelfAssesmentCategory();
    }
  }, [isCategoryFetched]);

  return (
    <React.Fragment>
      {isLoading && (
        <div className="d-flex justify-content-center align-items-center LoaderCenter">
          <div className="block">
            <span className="spinner-grow spinner-grow-lg mx-2" role="status" aria-hidden="true" />
            <h6 className="LoaderText">Processing...</h6>
          </div>
        </div>
      )}
      {!isLoading && (
        <section id="discover-personality-steps">
          <div className="discover-personality-top-cnt">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <img src={Blk} alt="blk" />
                  <p>
                    {quetionAsked && quetionAsked}
                    <strong>Which scenario describes you best</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <GoBack />
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="card" style={{ borderWidth: 0 }}>
                  <form id="msform">
                    <fieldset>
                      <div className="form-card">
                        {optionOne.map((data, i) => (
                          <nav className="border-nav" key={i} onClick={() => setActive([i])}>
                            <div className="nav nav-tabs">
                              <button className={
                                  isActive.includes(i)
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                type="button"  onClick={() => {
                                  setChecked(false);
                                  setRadioButton([data]);
                                }}>
                                {data.level}
                              </button>
                            </div>
                          </nav>
                        ))}
                        {radioButton.map((data, i) => (
                          <div key={i + 3} className="tab-content" id="nav-tabContent">
                            <div key={i + 4} className="tab-pane fade show active" id="step-1" role="tabpanel" aria-labelledby="nav-home-tab">
                              <div key={i + 5} className="discover-check-cnt">
                                <label key={i + 6} className="steps-checkbox">
                                  <input key={i + 7} type="checkbox" checked={isChecked} onChange={() => setChecked(!isChecked)}/>
                                  <span key={i + 8} className="checkmark" />
                                </label>
                                <p key={i + 9}>{data.options}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <input type="button" name="next" className="next action-button" value="" onClick={saveInfo}/>
                      <div className="row">
                        <div className="col-12">
                          <h2 className="steps">
                            Question {currentIndex + 1}
                            {"/"}
                            {selfAssesmentData.length}
                          </h2>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                  <div className="progress">
                    <div className="progress-bar" role="progressbar" aria-valuemin="0" style={{ width: percentageComplete + `%` }} aria-valuemax="100"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
}

export default SelfAssestOne;
