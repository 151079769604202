import axios from 'axios';
import { BASE_URL } from '../utils';

export const postData = (url, data) => {
    const userSessionString = sessionStorage.getItem('UserSession');
    const userSession = JSON.parse(userSessionString);

    const token = userSession && userSession.payload ? userSession.payload.token : null;
    const endpoint = `${BASE_URL}${url}`
    const config = {
        method: 'post',
        url: endpoint,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Accept-Language': 'en-GB,en',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json' 
        },
        data: data
    };
    if (!token) {
        console.error('No token found in user session.');
        return Promise.reject('Authentication token is missing.');
    }

    return axios(config);
}

export const getData = (url) => {
    const userSessionString = sessionStorage.getItem('UserSession');
    const userSession = JSON.parse(userSessionString);

    const token = userSession && userSession.payload ? userSession.payload.token : null;
    const endpoint = `${BASE_URL}${url}`
    const config = {
        method: 'get',
        url: endpoint,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Accept-Language': 'en-GB,en',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json' 
        }
    };
    if (!token) {
        console.error('No token found in user session.');
        return Promise.reject('Authentication token is missing.');
    }

    return axios(config);
}

export const downloadFile = (_api_path, _data) => {
    const userSessionString = sessionStorage.getItem('UserSession');
    const userSession = JSON.parse(userSessionString);

    const token = userSession && userSession.payload ? userSession.payload.token : null;
    const endpoint = `${BASE_URL}${_api_path}`;
    const config = {
        method: 'post',
        url: endpoint,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Accept-Language': 'en-GB,en',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        responseType: 'blob', // Important to handle binary data
        data: _data
    };

    if (!token) {
        console.error('No token found in user session.');
        return Promise.reject('Authentication token is missing.');
    }

    return axios(config)
};