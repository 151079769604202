import React from 'react';
import eagle from '../../../assets/images/eagle-img.png';
import bulk from '../../../assets/images/logo-icon-blk.png';
import { useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';
import GoBack from '../../../components/GoBack';
function LandingPage() {
  const navigation = useNavigate();
  const userType = useSelector(state => state.userType.userType);
  // const userToken = useSelector((state) => state.UserAuth.userData.payload.token);
  // const userId = useSelector((state) => state.UserAuth.userData.payload.user._id);
  // <GoBack />
  return (
    <section id='get-started-page'>
      <div className='container'>
        <div className='row justify-content-center'>
          <GoBack />
          <div className='col-lg-8 text-center d-flex flex-column justify-content-center align-items-center'>
            <div className='brand-logo'>
              <img className='img-fluid' src={bulk} alt='bulk' style={{ width: '80px' }} />
            </div>
            <div className='col-lg-9'>
              {userType.payload === 'jobSeeker' ? (
                <h6>Let’s build your personal bio so we can find the perfect job for you </h6>
              ) : (
                <h6>Let's build A persona for the type of person you are looking to hire </h6>
              )}
            </div>

            <div className='get-strarted-button' style={{ zIndex: 100 }}>

              <br />
              <button className='graphics-btn' onClick={() => navigation('../jobRole')}>
                Get Started
              </button>
              <br />
              <br />
              <img src={eagle} alt='eagle' width='250px' className='d-block d-sm-none img-fluid' />




            </div>
            <br />
            <img src={eagle} alt='eagle' width='250px' className='d-lg-block d-none img-fluid' />
          </div>
          {/* // <!--col-8--> */}
        </div>
        {/* <!--row--> */}
      </div>
    </section>
  );
}

export default LandingPage;
