import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getsPlansList as getsPlansListApi } from "../../../api/plans";
import GoBack from "../../../components/GoBack";
import './boost-profile.css'

const EnhanceProfile = () => {
const navigate = useNavigate();
const userType = useSelector(state => state.userType.userType);
const userToken = useSelector(state => state.UserAuth.userData.payload.token);
const [planList, setPlanList] = useState([]);
const isNewPage = window.location.pathname === '/boostProfile/new';
// console.log('isNewPage: ', isNewPage)

useEffect(() => {
    loadData();
}, [userToken]);

const loadData = () => {
    getsPlansListApi().then((_resp) => {
        const records = _resp.data.result;
        const plans = records.filter(plan => plan.credit < 0);
        setPlanList(plans);
    }).catch((_resp) => {
        console.error(_resp);
    });
};

const handleRedirect = () => {
    const destination = userType.payload === 'jobSeeker' ? '../mainMenu' : '../currentJob';
    navigate(destination);
};

return (
<section className='enhanced-profile-v2'>
    <div id="page">
        {!isNewPage?
            <GoBack/>
        : null}
        <div className="d-flex justify-content-center">
            <img src='/boost-profile/logo_raw.png' className="logo" alt="Profile Boost Logo" />
        </div>
        <div className="d-flex justify-content-center heading-text">
            <div className="d-flex align-items-center">
                <h1 className="boost-text">
                    BOOST
                </h1>
            </div>
            <div className="d-flex align-items-center">
                <h3 className="boost-sub-text">
                    your Profile
                </h3>
            </div>
        </div>
        {isNewPage?
            <div className="d-flex justify-content-center">
                <span onClick={() => navigate('/homepage')} className="text-center text-white" style={{'cursor': 'pointer'}}>skip</span>
            </div>
        : null}
        {/* <div className="d-flex justify-content-center">
            <div className="page-subtext-container d-flex align-items-center text-center">
                <p className="page-subtext-text ms-2 mb-0 pb-0">Rank higher and be seen by more hiring managers. It’s a once off fee.</p>
            </div>
        </div> */}
        <div className="row d-flex justify-content-center">
            <div className="col-4 mt-4">
                <div className="d-flex justify-content-center">
                    <div className="money-card-shadow"></div>
                    <div onClick={() => navigate('../paymentMethod', { state: { planInfo: planList[0] } })} type='button' className="money-card d-flex align-items-center">
                        <span className="text-center w-100 fs-1">GBP8.99</span>
                    </div>
                </div>
            </div>
        </div>
        {/* <div className="row d-flex justify-content-center">
            <div className="col-lg-8 col-md-10 col-8 d-flex justify-content-between">
                <div className="star-group">
                    <img src="/boost-profile/small_star.png" alt="Small Star" className="small-star"/>
                    <img src="/boost-profile/med_star.png" alt="Medium Star" className="medium-star"/>
                </div>
                <img src="/boost-profile/big_star_raw.png" alt="Big Star" className="big-star"/>
            </div>
        </div> */}
            <div className="row mt-4 d-flex justify-content-center">
                <div className="col-xl-4 col-lg-3 col-md-2 col-sm-1 col-xs-1 d-none d-sm-block" style={{ height: '50vh', position: 'relative' }}>
                    <img src="/boost-profile/big_star_raw.png" alt="Big Star" className="medium-star float-end" style={{ position: 'absolute', top: 0, right: 0 }} />
                    <img src="/boost-profile/big_star_raw.png" alt="Big Star" className="medium-star float-end" style={{ position: 'absolute', bottom: 0, right: 0 }} />
                </div>
                <div className="col-xl-4 col-lg-6 col-md-7 col-sm-8 col-xs-8 d-flex justify-content-center">
                    <img src="/boost-profile/mobile.png" className="mobile-img" alt="" />
                    <div className="mobile-card">
                        <span>Up to 10.3x more profile views. </span>
                        <br />
                        <b>Once off fee.</b>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-3 col-md-2 col-sm-1 col-xs-1 d-none d-sm-block d-flex align-content-center" style={{height: '40vh'}}>
                    <img src="/boost-profile/big_star_raw.png" alt="Big Star" className="big-star float-start"/>
                </div>
            </div>
            
            {/* <div className="clouds-container">
                <img className="clouds-container-img" src="/boost-profile/clouds_raw.png" alt="" />
            </div> */}
    </div>
</section>
);
};

export default EnhanceProfile;