import React, { useEffect } from "react";
import EagleMan from "../../../assets/images/eagle-man-img.png";
import ArrowIcon from '../../../assets/images/right-arrow.png';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { postApi } from "../../../utils";
import Swal from "sweetalert2";
import { useSelector } from 'react-redux';
import { API_UPDATE_FEEDBACK } from './../../../utils/APIConstant';
import HeaderMenu from "../../../components/HeaderMenu/headerMenu";
import SideMenu from "../../../components/SideMenu/sideMenu";
import { useState } from "react";
import { toast } from "react-toastify";

export default function FeedBack() {
    const userId = useSelector((state) => state.UserAuth.userData.payload.user._id);
    const userType = useSelector((state) => state.userType.userType);
    const userToken = useSelector(
        (state) => state.UserAuth.userData.payload.token
    );
 
    const [ feedbackReason, setCompanySize ] = useState();
    const [ data, setData ] = useState();
    const { state } = useLocation();
    const navigation = useNavigate();
    // const personalInfo = state?.personalInfo[0]
    // const info = state?.info
    const image = "" //personalInfo?.imagePath ? personalInfo?.imagePath : personalInfo?.destination
     const jobLocation = "" //personalInfo?.location
     const agentName = "" //personalInfo?.fullName
    // const jobTitle = info?.jobTitle[0]?.option
     const comapnyName = "" //personalInfo?.companyName ? personalInfo?.companyName : jobTitle

    useEffect(() => {
    setData(state)
    }, []);


    const feedbackPoints = [
		{ id: '1', title: 'Wrong role' },
		{ id: '2', title: 'Wrong job title' },
		{ id: '3', title: 'Wrong industry' },
		{ id: '4', title: 'Wrong skill level' },
		{ id: '5', title: 'Wrong location' },
        { id: '6', title: 'Wrong company size' },
		{ id: '7', title: 'Wrong salary amount' },
        { id: '8', title: 'Other' },
	];
    
     const UpdateInterst = () => {
        if(feedbackReason !== '' && feedbackReason !== undefined){
        if (userType.payload === 'jobSeeker') {
            const obj = {
                type: 1,
                seekerId: data.seekerId,
                posterId: data.posterId,
                jobId: data.jobId,
                seekerFeedBack:feedbackReason,
                status: 0,
                isDislike:1
            }
            postApi(API_UPDATE_FEEDBACK, obj, userToken, sucessFunction, errorFunction)

        } else if (userType.payload === 'jobPoster') {
            const obj = {
                type: 2,
                seekerId: data.seekerId,
                posterId: data.posterId,
                jobId: data.jobId,
                posterFeedBack:feedbackReason,
                status: 0,
                isDislike:1
            };
            postApi(API_UPDATE_FEEDBACK, obj, userToken, sucessFunction, errorFunction)
        }
    }else{
            toast.error('Please choose any of the option or skip', {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
        }
    }

    const sucessFunction = (res) => {
        navigation("../homepage");
        toast.success('Your feedback has been added successfully. !', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
    }

    const errorFunction = (err) => {
        console.log(err);
    }


    return (
        <div>
            <HeaderMenu />
            <section id="dashboard-cnt-area">
                <div className="container">
                    <div className="row dashboard-cnt-row  dashboard-sidebar">
                        <SideMenu />

                        <div className="col-lg-9 col-sm-8 col-xs-12 mircroshoft-cnt">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <div className="mircroshoft-heading">
                                    <h4>
                                    {comapnyName}
                                    <i className="fa fa-heart" aria-hidden="true" />
                                    </h4>
                                    <h6>{agentName}</h6>
                                    <p>{jobLocation}</p>

                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <div className="mircroshoft-logo">
                                        <img className="img-fluid" src={image} style={{objectFit: 'cover'}} />

                                    </div>
                                </div>

                                <br />
                                <br />
                                <div className="col-lg-12 feedback-heading">
                                    <h2>We’d appreciate your feedback</h2>
                                    <p>Please let us know why the job did not fit your needs. We learn from your responses.</p>
                                </div>


                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-7 col-md-12 col-xs-12 col-12 feedbacks-option">
                                            <ul>
                                            {feedbackPoints && feedbackPoints.map((data, i) => (
                                                <li key={i} onClick={() => setCompanySize(data.title)} ><a   className={feedbackReason && feedbackReason === data.title ? "active" : "" }>{data.title}</a></li>
                                               
                                             ))} 
                                            </ul>

                                            <div className="feedback-action-btn">
                                                <a  onClick={() => UpdateInterst()}  ><img className="img-fluid" src={ArrowIcon} /></a>
                                                <Link to="../../homepage"><span>Skip</span></Link>
                                            </div>


                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footer className="dashboard-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-xs-6 col-12 eagle-man-col">
                            <img src={EagleMan} />
                        </div>
                        <div className="col-lg-8 col-sm-12 col-xs-12 col-12 text-center">
                            <p>Copyright © 2022 perspectv.com. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}




