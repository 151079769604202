import React from "react";
import Logo from "../../../../assets/images/logo-icon-blk.png";
import "./LogoSearch.css";

const LogoSearch = () => {

  // searchHandler(event){
  //   let keyword =event.target.value;
  //   let filtered=this.state.allData.filter((item)=>{
  //     return item.companyName.indexOf(keyword) > -1
  //   });
  //   if (keyword === "") {
  //     filtered = [];
  //   }
  //   this.setState({
  //     filtered
  //   })
  // }

  return (
    <div className="LogoSearch serach-img">
      <img src={Logo} alt="" />
     
    </div>
  );
};

export default LogoSearch;
