import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderMenu from '../../../components/HeaderMenu/headerMenu';
import SideMenu from '../../../components/SideMenu/sideMenu';
import {
  API_JOB_LIST_WISHLIST,
  API_JOB_DELETE_WISHLIST,
  getApi,
  postApi,
  API_PERSONAL_BUY_MULTIPLE_PROFILE,
  API_CURRENT_USER,
} from '../../../utils';
import moment from 'moment';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import GoBack from '../../../components/GoBack';

function WishList() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [user, setUser] = useState();
  const userToken = useSelector(state => state.UserAuth.userData.payload.token);
  const userDetail = useSelector(state => state.UserAuth.userData.payload.user);
  const userType = useSelector(state => state.userType.userType);

  useEffect(() => {
    getCreditHistory();
  }, []);

  useEffect(() => {
    requestDataFromServer();
  }, []);

  const requestDataFromServer = () => {
    var obj = {
      // usertype: 1,
      _id: userDetail._id,
    };
    postApi(API_CURRENT_USER, obj, userToken, sucessFunctionWishlist, errorFunction);
  };

  const sucessFunctionWishlist = res => {
    setUser(res.data.data);
  };

  const getCreditHistory = () => {
    let axiosConfig = {
      headers: {
        Authorization: 'Bearer ' + userToken,
      },
    };
    getApi(API_JOB_LIST_WISHLIST, axiosConfig, sucessFunction, errorFunction);
  };

  const removeWishlist = (seekerId, pointInfo) => {
    const obj = {
      seekerId: seekerId,
      pointInfo: pointInfo,
    };
    postApi(API_JOB_DELETE_WISHLIST, obj, userToken, successFunction, errorFunction);
  };
  const PurchaseProfile = () => {
    const allId = [];
    data?.length > 0 &&
      data?.map((res, i) => {
        allId.push(res.pointInfo);
      });

    if (user?.planCredit < data?.length) {
      // there is not enough credit
      console.log('----this is all ids', allId);
      navigate('../payAsYouGoScreen', {
        state: { creditsNeeded: data?.length, profileId: allId },
      });
    } else {
      const obj = {
        pointInfoId: allId,
        credit: data?.length,
      };

      Swal.fire({
        title: `To purchase this profile. You will charged ${data?.length} credit from your credit wallet`,
        //text: "You don`t have credit purchase to this profile",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then(result => {
        if (result.isConfirmed) {
          postApi(
            API_PERSONAL_BUY_MULTIPLE_PROFILE,
            obj,
            userToken,
            sucessFunctionPur,
            errorFunction
          );
        }
      });
    }
  };

  const successFunction = res => {
    getCreditHistory();
    toast.success(res.data.message, {
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    //setData(res.data.data)
  };

  const sucessFunction = res => {
    setData(res.data.data);
    console.log(res);
  };

  const sucessFunctionPur = res => {
    getCreditHistory();
    toast.success(res.data.message, {
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const errorFunction = err => {
    toast.error(err.response.data.message, {
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <div>
      <HeaderMenu />
      <section id='dashboard-cnt-area'>
        <div className='container'>
          <GoBack />
          <div className='row dashboard-cnt-row'>
            {/* <img className="img-fluid eagle-man" src={Landscape} /> */}
            {(userType.payload !== "jobPoster") && (
                <SideMenu />
            )}
            {(userType.payload === "jobPoster") && (
                <div className="col-lg-3 col-sm-4 col-xs-12"></div>
            )}
            <div className='col-lg-9 col-sm-8 col-xs-12 text-center wishlist-table'>
              <div className='row my-3'>
                <div className='col-lg-12 recent-page-heading-jp mb-4'>
                  <a href='javascript:void(0)'>Manage Talent list</a>
                </div>
              </div>
              {/* <div className="text-right mb-2">
                <a href="" className="add-btn">
                  Add Profile
                </a>
              </div> */}
              <div className='table-responsive'>
                <table className='table table-bordered'>
                  <thead>
                    <tr>
                      <th>Job Seeker</th>
                      {/* <th>Job Title</th> */}
                      <th>Credits Required</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.length > 0 &&
                      data?.map((res, i) => (
                        <tr key={res._id}>
                          <td>{res.seekerId.fullName?.split(' ')?.[0] ?? res.seekerId.fullName}</td>
                          {/* <td>system engg</td> */}
                          <td>{res.credit}</td>
                          <td>
                            <a
                              href='javascript:void(0)'
                              onClick={() => removeWishlist(res.seekerId._id, res.pointInfo)}
                              className='bg-red'
                            >
                              <i className='fas fa-trash'></i>
                            </a>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className='text-right credits col-lg-12'>
                <p>Total Credits:{data?.length}</p>
                <p>Available Credits : {user ? user?.planCredit : 0}</p>
              </div>
              <div className='text-right  col-lg-12 mt-3 d-flex justify-content-end'>
                <a href='javascript:void(0)' className='purchase-btn' onClick={() => PurchaseProfile()}>
                  Purchase
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default WishList;
