import { postData } from "./requests";

export const getMatchesForJob = (_job_id, _filter) => {
    const url = 'api/common/matchesV2';
    const requestData = {
        'job_id': _job_id,
        'filter': _filter
    };
    return postData(url, requestData)
}

export const getMatchesForSeeker = (_seeker_id, _filter) => {
    const url = 'api/common/seeker-matches';
    const requestData = {
        'seeker_id': _seeker_id,
        'filter': _filter
    };
    return postData(url, requestData)
}

export const updateInterest = (_payload) => {
    const url = 'api/personal/updateinterest'
    return postData(url, _payload)
}