import React, { useState, useEffect } from 'react';
import { PaymentRequestButtonElement, useStripe, useElements } from '@stripe/react-stripe-js';
import AddCard from '../addCard';

const AppleAndGooglePay = () => {
	const stripe = useStripe();
	const elements = useElements();
	const [paymentRequest, setPaymentRequest] = useState(null);
	const [paymentCompleted, setPaymentCompleted] = useState(false);
	//Intent to check payment method on server side
	const handlePaymentMethodReceived = async (event) => {
		// Send the payment details to our function.
		const paymentDetails = {
			payment_method: event.paymentMethod.id,
			shipping: {
				name: event.shippingAddress.recipient,
				phone: event.shippingAddress.phone,
				address: {
					line1: event.shippingAddress.addressLine[0],
					city: event.shippingAddress.city,
					postal_code: event.shippingAddress.postalCode,
					state: event.shippingAddress.region,
					country: event.shippingAddress.country
				}
			}
		};
		const response = await fetch('./functions/create-payment-intent', {
			method: 'post',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ paymentDetails })
		}).then((res) => {
			return res.json();
		});
		if (response.error) {
			// Report to the browser that the payment failed.
			console.log(response.error);
			event.complete('fail');
		} else {
			// Report to the browser that the confirmation was successful, prompting
			// it to close the browser payment method collection interface.
			event.complete('success');
			// Let Stripe.js handle the rest of the payment flow, including 3D Secure if needed.
			const { error, paymentIntent } = await stripe.confirmCardPayment(response.paymentIntent.client_secret);
			if (error) {
				console.log(error);
				return;
			}
			if (paymentIntent.status === 'succeeded') {
				// history.push('/success');
			} else {
				console.warn(`Unexpected status: ${paymentIntent.status} for ${paymentIntent}`);
			}
		}
	};
	useEffect(
		() => {
			if (!stripe || !elements) {
				return;
			}

			const pr = stripe.paymentRequest({
				country: 'IN',
				currency: 'all',
				requestPayerName: true,
				requestPayerEmail: true,
				total: {
					label: 'Demo total',
					amount: 1350
				}
			});
			// Check the availability of the Payment Request API first.
			pr.canMakePayment().then((result) => {
				if (result) {
					console.log(result, result);
					pr.on('paymentmethod', handlePaymentMethodReceived);
					setPaymentRequest(pr);
				}
			});
		},
		[stripe, elements]
	);
	const successMessage = () => {
		return (
			<div className="success-msg">
				<svg
					width="1em"
					height="1em"
					viewBox="0 0 16 16"
					className="bi bi-check2"
					fill="currentColor"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fill-rule="evenodd"
						d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"
					/>
				</svg>
				<div className="title">Payment Successful</div>
			</div>
		);
	};
	// Use a traditional checkout form.
	return (
		<React.Fragment>
			{paymentRequest && <PaymentRequestButtonElement options={{ paymentRequest }} />}
			{!paymentRequest && paymentCompleted ? (
				successMessage()
			) : (
				<AddCard amount={'£35'} setPaymentCompleted={setPaymentCompleted} />
			)}
		</React.Fragment>
	);
};

export default AppleAndGooglePay