import React, { useState, useEffect } from "react";
import brand from "../../../../assets/images/logo-icon-blk.png";
import {
  API_SEEKER_JOB_ROLE_OPTIONS,
  API_SEEKER_JOB_ROLE_TITLE,
  API_SEEKER_ADD_JOB_ROLE,
  postApi,
  postApiPoster,
} from "../../../../utils";

import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { toast } from "react-toastify";
import { Modal, ModalBody } from "react-bootstrap";
import axios from "axios";

function EducationUpdate({
  prevEducation,
  educationModal,
  setEducationModal,
  updateProfileFunc,
}) {
  //*******Getting router passed data***************************/
  const { state } = useLocation();
  const [routerData] = useState(state);
  const navigation = useNavigate();
  const [isFetchedData, setFetchedData] = useState(false);
  const [jobTitle, setJobTitle] = useState("");
  const [jobRolesForSuggestions, setjobRolesForSuggestions] = useState([]);

  const [jobEucationListed, setEducationLisited] = useState([]);
  const [educationLevel, setEducationLevel] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [userCreatedRole, setUserCreatedRole] = useState("");

  const userType = useSelector((state) => state.userType.userType);
  const userToken = useSelector(
    (state) => state.UserAuth.userData.payload.token
  );
  const userId = useSelector(
    (state) => state.UserAuth.userData.payload.user._id
  );

  const [educationSelectedForInput, setEducationSelectedForInput] =
    useState("");

  const [selectedFromSuggestion, setSelectedFromSuggestion] = useState("");
  const [suggestionOff, setSuggestionOff] = useState(false);
  //call api
  const requestDataFromServer = () => {
    setLoading(true);

    const obj = {
      category: "educationlevel",
    };
    if (userType.payload === "jobSeeker") {
      postApi(
        API_SEEKER_JOB_ROLE_TITLE,
        obj,
        userToken,
        successFunction,
        errorFunction
      );
      postApi(
        API_SEEKER_JOB_ROLE_OPTIONS,
        obj,
        userToken,
        successFunctionOptions,
        errorFunctionOptions
      );
    } else {
      postApiPoster(
        API_SEEKER_JOB_ROLE_TITLE,
        obj,
        userToken,
        successFunction,
        errorFunction
      );
      postApiPoster(
        API_SEEKER_JOB_ROLE_OPTIONS,
        obj,
        userToken,
        successFunctionOptions,
        errorFunctionOptions
      );
    }
  };
  const successFunction = (response) => {
    if (response.status === 200) {
      // console.log(response.data.result, 'tiltterere');
      setJobTitle(response.data.result);
    }
  };
  const errorFunction = (error) => {
    setLoading(false);
    setFetchedData(true);
    // console.log(error, "drerer");
  };
  const successFunctionOptions = (response) => {
    setLoading(false);
    setFetchedData(true);

    if (response.status === 200) {
      // setEducationLisited(response.data.result);
      const adminData = response?.data?.result.filter((item) => {
        return item.addedby === "3";
      });
      setjobRolesForSuggestions(adminData);
      // console.log("get filter data -----", adminData);
      if (adminData.length > 9) {
        adminData.length = 9;
      }
      setEducationLisited(adminData);
    }

    // console.log(response);
  };
  const errorFunctionOptions = (error) => {
    setLoading(false);
    setFetchedData(true);
    // console.log(error);
  };
  useEffect(() => {
    // console.log("get user data-------");
    if (!isFetchedData) {
      requestDataFromServer();
    }
  }, [isFetchedData]);
  //*******Save user data in server and naviagte to another page***********************************//
  const savingInfo = async (mode) => {
    let obj = {};
    if (userType.payload === "jobSeeker") {
      obj = {
        option: userCreatedRole,
        category: "educationlevel",
        usertype: "1",
        status: "2",
        userId: userId,
        addedby: "1",
      };
    } else {
      obj = {
        option: userCreatedRole,
        category: "educationlevel",
        usertype: "2",
        status: "2",
        userId: userId,
        addedby: "2",
      };
    }

    const axiosConfig = {
      headers: {
        type: "front-end",
        mode: mode == null ? 1 : mode,
        Authorization: "Bearer " + userToken,
      },
    };

    try {
      const data = await axios.post(API_SEEKER_ADD_JOB_ROLE, obj, axiosConfig);
      setEducationModal(false);
      updateProfileFunc("educationLevelId", data?.data?.data?._id);
    } catch (error) {
      toast.error("Something went wrong!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    // if (educationLevel.length > 0) {
    //   const [id] = educationLevel;
    //   // navigation("../skillLevel", {
    //   //   state: { ...routerData, educationLevelId: id },
    //   // });
    //   setEducationModal(false);
    //   updateProfileFunc("educationLevelId", id);
    // } else if (userCreatedRole) {
    //   //*************************User created job manually*****************************/
    //   //********************************On loader when user saves data******************************
    //   setLoading(true);
    //   //*******Save info Api
    //   if (userType.payload === "jobSeeker") {
    //     const obj = {
    //       option: userCreatedRole,
    //       category: "educationlevel",
    //       usertype: "1",
    //       status: "2",
    //       userId: userId,
    //       addedby: "1",
    //     };
    //     postApi(
    //       API_SEEKER_ADD_JOB_ROLE,
    //       obj,
    //       userToken,
    //       saveDataSuccessFunction,
    //       errorSavingData
    //     );
    //   } else {
    //     const obj = {
    //       option: userCreatedRole,
    //       category: "educationlevel",
    //       usertype: "2",
    //       status: "2",
    //       userId: userId,
    //       addedby: "2",
    //     };
    //     postApiPoster(
    //       API_SEEKER_ADD_JOB_ROLE,
    //       obj,
    //       userToken,
    //       saveDataSuccessFunction,
    //       errorSavingData
    //     );
    //   }
    // } else {
    //   toast.error("Please choose any of the option or type manually!", {
    //     position: "top-right",
    //     autoClose: 1000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
  };
  //If data is saved suceessfully
  const saveDataSuccessFunction = (res) => {
    setLoading(false);
    if (res.status === 200) {
      const customId = res.data.data._id;

      navigation("../skillLevel", {
        state: { ...routerData, educationLevelId: customId },
      });
    }
  };
  //!error occured in saving data
  const errorSavingData = (err) => {
    toast.error(err.data.message, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <Modal show={educationModal}>
      <ModalBody>
        <button
          className="position-absolute"
          style={{
            width: "100px",
            backgroundColor: "transparent",
            top: "20px",
            left: "0px",
            fontSize: "30px",
            zIndex: "333",
            border: "none",
          }}
          onClick={() => setEducationModal(false)}
        >
          <i className="fa fa-arrow-circle-left" aria-hidden="true"></i>
        </button>
        {isLoading && (
          <div className="d-flex justify-content-center align-items-center LoaderCenter">
            {" "}
            <div className="block">
              <span
                className="spinner-grow spinner-grow-lg mx-2"
                role="status"
                aria-hidden="true"
              />
              <h6 className="LoaderText">Processing...</h6>
            </div>
          </div>
        )}
        {!isLoading && (
          <section id="job-role-steps">
            <div className="discover-personality-top-cnt">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <img src={brand} alt="brand" />
                  </div>
                </div>
              </div>
            </div>
            {/* <!--discover-personality-top-cnt--> */}

            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-2" />
                <div className="col-lg-12">
                  <div className="card" style={{ borderWidth: 0 }}>
                    <form id="msform">
                      {/* <!-- progressbar --> */}

                      <fieldset>
                        <div className="form-card">
                          {userType.payload === "jobSeeker"
                            ? jobTitle &&
                              jobTitle.map((data) => (
                                <h3 key={data._id}>{data.question}</h3>
                              ))
                            : jobTitle &&
                              jobTitle.map((data) => (
                                <h3 key={data._id}>{data.question}</h3>
                              ))}
                          <div className="role-wrapper">
                            {jobEucationListed &&
                              jobEucationListed.map((data, i) => (
                                <div key={i} className="role-col">
                                  <div key={i + 1} className="inputGroup">
                                    <input
                                      key={i + 2}
                                      id="radio28"
                                      name="radio"
                                      checked={
                                        educationLevel.length > 0
                                          ? educationLevel.includes(data._id)
                                          : data.option == prevEducation
                                      }
                                      readOnly
                                      type="radio"
                                    />
                                    <label
                                      key={i + 3}
                                      htmlFor="radio28"
                                      onClick={() =>{
                                        setEducationLevel([data._id])
                                        setUserCreatedRole(data.option);
                                      }}
                                    >
                                      {data.option}
                                    </label>
                                  </div>
                                </div>
                              ))}
                          </div>
                          {/* <!--role-wrapper--> */}

                          <div className="enter-job-role">
                            {userType.payload === "jobSeeker" ? (
                              <h5>
                                Don’t see the qualification you're looking for?
                              </h5>
                            ) : (
                              <h5>
                                Don’t see the qualification you're hiring for?
                              </h5>
                            )}

                            <input
                              type="text"
                              id="name"
                              name="name"
                              placeholder="Type your qualification field here..."
                              required=""
                              value={educationSelectedForInput}
                              onFocus={(event) => {
                                event.target.setAttribute(
                                  "autocomplete",
                                  "off"
                                );
                                // console.log(event.target.autocomplete);
                              }}
                              onChange={(e) => {
                                setEducationLevel([]);
                                setUserCreatedRole(e.target.value);
                                setEducationSelectedForInput(e.target.value);
                                if (e.target.value === "") {
                                  setSuggestionOff(false);
                                  setSelectedFromSuggestion("");
                                }
                              }}
                            />
                          </div>
                        </div>

                        {/* {userCreatedRole &&
                          !suggestionOff &&
                          !selectedFromSuggestion &&
                          jobRolesForSuggestions?.filter(skill =>
                            skill.option.toLowerCase().includes(userCreatedRole.toLowerCase())
                          ).length > 0 && (
                            <div className='position-absolute  jobs-dropdown-update'>
                              {jobRolesForSuggestions
                                ?.filter(skill =>
                                  skill.option
                                    .toLowerCase()
                                    .includes(userCreatedRole.toLowerCase())
                                )
                                ?.slice(0, 5)
                                ?.map(skill => (
                                  <div
                                    style={{
                                      padding: '5px 0',
                                      borderRadius: '5px',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      setUserCreatedRole(skill.option);
                                      setSelectedFromSuggestion(skill.option);
                                      setSuggestionOff(true);
                                      setEducationLevel([skill._id]);
                                      setEducationSelectedForInput('');
                                      // setJobRoleSelected([skill._id])
                                    }}
                                  >
                                    {skill.option}
                                  </div>
                                ))}
                            </div>
                          )} */}

                        <input
                          type="button"
                          name="next"
                          className="form-btn mt-4"
                          value="update"
                          onClick={savingInfo}
                        />
                      </fieldset>
                    </form>
                  </div>
                </div>
                <div className="col-lg-2" />
              </div>
            </div>
          </section>
        )}
      </ModalBody>
    </Modal>
  );
}

export default EducationUpdate;
