import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderMenu from "../../../components/HeaderMenu/headerMenu";
import SideMenu from "../../../components/SideMenu/sideMenu";
import { API_JOB_POSTER_CREDIT, getApi } from "../../../utils";

function Credit() {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [creditData, setCredit] = useState(0);
  const userToken = useSelector(
    (state) => state.UserAuth.userData.payload.token
  );
  const userType = useSelector(state => state.userType.userType);
  const { state } = useLocation();
  useEffect(() => {
    getCreditHistory();
    setCredit(state?.planCredit);
  }, []);

  const getCreditHistory = () => {
    let axiosConfig = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };
    getApi(API_JOB_POSTER_CREDIT, axiosConfig, sucessFunction, errorFunction);
  };

  const sucessFunction = (res) => {
    setData(res.data.data);
  };

  const errorFunction = (err) => {
    console.log(err);
  };

  return (
    <div>
      <HeaderMenu />
      <section id="dashboard-cnt-area">
        <div className="container">
          <div className="row dashboard-cnt-row">
            {/* <img className="img-fluid eagle-man" src={Landscape} /> */}
            {(userType.payload !== "jobPoster") && (
                <SideMenu />
            )}
            {(userType.payload === "jobPoster") && (
                <div className="col-lg-3 col-sm-4 col-xs-12"></div>
            )}
            <div className="col-lg-9 col-sm-8 col-xs-12  text-center wishlist-table">
              <div className="row my-3">
                <div className="col-lg-12 recent-page-heading-jp mb-4">
                  <a
                    href="javascript:void(0);"
                    onClick={() =>
                      navigate("../paymentOption", { state: { planType: 1 } })
                    }
                  >
                    {" "}
                    Add Credit
                  </a>
                </div>
              </div>
              <div className="text-right credits col-lg-12">
                <p>Total Credits: €{creditData}</p>
              </div>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Plan name</th>
                      <th>Amount</th>
                      <th>Credit</th>
                      <th>Transaction ID</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.length > 0 &&
                      data?.map((res, i) => (
                        <>
                          <tr>
                            <td>
                              {moment(res.createdAt).format("YYYY/MM/DD")}
                            </td>
                            <td>{res?.planId?.title}</td>
                            <td>{res.amount}</td>
                            <td>{res?.credit}</td>
                            <td>{res?.transactionId}</td>
                          </tr>
                        </>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Credit;
