import { postData, getData } from "./requests";

export const removeFromWishlist = (_payload) => {
    const url = "api/personal/delete-wishList"
    return postData(url, _payload)
}

export const addToWishlist = (_payload) => {
    const url = "api/personal/add-wishList"
    return postData(url, _payload)
}

export const getWishlist = () => {
    const url = "api/personal/get-wishList"
    return getData(url)
}