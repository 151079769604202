import React, { useState } from 'react';
import Blk from '../../../assets/images/logo-icon-blk.png';
import Eye from '../../../assets/images/speed-eye-img.png';
import ArrowIcon from '../../../assets/images/right-arrow.png';
import Hand from '../../../assets/images/discover-hand.png';
import LoginPopImage from '../../../assets/images/login-popup-img-1.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

function RoleStepComplete() {
  const userType = useSelector(state => state.userType.userType);
  const userToken = useSelector(state => state.UserAuth.userData.payload.token);
  const navigation = useNavigate();
  const { state } = useLocation();
  const jobId = state.jobId;
  const [routerData] = useState(state);
  ///here we will store all the info to server
  const getUserType = () => {
    if (userType.payload === 'jobSeeker') {
      navigation('../skillStep', { state: { ...routerData, jobId: jobId } });
    } else {
      navigation('../skillStep', { state: { ...routerData, jobId: jobId } });
    }
  };
  return (
    <React.Fragment>
      {userType.payload === 'jobSeeker' ? (
        <section id='design-speed-screen'>
          <div className='container'>
            <div className='row justify-contentt-center'>
              <div className='col-lg-8 text-center mx-auto'>
                <div className='discover-main-screen-cnt d-flex flex-column justify-content-center align-items-center'>
                  <img src={Blk} alt={'blk'} className='w-80px' />

                  <div className='col-12 col-lg-10 col-xl-9'>
                    <h6 style={{ fontSize: '2.5rem' }}>
                      Congrats you’re a legend!
                      <br />
                      <span className='mt-3 d-block'>Now let’s see what skills you have....</span>
                    </h6>
                  </div>
                  <br />
                  <br />
                  <div className='discover-hand-icon'>
                    <img src={Eye} alt='eye' style={{ transform: 'scale(1.8)' }} />
                  </div>
                  <a
                    href='javascript:void(0)'
                    style={{ transform: 'translate(50%, 40%)' }}
                    onClick={() => getUserType()}
                  >
                    <img src={ArrowIcon} />{' '}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section id='perfect-job-poster'>
          <div className='container'>
            <div className='row justify-contentt-center'>
              <div className='col-lg-12 text-center mx-auto'>
                <div className='brand-logo'>
                  <img src={Blk} className='w-80px' />
                </div>

                <img className='img-fluid' src={LoginPopImage} style={{ marginTop: '-3%' }} />

                <div className='perfect-content'>
                  <h6 style={{ fontSize: '2rem' }} className='my-3'>
                    Perfect!
                    <br />
                    Now let’s see what skills the job requires
                  </h6>

                  <a onClick={() => getUserType()}>
                    <img src={ArrowIcon} />{' '}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
}

export default RoleStepComplete;
