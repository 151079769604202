import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ArrowIcon from "../../../assets/images/right-arrow.png";
import Blk from "../../../assets/images/logo-icon-blk.png";
import { toast } from "react-toastify";
import {
  API_SUBMIT_JOBSEEKER_DATA,
  API_SUBMIT_JOBPOSTER_DATA,
  API_SET_POINTS,
  API_HARD_STEP_DATA,
  API_COMMON_ONBOARDING_STEPS,
} from "./../../../utils/APIConstant";
import { postApi, postApiPoster } from "../../../utils";
import GoBack from "../../../components/GoBack";
import { saveUserInfo } from "../../../redux/actions";

function HardStepFour() {
  const navigation = useNavigate();
  const [checkedItems, setCheckedItems] = useState([]);
  const [isLoading, setLoading] = useState(false);
  //*******Getting router passed data***************************/
  const { state } = useLocation();
  const jobId = state.jobId;
  const userToken = useSelector(
    (state) => state.UserAuth.userData.payload.token
  );
  const userId = useSelector(
    (state) => state.UserAuth.userData.payload.user._id
  );
  console.log(userId, "id");
  const userType = useSelector((state) => state.userType.userType);
  const dispatch = useDispatch();
  //***************************Get checked list items************************* */
  const getChekedItems = item => {
    // console.log(item, 'itemsss');
    if (checkedItems.includes(item)) {
      const index = checkedItems.indexOf(item);
      checkedItems.splice(index, 1);

      setCheckedItems([...checkedItems]);
    } else {
      if (checkedItems.length < 1) {
        setCheckedItems([...checkedItems, item]);
      } else {
        toast.error('You can select only 1 options!', {
          position: 'top-right',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  const [stepData, setstepData] = useState([]);
  useEffect(() => {
    requestDataFromServer1();
  }, [checkedItems]);
  const redirectToNextPage = () => {
    if (checkedItems.length === 1) {
      saveFunction();
    } else {
      toast.error('Opps! you forgot to select items', {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const saveFunction = () => {
    //**********************************Save user info ********************************//
    setLoading(true);
    // const {
    //   educationLevelId,
    //   industryId,
    //   jobTitleId,
    //   roleId,
    //   skillLevelId,
    //   hardSkill,
    //   employmentType,
    //   employmentMode,
    //   campanySize,
    //   softSkill,
    //   selfAssessmentData,
    //   selfAssessmentResult,
    //   locationId,
    //   feelsuits1,
    //   feelsuits2,
    //   feelsuits3,
    //   isRemoteWorker
    // } = routerData;
    if (userType.payload === 'jobSeeker') {
      // const obj = {
      //   userId: userId,
      //   roleId: roleId,
      //   userType: 1,
      //   jobTitleId: jobTitleId,
      //   industryId: industryId,
      //   educationLevelId: educationLevelId,
      //   skillLevelId: skillLevelId,
      //   locationId: locationId,
      //   campanySize: campanySize,
      //   employmentType: employmentType,
      //   employmentMode: employmentMode,
      //   hardSkill: hardSkill,
      //   softSkill: softSkill,
      //   selfAssessmentData: selfAssessmentData,
      //   selfAssessmentResult: selfAssessmentResult,
      //   feelsuits1,
      //   feelsuits2,
      //   feelsuits3,
      //   feelsuits4: checkedItems,
      //   remotely: isRemoteWorker
      // };
      // console.log('obj -------- save user', obj);
      // postApi(API_SUBMIT_JOBSEEKER_DATA, obj, userToken, saveDataSuccessFunction, errorSavingData);
      updateOnBoardingSteps(checkedItems);
      // } else {
      //   const obj = {
      //     userId: userId,
      //     roleId: roleId,
      //     userType: 2,
      //     jobTitleId: jobTitleId,
      //     industryId: industryId,
      //     educationLevelId: educationLevelId,
      //     skillLevelId: skillLevelId,
      //     locationId: locationId,
      //     campanySize: campanySize,
      //     employmentType: employmentType,
      //     employmentMode: employmentMode,
      //     hardSkill: hardSkill,
      //     softSkill: softSkill,
      //     selfAssessmentData: selfAssessmentData,
      //     selfAssessmentResult: selfAssessmentResult,
      //     feelsuits1,
      //     feelsuits2,
      //     feelsuits3,
      //     feelsuits4: checkedItems,
      //     remotely: isRemoteWorker
      //   };
      //   console.log('creating job for poster--------', obj);
      //   postApiPoster(
      //     API_SUBMIT_JOBPOSTER_DATA,
      //     obj,
      //     userToken,
      //     saveDataSuccessFunction,
      //     errorSavingData
      //   );
    }
  };
  const userAuthInfo = useSelector((state) => state.UserAuth);

  //If data is saved suceessfully
  const saveDataSuccessFunction = res => {
    // console.log('get submit user info--------', res);
    setLoading(false);
    if (res.status === 200) {
      if (userType.payload === 'jobSeeker') {
        const obj = {
          userId: res.data.user.userId,
          id: res.data.user._id,
        };
        postApi(API_SET_POINTS, obj, userToken, updateBackendSuccess, errorSavingData);
      } else {
        const obj = {
          userId: res.data.user.userId,
          id: res.data.user._id,
        };
        postApiPoster(API_SET_POINTS, obj, userToken, updateBackendSuccess, errorSavingData);
      }
      console.log("auth info----", userAuthInfo)
      var updatedInfo = userAuthInfo.userData?.payload;
      updatedInfo.user.selfAssessmentResult = true;

      var existingUserInfo = userAuthInfo.userData?.payload;
      existingUserInfo.user.selfAssessmentResultSteps = 15;

      dispatch(
        saveUserInfo({
          payload: existingUserInfo,
        })
      );

      dispatch(
        saveUserInfo({ payload: updatedInfo, isRemember: userAuthInfo.userData?.isRemember })
      );
      toast.success(res.data.message, {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigation('../hardStep5', {state: {jobId: jobId}});
    }
  };

  const updateBackendSuccess = response => {
    // console.log('update backend value------', response);
  };
  //!error occured in saving data
  const errorSavingData = err => {
    console.log(err, 'errr');
    setLoading(false);
    toast.error(err.message, {
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  // Get step data...........

  const user_result = sessionStorage.getItem('UserResult');
  //console.log(user_result,'result=====');

  const requestDataFromServer1 = () => {
    const obj = {
      result: user_result,
    };
    postApi(API_HARD_STEP_DATA, obj, userToken, successFunction1, errorFunction1);
  };
  const successFunction1 = res => {
    setstepData(res.data.whb2);
  };
  const errorFunction1 = errr => {
    console.log(errr, 'erorr');
  };


  const updateOnBoardingSteps = (feelsuits4) => {

    const obj = {
      jobId: jobId,
      "userId": userId,
      "feelsuits4": feelsuits4,
      "userType": userType.payload,
      "stepNumber": 15,
      // "_id": "642d1ab16fa8534d104c5a20"
    };
    postApi(API_COMMON_ONBOARDING_STEPS, obj, userToken, saveDataSuccessFunction, errorSavingData);


  }


  const renderItem = (item, index) => {
    return (
      <div className='col-lg-4 col-sm-6 col-12'>
        <div className='inputGroup'>
          <input
            id={`check${index + 1}`}
            name='checkbox'
            type='checkbox'
            readOnly
            checked={checkedItems.includes(item)}
          />
          <label htmlFor={`check${index + 1}`} onClick={() => getChekedItems(item)}>
            {item}
          </label>
        </div>
      </div>
    );
  };

  return (
    <section id='feel-suite-best'>
      <div className='container'>
        <GoBack />

        <div className='row d-flex algin-items-center justify-content-center'>
          <div className='col-lg-6 text-center'>
            <img src={Blk} alt='blk' className='w-80px' />
            <h3 style={{ fontSize: '2rem', lineHeight: '2.5rem' }}>
              Select 1 that you feel suits you the best
            </h3>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-12 suite-best-wrapper yellow-hvr-checkbox'>
            <div className='row text-capitalize d-flex justify-content-center'>
              {stepData.map((item, index) => renderItem(item, index))}
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-12 text-center'>
            <a href='javascript:void(0)' onClick={redirectToNextPage}>
              {' '}
              <img src={ArrowIcon} alt='right' />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HardStepFour;
