import React, { useEffect, useState } from "react";
import HeaderMenu from "../../../components/HeaderMenu/headerMenu";
import SideMenu from "../../../components/SideMenu/sideMenu";
import { API_All_NOTIFICATION } from "./../../../utils/APIConstant";
import moment from "moment";
import { useSelector } from "react-redux";
import { getApi } from "../../../utils";
import DefaultImage from "../../../assets/images/defaultProfile.png";

function Notificationn() {
  const [data, setData] = useState();
  console.log("🚀 ~ file: index.js:12 ~ Notification ~ dat̥a", data);
  const userToken = useSelector(
    (state) => state.UserAuth.userData.payload.token
  );
  const userType = useSelector(state => state.userType.userType);

  useEffect(() => {
    getNotification();
  }, []);

  const getNotification = () => {
    let axiosConfig = {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };
    getApi(API_All_NOTIFICATION, axiosConfig, sucessFunction, errorFunction);
  };

  const sucessFunction = (res) => {
    setData(res.data.data);
  };

  const errorFunction = (err) => {
    console.log(err);
  };
  const test = () => {
    console.log("this is clicked");

  };




  return (
    <>
      <HeaderMenu />
      <section id="dashboard-cnt-area">
        <div className="container">
          <div className="row dashboard-cnt-row">
            {(userType.payload !== "jobPoster") && (
                <SideMenu />
            )}
            {(userType.payload === "jobPoster") && (
                <div className="col-lg-3 col-sm-4 col-xs-12"></div>
            )}
            {/* <div className="col-lg-9 col-sm-8 col-xs-12 mircroshoft-cnt">
                    <div className="row">
                        <div className="col-lg-12 recent-page-heading mb-4">
                            <a  style={{background:"#d9453d"}}>Recent Notification</a>
                        </div>
                     </div> */}
            <div className="col-lg-9 col-sm-8 col-xs-12 text-center wishlist-table">
              <div className="row my-3">
                <div className="col-lg-12 recent-page-heading-jp mb-4">
                  <a >Recent Notification</a>
                </div>


              </div>
              <div className="row mt-5 pb-5">
                {data?.length > 0 &&
                  data?.map((res, i) => (
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                      <div className="recent-profile" key={res._id}>
                        <div className="recent-profile-img">
                          <img
                            className="img-fluid"
                            src={res?.image !== null ? res.image : DefaultImage}
                          />
                        </div>
                        <div className="recent-profile-text">
                          <h5>{res.title}</h5>
                          <br />
                          <p>{res.description}</p>
                          <br />
                          <p className="text-right">
                            {moment(res.createdAt).format("YYYY/MM/DD")}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Notificationn;
