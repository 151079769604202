import React, {useEffect, useState} from 'react';
import brand from '../../../assets/images/brand-logo.png';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {saveUserInfo, userType} from '../../../redux/actions';
import {fetchToken} from '../../../firebase';
import {API_SEEKER_LOGIN, emailValidation, postApiWithoutHeader,} from '../../../utils';
import { Login as LoginApi } from '../../../api/public/auth';

import {toast} from 'react-toastify';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isRemember, setRemember] = useState(false);
  const [isTokenFound, setTokenFound] = useState(false);
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const isSelectedType = useSelector(state => state.userType.userType);

  useEffect(() => {
    fetchToken(setTokenFound);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const submitBtnAction = e => {
    e.preventDefault();

    if (email.trim() === '') {
      alert('Please Enter Email');
    } else if (!emailValidation(email)) {
      alert('Please Enter Valid Email');
    } else if (password.trim() === '') {
      alert('Please Enter Password');
    } else if (password.length < 5) {
      alert('Password length must be between 6 to 20');
    } else {
      setLoading(true);
      let obj = {
        email: email,
        password: password,
        JobSeeker: 1,
        deviceToken: isTokenFound,
        deviceType: 1,
      };

      // postApiWithoutHeader(API_SEEKER_LOGIN, obj, successCallBack, errorCallBack);

      LoginApi(obj).then((_resp) => {
        const obj = _resp.data
        dispatch(saveUserInfo({ 'payload': obj, 'isRemember': isRemember }));
        setTimeout(() => {
          if(obj['user']['type'] === 'jobPoster'){
            dispatch(userType({ 'payload': 'jobPoster' }));
            navigation('../jobPoster');
          }else if(obj['user']['type'] === 'jobSeeker'){
            dispatch(userType({ 'payload': 'jobSeeker' }));
          }else{
            toast.error('Something went wrong!', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }, 500)
      }).catch((_resp) => {
        try {
          const message = _resp.response.data.message
          toast.info(message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } catch (error) {
          toast.error('Something went wrong!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }).finally(() => {
        setLoading(false);
      })
    }
  };

  return (
    <section id='login-page'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-6 text-center'>
            <div className='brand-logo'>
              <img className='img-fluid' src={brand} style={{ width: '200px' }} alt='brand' />
            </div>
            <form onSubmit={submitBtnAction}>
              <input
                className='form-control login-input'
                type='email'
                name='fname'
                placeholder='E-mail'
                value={email}
                onChange={text => setEmail(text.target.value)}
              />
              <input
                className='form-control login-input'
                type='password'
                name='fname'
                placeholder='Password'
                value={password}
                onChange={text => setPassword(text.target.value)}
                maxLength={20}
              />
              {isLoading ? (
                <button className='loading-btn' type='button' disabled>
                  <span
                    className='spinner-grow spinner-grow-lg mx-2'
                    role='status'
                    aria-hidden='true'
                  />
                  Processing..
                </button>
              ) : (
                <button className='form-btn' type='submit'>
                  Login
                </button>
              )}

              <div className='login-checkbox'>
                <br />
                <label className='steps-checkbox' onChange={() => setRemember(!isRemember)}>
                  Remember me
                  <input type='checkbox' defaultChecked={isRemember} />
                  <span className='checkmark' />
                </label>
                {/* <!--steps-checkbox--> */}
                <br />
                <label className='steps-checkbox' onClick={() => navigation('/sendEmail')}>
                  Forgot password?
                  {/* <input type="checkbox" ckecked="cheked" /> */}
                  {/* <span className="checkmark" /> */}
                </label>
                {/* <!--steps-checkbox--> */}
              </div>
              {/* <!--login-checkbox--> */}
            </form>

            <p className='login-signup-txt' style={{ fontSize: '1rem', marginTop: '-8%' }}>
              Don’t have an account?{' '}
              <strong style={{ textDecoration: 'underline' }} onClick={() => navigation('/app')}>
                Sign up
              </strong>
            </p>
            <p className='login-signup-txt opacity-80' style={{ fontSize: '1rem', marginTop: '1px' }}>
              <strong style={{ textDecoration: 'underline' }} onClick={() => navigation('/sendEmail')}>
                Verify Account
              </strong>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LoginPage;
