import { TYPES } from "../actions";
import { updateObject } from '../../utils'

const initialState = {
  userType:''
};

const getUserType = (state = initialState, { type, payload }) => {

 switch (type) {
    case TYPES.USER_TYPE:
      return{
          ...state,
          userType: payload
      }
    default:
      return { ...state };
  }
};

export { getUserType };
