import React, {useState, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import brand from "../../../assets/images/logo-icon-blk.png";
import {toast} from "react-toastify";
import {useSelector, useDispatch} from "react-redux";
import {
	API_SEEKER_JOB_ROLE_OPTIONS,
	API_SEEKER_JOB_ROLE_TITLE,
	API_SEEKER_ADD_JOB_ROLE,
	API_COMMON_ONBOARDING_STEPS,
	postApi,
	postApiPoster,
} from "../../../utils";
import GoBack from "../../../components/GoBack";
import {saveUserInfo} from "../../../redux/actions";
import axios from "axios";

function JobRole() {
	const {state} = useLocation();
	let companyId = null;
	let jobId = null;
	if (state && state.companyId) {
		companyId = state.companyId;
		jobId = state.jobId;
	}

	const userType = useSelector((state) => state.userType.userType);
	const userToken = useSelector((state) => state.UserAuth.userData.payload.token);
	const userId = useSelector((state) => state.UserAuth.userData.payload.user._id);

	const [jobRoleForTextField, setJobRoleForTextField] = useState("");
	const [jobRoleSelected, setJobRoleSelected] = useState([]);
	const [isFetchedData, setFetchedData] = useState(false);
	const [jobSeekerTitle, setJobSeekerTitle] = useState("");
	const [jobListed, setJobListed] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const [userCreatedRole, setUserCreatedRole] = useState("");

	const navigation = useNavigate();

	const requestDataFromServer = () => {
		setLoading(true);

		const obj = {
			category: "jobrole",
		};
		if (userType.payload === "jobSeeker") {
			postApi(
				API_SEEKER_JOB_ROLE_TITLE,
				obj,
				userToken,
				successFunction,
				errorFunction,
				1
			);
			postApi(
				API_SEEKER_JOB_ROLE_OPTIONS,
				obj,
				userToken,
				successFunctionOptions,
				errorFunctionOptions,
				1
			);
		} else {
			postApiPoster(
				API_SEEKER_JOB_ROLE_TITLE,
				obj,
				userToken,
				successFunction,
				errorFunction
			);
			postApiPoster(
				API_SEEKER_JOB_ROLE_OPTIONS,
				obj,
				userToken,
				successFunctionOptions,
				errorFunctionOptions
			);
		}
	};

	const successFunction = (response) => {
		setFetchedData(true);
		setLoading(false);
		if (response.status === 200) {
			console.log(response.data.result, "tiltterere");
			setJobSeekerTitle(response.data.result);
		}
	};

	const errorFunction = (error) => {
		setLoading(false);
		setFetchedData(true);
		console.log(error, "drerer");
	};

	const successFunctionOptions = (response) => {
		setLoading(false);
		setFetchedData(true);

		if (response.status === 200) {
			var jobLister = [];
			response?.data?.result.map((item) => {
				if (item.addedby === "3") {
					// eslint-disable-next-line no-unused-expressions
					jobLister.push(item);
				}
			});
			setJobListed(jobLister.slice(0, 9));
		}
	};

	const errorFunctionOptions = () => {
		setLoading(false);
		setFetchedData(true);
	};

	useEffect(() => {
		if (!isFetchedData) {
			requestDataFromServer();
		}
	}, [isFetchedData]);

	const updateOnBoardingSteps = (id) => {
		const obj = {
			jobId: jobId,
			companyId: companyId,
			userId: userId,
			roleId: id,
			userType: userType.payload,
			stepNumber: 1,
			// "_id": "642d1ab16fa8534d104c5a20"
		};
		postApi(
			API_COMMON_ONBOARDING_STEPS,
			obj,
			userToken,
			successFunctionOnboardingSteps,
			errorSavingData
		);
	};
	const userAuthInfo = useSelector((state) => state.UserAuth);
	const dispatch = useDispatch();

	const successFunctionOnboardingSteps = (res) => {
		setLoading(false);

		if (res.status === 200) {
			// dispatch the selfAssessmentstep
			var existingUserInfo = userAuthInfo.userData?.payload;
			existingUserInfo.user.selfAssessmentResultSteps = 1;

			dispatch(
				saveUserInfo({
					payload: existingUserInfo,
				})
			);

			let jobId = res?.data?.job?.id;
			navigation("../jobTitle", {state: {jobId: jobId}});
		}
	};

	const savingInfo = async (mode) => {
		setLoading(true);
		let obj;
		if (userType.payload === "jobSeeker") {
			obj = {
				option: userCreatedRole,
				category: "jobrole",
				usertype: 1,
				status: "2",
				userId: userId,
				addedby: "1",
			};
		} else {
			obj = {
				option: userCreatedRole,
				category: "jobrole",
				usertype: 2,
				status: "2",
				userId: userId,
				addedby: "2",
			};
		}

		let axiosConfig = {
			headers: {
				type: "front-end",
				mode: mode == null ? 1 : mode,
				Authorization: "Bearer " + userToken,
			},
		};

		try {
			const data = await axios.post(API_SEEKER_ADD_JOB_ROLE, obj, axiosConfig);
			updateOnBoardingSteps(data?.data?.data?._id);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			toast.error("Something went wrong!", {
				position: "top-right",
				autoClose: 1000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
	};

	const errorSavingData = (err) => {
		toast.error(err.data.message, {
			position: "top-right",
			autoClose: 1000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};

	return (
		<React.Fragment>
			{isLoading && (
				<div className="d-flex justify-content-center align-items-center LoaderCenter">
					{" "}
					<div className="block">
            <span
				className="spinner-grow spinner-grow-lg mx-2"
				role="status"
				aria-hidden="true"
			/>
						<h6 className="LoaderText">Processing...</h6>
					</div>
				</div>
			)}
			{!isLoading && (
				<section id="job-role-steps">
					<div className="discover-personality-top-cnt">
						<div className="container">
							<div className="row">
								<div className="col-lg-12 text-center">
									<img src={brand} alt="brand" style={{width: "80px"}}/>
								</div>
							</div>
						</div>
					</div>
					{/* <!--discover-personality-top-cnt--> */}

					<GoBack/>
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-lg-2"/>
							<div className="col-lg-8">
								<div className="card" style={{borderWidth: 0}}>
									<form id="msform">
										<fieldset>
											<div className="form-card d-flex align-items-center justify-content-center flex-column">
												<div className="col-12 col-lg-8">
													{userType.payload === "jobSeeker"
														? jobSeekerTitle &&
														jobSeekerTitle.map((data) => (
															<h3 key={data._id} className="job-que">
																{data.question}
															</h3>
														))
														: jobSeekerTitle &&
														jobSeekerTitle.map((data) => (
															<h3 key={data._id} className="job-que">
																{data.question}
															</h3>
														))}
												</div>

												<div className="role-wrapper">
													{jobListed &&
														jobListed.map((data, i) => (
															<div key={i + 3} className="role-col">
																<div key={i} className="inputGroup">
																	<input
																		key={i + 1}
																		id={data?._id}
																		name="radio"
																		type="radio"
																		readOnly
																		checked={jobRoleSelected.includes(data._id)}
																	/>
																	<label
																		key={i + 2}
																		htmlFor={data?._id}
																		className="text-capitalize"
																		onClick={() => {
																			setJobRoleForTextField("");
																			setJobRoleSelected([data._id]);
																			setUserCreatedRole(data.option);
																		}}
																	>
																		{data.option}
																	</label>
																</div>
															</div>
														))}
												</div>

												<div className="enter-job-role job-role-desc position-relative">
													{userType.payload === "jobSeeker" ? (
														<h5>Don’t see the Culture you looking for?</h5>
													) : (
														<h5>Don’t see the Culture you hiring for?</h5>
													)}
													<input
														type="text"
														id="name"
														name="name"
														className="job-input"
														placeholder="Type your Culture here..."
														value={jobRoleForTextField}
														onFocus={(event) => {
															event.target.setAttribute("autocomplete", "off");
														}}
														onChange={(e) => {
															const {value} = e.target;
															const regex = /^[a-zA-Z\s]*$/; // Regex pattern that matches only alphabets and spaces

															if (regex.test(value)) {
																setJobRoleForTextField(e.target.value);
																setUserCreatedRole(e.target.value);
																setJobRoleSelected([]);
															}
														}}
													/>
												</div>
											</div>

											<input
												type="button"
												name="next"
												className="next action-button"
												value=""
												onClick={savingInfo}
											/>
											<div className="row">
												<div className="col-12">
													<h2
														className="steps"
														style={{
															fontSize: "1.2rem",
															margin: "0rem 0rem 1rem",
														}}
													>
														Step 1/8
													</h2>
												</div>
											</div>
										</fieldset>
									</form>

									<div className="progress">
										<div
											className="progress-bar"
											role="progressbar"
											aria-valuemin="0"
											aria-valuemax="100"
										/>
									</div>
								</div>
							</div>
							<div className="col-lg-2"/>
						</div>
					</div>
				</section>
			)}
		</React.Fragment>
	);
}

export default JobRole;
