import React, { useState } from 'react';

import { Modal } from 'react-bootstrap';
import LoginPopUpImage from '../../../assets/images/login-popup-img-2.png';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
export default function ModalStepTwo(props) {
  const navigate = useNavigate();
  const choosenOption = useSelector(state => state.optionSelected.optionSelected);

  const skipAndNavigate = () => {
    props.hidetwo();
    if (choosenOption.payload === 'Sign Up') {
      navigate('../signup');
    } else {
      navigate('../login');
    }
  };
  return (
    <Modal
      style={{ paddingBottom: '20px' }}
      show={props.showtwo}
      onHide={props.hidetwo}
      size='lg'
      backdrop='static'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton className='modal-close modal-two-image-hand ' />
      <Modal.Body style={{ marginTop: '-20px', padding: '0px' }}>
        <div>
          <form id='msform'>
            <fieldset className='login-pop-step-2' style={{ display: 'block', opacity: '1' }}>
              <div className='form-card'>
                <div className='login-pop-cnt'>
                  <img className='img-fluid image-margin-top' src={LoginPopUpImage} />
                  <div className='modal-content-wrap-two'>
                    <h3>Get direct access for faster results</h3>
                    <p className='modal-text'>
                      When Perspectv identifies strong matches, it’ll connect &amp; introduce you
                      directly to those Job Posters.
                    </p>
                  </div>
                </div>
              </div>

              <div className='py-3 color-match'>
                <input
                  type='button'
                  name='next'
                  className='next action-button'
                  onClick={props.stepthree}
                />
                <br />
                <label
                  type='button'
                  style={{ fontSize: '0.8rem' }}
                  className='modal-btn'
                  onClick={skipAndNavigate}
                >
                  Skip
                </label>
              </div>
            </fieldset>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
