import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import HeaderMenu from "../../../components/HeaderMenu/headerMenu";
import SideMenu from "../../../components/SideMenu/sideMenu";
import {
  API_JOPOSTER_CHANGE_PASSWORD,
  API_SEEKER_CHANGE_PASSWORD,
  postApiPosterWithoutHeader,
  postApiWithoutHeader,
} from "../../../utils";

function ConfirmPassword() {
  const navigation = useNavigate();
  const [password, setPassword] = useState("");
  // const state = useLocation();
  // const [ userInfo ] = useState(state);
  const userId = useSelector(
    (state) => state.UserAuth.userData.payload.user._id
  );
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const userType = useSelector((state) => state.userType.userType);

  const changePaswword = (e) => {
    e.preventDefault();
    if (!oldPassword)
      return toast.error("Please enter old password!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    if (password !== "" && confirmPassword !== "") {
      if (password === confirmPassword) {
        let obj = {
          _id: userId,
          oldPassword: oldPassword,
          password: password,
        };
        if (userType.payload === "jobSeeker") {
          postApiWithoutHeader(
            API_SEEKER_CHANGE_PASSWORD,
            obj,
            successCallBack,
            errorCallBack
          );
        } else {
          postApiPosterWithoutHeader(
            API_JOPOSTER_CHANGE_PASSWORD,
            obj,
            successCallBack,
            errorCallBack
          );
        }
      } else {
        toast.error("Password should be same!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.error("Please enter password and confirm password!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const successCallBack = (response) => {
    console.log(response.data);
    toast.success(response.data.message, {
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    navigation("../../homepage");
  };

  const errorCallBack = (error) => {
    setLoading(false);
    toast.error(error.response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  return (
    <>
      <HeaderMenu />
      <section id="dashboard-cnt-area">
        <div className="container">
          <div className="row dashboard-cnt-row">
            {(userType.payload !== "jobPoster") && (
                <SideMenu />
            )}
            {(userType.payload === "jobPoster") && (
                <div className="col-lg-3 col-sm-4 col-xs-12"></div>
            )}
            <div className="col-lg-9 col-sm-8 col-xs-12 mircroshoft-cnt mb-3">

              <div className="row" style={{
                paddingRight: 300,

              }}>
                <div className="col-lg-9 col-sm-8 col-xs-12 recent-page-heading">

                  <a  style={{ background: "#d9453d" }}>
                    Change Password
                  </a>
                </div>
              </div>

              <div className="row mt-5 pb-5">
                <div className="col-lg-6 col-md-6 col-xs-6 col-12">
                  <form onSubmit={changePaswword}>
                    <div className="signup-input-bx">
                      <label>Old Password</label>
                      <input
                        className="form-control signup-input-bx"
                        type="password"
                        onChange={(e) => setOldPassword(e.target.value)}
                      />
                    </div>
                    <div className="signup-input-bx">
                      <label>New Password</label>
                      <input
                        className="form-control signup-input-bx"
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>

                    <div className="signup-input-bx">
                      <label>Confirm Password</label>
                      <input
                        className="form-control signup-input-bx"
                        type="password"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-12 pb-5">
                      <button className="form-btn" type="submit">
                        Update
                      </button>
                      &gt;
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ConfirmPassword;
