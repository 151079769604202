import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./style/custom_style.css";
import "./style/responsive.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import SwDev from "./swDev";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { BrowserRouter } from "react-router-dom";
import TagManager from 'react-gtm-module';

const stripePromise = loadStripe(
  "pk_live_51L3HfIC1k0mqEMMzQG1o0SpxkNYIXAzkB5ShXmyTEWI7nK56tQmeXuJSTYUyRRiTANW2kiExU6akB5AGAid2qJFV00J9XIajJZ"
  //"pk_test_51LivKkSHnVwgWeNxQhiInaeeMjMhXiq2a3nYIIicpR9a3rulip5xf6rh3nPYHsBjFF559PY9uBgQQSNJAxRHOkZV00DVmz6cZB"
);

const tagManagerArgs = {
    gtmId: 'GTM-P66QRKCN'
}

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Elements stripe={stripePromise}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Elements>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
SwDev();
