import React, {useEffect, useState} from "react";
import {useNavigate, useLocation} from "react-router-dom";

import {toast} from "react-toastify";
import {useSelector, useDispatch} from "react-redux";

import Toogle from "../../../../assets/images/toggle-icon.png";
import EagleMan from "../../../../assets/images/eagle-man-img.png";
import ArrowIcon from "../../../../assets/images/right-arrow.png";
import moment from "moment";
import ProfileIcon from "../../../../assets/images/profile-img-standard.png";
import UploadIcon from "../../../../assets/images/profile-img-upload-icon.png";
import Landscape from "../../../../assets/images/barnd-landscape-logo.png";
import MicrosoftLogo from "../../../../assets/images/microshof-logo.png";
import HeaderMenu from "../../../../components/HeaderMenu/headerMenu";
import {saveUserInfo} from "../../../../redux/actions";

import {
	API_GET_POSTER_DETAIL,
	API_PERSONAL_INFO,
	postApiPoster,
} from "../../../../utils";

export default function JobDetailsPage() {
	const [isLoading, setLoading] = useState(false);
	const {state} = useLocation();
	const jobId = state.jobId;
	//console.log(state, 'information------------------------------');

	console.log(jobId, "jobId");
	const navigation = useNavigate();
	const userToken = useSelector(
		(state) => state.UserAuth.userData.payload.token
	);
	// console.log(userToken,"TOKEN");
	const userType = useSelector((state) => state.userType.userType);
	const userId = useSelector(
		(state) => state.UserAuth.userData.payload.user._id
	);
	const [jobData, setjobData] = useState([]);
	const [personInfo, setpersonInfo] = useState([]);
	//******Watch the current Index changes
	useEffect(() => {
		if (userType.payload === "jobSeeker") {
			requestDataFromServer1();
		} else {
			requestDataFromServer();
		}
	}, []);
	const requestDataFromServer = () => {
		setLoading(true);
		const obj = {
			usertype: 2,
			userId: userId,
			jobId: jobId,
		};
		postApiPoster(
			API_GET_POSTER_DETAIL,
			obj,
			userToken,
			successFunction,
			errorFunction
		);
	};

	// const userAuthInfo = useSelector((state) => state.UserAuth);

	const successFunction = (res) => {
		console.log(res, "response____");
		if (res.status === 200) {
			setLoading(false);
			let data = res.data.result;

			data?.map((item) => {
				if (item._id === jobId) {
					console.log(item, "item_____");
					if (item?.poster[0].planCredit > 0) {
						setNext("../jobPoster/company/" + item.company[0]._id);
					}
					setjobData([item]);
				}
			});
		} else {
			setLoading(false);
			toast.error("Oops something went wrong!");
		}
	};
	console.log(jobData, "jobdayta");
	const errorFunction = (errr) => {
		setLoading(false);
		toast.error(errr.message);
	};

	const [next, setNext] = useState("../paymentOption/new");
	console.log("Next Page", next);

	const nextPage = () => {
		navigation(next);
	};

	const requestDataFromServer1 = () => {
		setLoading(true);
		const obj = {
			usertype: 1,
			userId: userId,
		};
		postApiPoster(
			API_PERSONAL_INFO,
			obj,
			userToken,
			successFunction1,
			errorFunction1
		);
	};
	const successFunction1 = (res) => {
		if (res.status === 200) {
			setLoading(false);
			let data = res.data.result;

			data?.map((item) => {
				if (item._id === jobId) {
					setjobData([item]);
				}
			});
		} else {
			setLoading(false);
			toast.error("Oops something went wrong!");
		}
	};
	const errorFunction1 = (errr) => {
		setLoading(false);
		toast.error(errr?.message);
	};

	const renderItem = (item) => {
		console.log(item)
		//console.log('get item value', item._id);
		if (item._id === jobId) {
			const soft_skills = item.softSkill.map((data) => {
				return (
					<li
						style={{
							textAlign: "left",
							listStyle: "none",
						}}
					>
						{data.skill.option}
					</li>
				);
			});

			const hard_skills = item.hardSkill.map((data) => {
				return (
					<li style={{textAlign: "left", listStyle: "none"}}>
						{data.skill.option}
					</li>
				);
			});

			return (
				<div className="col-lg-12 col-sm-12 col-xs-12 mircroshoft-cnt" style={{paddingTop: '0'}}>
					<div className="row">
						<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-none d-sm-block">
							<div className="mircroshoft-heading">
								<h4>
									{item.company[0]?.name}
									<i className="fa fa-heart" aria-hidden="true"/>
								</h4>
								<h6 className={'capitalize'}>{item.jobtitle[0]?.option} </h6> {/* Fraud Agent */}
								<p>
									{item.locationName ?? ""}
								</p>
							</div>
						</div>

						<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-none d-sm-block">
							<div className="mircroshoft-logo text-right">
								<img
									className="jobdetail_logo"
									src={item?.poster[0]?.destination}
								/>
							</div>
						</div>

						<div className={"d-block d-sm-none col-12 mt-4"}>
							<h5>Company Name</h5>
							<p>{item.company[0].name}</p>
						</div>

						<div className={"d-block d-sm-none col-xs-12 mt-4"}>
							<h5>Job Title</h5>
							<p>{item.jobtitle[0]?.option}</p>
						</div>

						<div className={"d-block d-sm-none col-xs-12 mt-4"}>
							<h5>Job Location</h5>
							<p>{item.locationName ?? ""}</p>
						</div>

						<div className="col-lg-12 mt-4">
							<h5>Company Pitch</h5>
							<p>{item.company[0].description ?? " - "}</p>
						</div>

						<div className="col-lg-12 mt-4">
							<h5>Job Description</h5>
							<p>{item.jobDesc ?? " - "}</p>
						</div>

						<div className="col-lg-3 col-md-4 col-xs-6 mt-4">
							<h5>Company size</h5>
							<p>{item.company[0].size}</p>
						</div>

						<div className="col-lg-3 col-md-4 col-xs-6 mt-4">
							<h5>Employment Type</h5>
							<p>{item.employmentMode}</p>
						</div>

						<div className="col-lg-3 col-md-4 col-xs-6 mt-4">
							<h5>Maximum Salary</h5>
							<p>{item.employmentType}</p>
						</div>

						<div className="col-lg-3 col-md-4 col-xs-6 mt-4">
							<h5>Start Date</h5>
							<p>
								{moment(item.startDate).format("YYYY/MM/DD")} {/*dd/mm/yy/*/}
							</p>
						</div>

						<div className="col-lg-12 mt-4">
							<h5>Soft Skills</h5>
							<ul style={{paddingLeft: "0px"}}>{soft_skills}</ul>
						</div>

						<div className="col-lg-12 mt-4">
							<h5>Hard Skills</h5>
							<ul style={{paddingLeft: "0px"}}>{hard_skills}</ul>
						</div>
					</div>
				</div>
			);
		}
	};

	return (
		<React.Fragment>
			{isLoading && (
				<div className="d-flex justify-content-center align-items-center LoaderCenter">
					{" "}
					<div className="block">
            <span
				className="spinner-grow spinner-grow-lg mx-2"
				role="status"
				aria-hidden="true"
			/>
						<h6 className="LoaderText">Processing...</h6>
					</div>
				</div>
			)}
			{!isLoading && (
				<>
					<HeaderMenu/>

					<section id="dashboard-cnt-area">
						<div className="container">
							<div className="row dashboard-cnt-row">
								{/* <img className="img-fluid eagle-man" src={EagleMan} /> */}
								{jobData && jobData?.map((item) => renderItem(item))}
							</div>
							<div className="row">
								<div className="col-lg-12 text-center">
									<a  onClick={() => nextPage()}>
										{/* onClick={nextPage} */}{" "}
										<img src={ArrowIcon} alt="right"/>
									</a>
								</div>
							</div>
						</div>
					</section>
				</>
			)}
		</React.Fragment>
	);
}
