import { postData, downloadFile } from "./requests";

export const GetProfileData = (userId, userType) => {
    const url = 'api/jobposter/getprofile';
    const requestData = {
        usertype: userType,
        _id: userId
    };
    return postData(url, requestData)
}

export const deleteSeekerProfile = (_seeker_id) => {
    const url = 'api/jobseeker/delete';
    const requestData = {
        _id: _seeker_id
    };
    return postData(url, requestData)
}

export const deletePosterProfile = (_poster_id) => {
    const url = 'api/jobposter/delete';
    const requestData = {
        _id: _poster_id
    };
    return postData(url, requestData)
}

export const downloadUserCsv = (_link) => {
    const path = 'api/jobseeker/download/user-csv'
    const payload = {
        'link': _link
    }
    return downloadFile(path, payload)
}