import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";


const firebaseConfig = {
  apiKey: "AIzaSyCK0s3XIkRDp2PVM_qSJtwol-azyruokok",
  authDomain: "perspectv-295ef.firebaseapp.com",
  projectId: "perspectv-295ef",
  storageBucket: "perspectv-295ef.appspot.com",
  messagingSenderId: "994232939716",
  appId: "1:994232939716:web:3d772a0ea4dffceed44591",
  //measurementId: "G-GMWDCYBBSD",
};

var vapidKeyy = 'BNUdnW5qia87gRd1r9jwLkqEEK5y4yNXZ1ya6vtcIdJzPbSwLQpIpl_huE3utGAoer_lpOr-E8bE18c9QRGIn54'

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = async (setTokenFound) => {
  return getToken(messaging, { vapidKey: vapidKeyy }).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      setTokenFound(currentToken);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
    setTokenFound(false);
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

