import React, { useState, useEffect } from "react";
import Blk from "../../../../assets/images/logo-icon-blk.png";
import ArrowIcon from "../../../../assets/images/right-arrow.png";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  API_ADD_IMAGE_DOCUMNET_JOBSEEKER,
  API_GET_SEEKER_DETAIL,
  postApi,
} from "../../../../utils";
import { saveUserInfo } from "../../../../redux/actions";

export default function CompanyInfo() {
  const navigation = useNavigate();
  const { state } = useLocation();
  const userToken = useSelector((state) => state.UserAuth.userData.payload.token);
  const userId = useSelector((state) => state.UserAuth.userData.payload.user._id);
  const userAuth = useSelector((state) => state.UserAuth);

  const isLogo = userAuth?.userData?.payload?.user?.selfAssessmentResult
    ? userAuth?.userData?.payload?.user?.selfAssessmentResult
    : false;
  const [routerData] = useState(state);
  const [comapnyDescription, setComapnyDescription] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [isLoading, setLoading] = useState(false);
  const userType = useSelector((state) => state.userType.userType);
  const dispatch = useDispatch();

  useEffect(() => {
    requestDataFromServer();
  }, []);

  const requestDataFromServer = () => {
    setLoading(true);
    var obj = {};
    if (userType.payload === "jobSeeker") {
      obj = {
        usertype: 1,
        userId: userId,
      };
    } else {
      obj = {
        usertype: 2,
        userId: userId,
      };
    }

    postApi(
      API_GET_SEEKER_DETAIL,
      obj,
      userToken,
      successFunction,
      errorFunction
    );
  };

  const userAuthInfo = useSelector((state) => state.UserAuth);

  const successFunction = (res) => {
    setLoading(false);
    if (res.status === 200) {
      let data = res.data.result;

      data?.map((item) => {
        console.log("------", item);
        console.log("this is item company desc", item.companyDesc);
        setComapnyDescription(item.companyDesc ? item.companyDesc : "");
      });

    }
  };

  const errorFunction = (res) => {
    setLoading(false);
  };

  const errorCallBack = () => { };

  //Const save posterInfo
  const savePosterInfo = () => {
    // navigation("../profileCompleteCongrats");
    if (comapnyDescription === "" || comapnyDescription == null) {
      toast.error("Please provide company  description!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (jobDescription === "" || jobDescription == null) {
      toast.error("Please provide job description!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      //Hit the api
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("type", "front-end");
      myHeaders.append("mode", "2");
      myHeaders.append("Authorization", `Bearer ${userToken}`);

      var formdata = new FormData();
      formdata.append("upload", routerData.upload ? routerData.upload : "");
      formdata.append(
        "companyDesc",
        comapnyDescription ? comapnyDescription : ""
      );
      formdata.append("jobDesc", jobDescription);
      formdata.append("startDate", routerData.startDate);
      formdata.append("userId", userId);
      console.log("this si the start data", routerData.startDate);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(`${API_ADD_IMAGE_DOCUMNET_JOBSEEKER}`, requestOptions)
        .then((response) => {
          navigation("../profileCompleteCongrats");
        })
        .then((result) => {
          setLoading(false);
          console.log("get upload response-------", result);
		  setLoading(false);

		  toast.success("Record updated successfully", {
			  position: "top-right",
			  autoClose: 1000,
			  hideProgressBar: false,
			  closeOnClick: true,
			  pauseOnHover: true,
			  draggable: true,
			  progress: undefined,
		  });

		  navigation("../profileCompleteCongrats");
        })
      // .catch((error) => {
      //   setLoading(false);

      //   toast.error("Oops something went wrong!", {
      //     position: "top-right",
      //     autoClose: 1000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //   });
      // });
    }
  };
  return (
    <React.Fragment>
      {isLoading && (
        <div className="d-flex justify-content-center align-items-center LoaderCenter">
          {" "}
          <div className="block">
            <span
              className="spinner-grow spinner-grow-lg mx-2"
              role="status"
              aria-hidden="true"
            />
            <h6 className="LoaderText">Processing...</h6>
          </div>
        </div>
      )}
      {!isLoading && (
        <section id="feel-suite-best">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center mb-5">
                <img src={Blk} alt="blk" />
                <h3>Tell us more about company and the job</h3>
              </div>
            </div>

            <div className="row company-info-container">
              {
                <div className="row">
                  <div className="col-lg-2" />
                  <div className="col-lg-8 upload-your-profile text-center company-job">
                    <p>
                      Try not to overthink just be yourself and tell us a bit
                      about the company and why this is a great opportunity for
                      potential candidates.
                    </p>
                    <div className="text-area">
                      <textarea
                        placeholder="Tell us more about your company..."
                        value={comapnyDescription}
                        onChange={(e) => setComapnyDescription(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              }
              <div className="row">
                <div className="col-lg-2" />
                <div className="col-lg-8 upload-your-profile text-center">
                  <p>Add a short, engaging overview of the job</p>

                  <div className="text-area">
                    <textarea
                      placeholder="Include a description of the job major funtion,how it contributes to the company's objectives and why it's important..."
                      onChange={(e) => setJobDescription(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-lg-2" />
                <p>
                  {" "}
                  <a  onClick={savePosterInfo}>
                    {" "}
                    <img src={ArrowIcon} />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
}
