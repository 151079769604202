import React, { useState, useEffect } from 'react';
import brand from '../../../assets/images/logo-icon-blk.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  API_SEEKER_JOB_ROLE_OPTIONS,
  API_SEEKER_JOB_ROLE_TITLE,
  API_SEEKER_ADD_JOB_ROLE,
  API_COMMON_ONBOARDING_STEPS,
  postApi,
  postApiPoster,
  API_ADD_LOCATION,
} from '../../../utils';

import { toast } from 'react-toastify';
import Autosuggestion from '../locationAutoSuggestion/autosugestion';
import LocationSearch from '../locationAutoSuggestion/autosugestion';
import GoBack from '../../../components/GoBack';
import { saveUserInfo } from '../../../redux/actions';


// import Autosuggest from 'react-autosuggest';
function RoleStepSix() {
  //*******Getting router passed data***************************/
  const { state } = useLocation();
  const jobId = state.jobId;
  const navigation = useNavigate();
  const [locationSelected, setLocationSelected] = useState('');
  const [isRemoteWorker, setRemoteWorker] = useState(false);
  const [locationOption, setLocationOption] = useState([]);
  const [isFetchedData, setFetchedData] = useState(false);
  const [jobTitle, setJobTitle] = useState('');

  const [isLoading, setLoading] = useState(false);
  const userType = useSelector(state => state.userType.userType);
  const userToken = useSelector(state => state.UserAuth.userData.payload.token);
  const userId = useSelector(state => state.UserAuth.userData.payload.user._id);

  //call api
  const requestDataFromServer = () => {
    setLoading(true);

    const obj = {
      category: 'location',
    };
    if (userType.payload === 'jobSeeker') {
      postApi(API_SEEKER_JOB_ROLE_TITLE, obj, userToken, successFunction, errorFunction);
      postApi(
        API_SEEKER_JOB_ROLE_OPTIONS,
        obj,
        userToken,
        successFunctionOptions,
        errorFunctionOptions
      );
    } else {
      postApiPoster(API_SEEKER_JOB_ROLE_TITLE, obj, userToken, successFunction, errorFunction);
      postApiPoster(
        API_SEEKER_JOB_ROLE_OPTIONS,
        obj,
        userToken,
        successFunctionOptions,
        errorFunctionOptions
      );
    }
  };
  const successFunction = response => {
    setFetchedData(true);
    setLoading(false);
    if (response.status === 200) {
      setJobTitle(response.data.result);
    }
  };
  const errorFunction = error => {
    setLoading(false);
    setFetchedData(true);
  };
  const successFunctionOptions = response => {
    console.log(response, 'location');
    setLoading(false);
    setFetchedData(true);

    if (response.status === 200) {
      setLocationOption(response.data.result);
    }

    // console.log(response);
  };
  const errorFunctionOptions = error => {
    setLoading(false);
    setFetchedData(true);
    console.log(error);
  };
  useEffect(() => {
    if (!isFetchedData) {
      requestDataFromServer();
    }
  }, [isFetchedData]);
  //*******Save user data in server and naviagte to another page***********************************//
  const savingInfo = () => {
    if (locationSelected.length > 0) {
      if (locationSelected.split(',').length > 1) {
        updateOnBoardingSteps(locationSelected);
      } else {
        toast.error('Oops! you forgot to select city!', {
          position: 'top-right',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      if (isRemoteWorker) {
        setLoading(true);
        updateOnBoardingSteps(null);
      } else {
        toast.error('Oops! you forgot to select location!', {
          position: 'top-right',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  //Auto suggestion value
  const getLocationSelected = location => {
    console.log("-----------location selected-----", location);
    setLocationSelected(location);
  };

  const updateOnBoardingSteps = (location) => {
    const obj = {
      jobId: jobId,
      "userId": userId,
      "location": location,
      "remotely": isRemoteWorker,
      "userType": userType.payload,
      "stepNumber": 6
    };
    postApi(API_COMMON_ONBOARDING_STEPS, obj, userToken, successFunctionOnboardingSteps, errorSavingData);
  }

  const userAuthInfo = useSelector(state => state.UserAuth);
  const dispatch = useDispatch();

  const successFunctionOnboardingSteps = res => {

    setLoading(false);

    if (res.status === 200) {

      // dispatch the selfAssessmentstep
      var existingUserInfo = userAuthInfo.userData?.payload;
      existingUserInfo.user.selfAssessmentResultSteps = 6;

      dispatch(
        saveUserInfo({
          payload: existingUserInfo,
        })
      );

      navigation(userType.payload === 'jobSeeker' ? '../companySize' : '../salary', {state: {jobId: jobId}});
    }

  }
  const errorSavingData = err => {
    toast.error(err.data.message, {
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };


  return (
    <React.Fragment>
      {isLoading && (
        <div className='d-flex justify-content-center align-items-center LoaderCenter'>
          {' '}
          <div className='block'>
            <span className='spinner-grow spinner-grow-lg mx-2' role='status' aria-hidden='true' />
            <h6 className='LoaderText'>Processing...</h6>
          </div>
        </div>
      )}
      {!isLoading && (
        <section id='job-role-steps' className='bg-position-1'>
          <div className='discover-personality-top-cnt'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12 text-center'>
                  <img src={brand} alt='brand' className='w-80px' />
                </div>
              </div>
            </div>
          </div>
          {/* <!--discover-personality-top-cnt--> */}

          <div className='container'>
            <GoBack />
            <div className='row justify-content-center'>
              <div className='col-lg-6'>
                <div className='card' style={{ borderWidth: 0 }}>
                  <form id='msform'>
                    {/* <!-- progressbar --> */}

                    <fieldset>
                      <div className='form-card'>
                        {userType.payload === 'jobSeeker' ? (
                          <h3 className='job-que'>Where would you like the job to be located?</h3>
                        ) : (
                          <h3 className='job-que'>Where would you like the job to be located?</h3>
                        )}
                        {userType.payload === 'jobSeeker'
                          ? jobTitle &&
                          jobTitle.map(data => (
                            <h3 key={data._id} className='job-que'>
                              {data.question}
                            </h3>
                          ))
                          : jobTitle &&
                          jobTitle.map(data => (
                            <h3 key={data._id} className='job-que'>
                              {data.question}
                            </h3>
                          ))}
                        <div className='enter-job-role' style={{ transform: 'translateY(15%)' }}>
                          <LocationSearch onLocationSelected={getLocationSelected} />
                          <label className='steps-checkbox'>
                            <input
                              type='checkbox'
                              checked={isRemoteWorker}
                              onChange={e => setRemoteWorker(!isRemoteWorker)}
                            />

                            <span className='checkmark' />
                            {userType.payload === 'jobSeeker' ?
                              " I'm happy to work remotely. " : " Show seekers who works remotely."
                            }

                          </label>
                          {/* <!--steps-checkbox--> */}
                        </div>
                      </div>
                      {/* <!--form-card--> */}

                      <input
                        type='button'
                        name='next'
                        className='next action-button'
                        value=''
                        onClick={savingInfo}
                      />
                      <div className='row'>
                        <h2
                          className='steps'
                          style={{ fontSize: '1.2rem', margin: '0rem 0rem 1rem' }}
                        >
                          Step 6/8
                        </h2>
                      </div>
                    </fieldset>
                  </form>

                  <div className='progress'>
                    <div
                      className='progress-bar'
                      role='progressbar'
                      style={{ width: '75%' }}
                      aria-valuenow='25'
                      aria-valuemin='0'
                      aria-valuemax='100'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
}

export default RoleStepSix;
