import { TYPES } from "../actions";


const initialState = {
  optionSelected:''
};

const choosenOption= (state = initialState, { type, payload }) => {

 switch (type) {
    case TYPES.CHOOSEN_OPTION:
      return{
          ...state,
          optionSelected: payload
      }
    default:
      return { ...state };
  }
};

export { choosenOption };
