import React, { useState, useEffect } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isPossiblePhoneNumber } from "react-phone-number-input";

export default function PhoneNumberInput({
  onTouch,
  initialValue,
  country,
  rest,
}) {
  // console.log('get value ---------', initialValue);
  const [value, setValue] = useState(initialValue);
  // console.log('get valuexxx ---------', value);

  // useEffect(()=> {
  // 	setValue(...initialValue)
  // },[])
  // console.log('get value ---------', value);
  const getValue = (number) => {
    // console.log('get value --------->>>>>>', number.length );
    // if(number.length < 13){
    //   onTouch(number);
    //   setValue(number);
    // }
    onTouch(number);
    setValue(number);
  };
  return (
    <PhoneInput
      international
      defaultCountry={"US"}
      placeholder="Enter mobile number"
      value={initialValue ? initialValue : value}
      onChange={(e) => getValue(e)}
      limitMaxLength={10}
      disabled={rest ? true : false}
    />
  );
}
