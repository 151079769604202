import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import rightArrowImg from '../../../assets/images/right-arrow.png';
import SideMenu from '../../../components/SideMenu/sideMenu';
import HeaderMenu from '../../../components/HeaderMenu/headerMenu';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { API_GET_POSTER_DETAIL, postApiPoster } from '../../../utils';
import { toast } from 'react-toastify';
import FooterSection from '../../../components/Footer/footerSection';

export default function MatchesFoundJobPoster() {
  const { state } = useLocation();

  const [feedbackPoints, setfeedbackPoints] = useState([
    { id: '1', title: 'Wrong role', count: 0 },
    { id: '2', title: 'Wrong job title', count: 0 },
    { id: '3', title: 'Wrong industry', count: 0 },
    { id: '4', title: 'Wrong skill level', count: 0 },
    { id: '5', title: 'Wrong location', count: 0 },
    { id: '6', title: 'Wrong company size', count: 0 },
    { id: '7', title: 'Wrong salary amount', count: 0 },
    { id: '8', title: 'Other', count: 0 },
  ]);

  const arrangeCount = () => {
    for (var i = 0; i < feedbackPoints.length; i++) {
      state?.feedback?.map(feed => {
        // console.log("feed", feed?.seekerFeedBack, "==", feedbackPoints[i]?.title)
        if (feed?.seekerFeedBack?.toLowerCase() === feedbackPoints[i]?.title?.toLowerCase()) {
          feedbackPoints[i].count++;
          setfeedbackPoints([...feedbackPoints]);
        }
      });
    }
    // console.log("data", feedbackPoints)
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    arrangeCount();
  }, []);

  return (
    <div>
      <HeaderMenu />
      <section id='dashboard-cnt-area' className='dashboard-bg'>
        <div className='container'>
          <div className='row dashboard-cnt-row'>
            {/* <img className="img-fluid eagle-man" src={Landscape} /> */}
            <SideMenu />

            <div className='col-lg-9 col-sm-8 col-xs-12 mircroshoft-cnt'>
              <div className='row'>
                <div className='col-lg-12 recent-page-heading-jp mb-4'>
                  <h5>Feedback</h5>
                  <br />
                  <a href='javascript:void(0)'>Feedback</a>
                </div>
              </div>

              <div className='mt-4 col-12 text-start'>
                <b className='feedback-heading'>Reason for rejection</b>

                <div className='col-12 col-lg-6 mt-3'>
                  {feedbackPoints &&
                    feedbackPoints.map((data, i) => (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls='panel1bh-content'
                          id='panel1bh-header'
                        >
                          <Typography sx={{ width: '33%', flexShrink: 0 }}>{data.title}</Typography>
                          <Typography>
                            <span className='badge bg-light-green rounded-circle'>{data.count}</span>
                          </Typography>
                        </AccordionSummary>
                      </Accordion>
                    ))}
                </div>
              </div>

              <div className='row'>
                <div className='col-lg-12 mt-5 text-start mb-5'>
                  <a href='/'>
                    <img src={rightArrowImg} />{' '}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterSection />
    </div>
  );
}
