import React, { useState } from 'react';

import { Modal } from 'react-bootstrap';
import LogoRight from '../../../assets/images/right-arrow.png';
import CostIcon from '../../../assets/images/design-cost-icon.png';
import IconBlack from '../../../assets/images/logo-icon-blk.png';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function JobPosterThirdModal(props) {
  const navigate = useNavigate();
  const choosenOption = useSelector(state => state.optionSelected.optionSelected);

  const nextStep = () => {
    props.stepfour();
  };
  const skipAndNavigate = () => {
    props.hidethree();
    if (choosenOption.payload === 'Sign Up') {
      navigate('../signup');
    } else {
      navigate('../login');
    }
  };
  return (
    <Modal
      style={{ paddingBottom: '20px' }}
      show={props.showthree}
      onHide={props.hidethree}
      size='lg'
      backdrop='static'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className='four-three-modal'
    >

      <Modal.Header closeButton className='modal-close-stepfour' />
      <Modal.Body style={{ marginTop: '-20px', padding: '0px' }}>
        <form id='msform'>
          <fieldset className='login-pop-step-1-jp-coloured'>
            <div className='discover-main-screen-cnt-color-bg'>
              <img src={IconBlack} />
              <div className='modal-content-wrapper pt-5 pb-5 '>
                <h3 className='modal-header-text-white'>Designed for cost</h3>
                <p className='modal-text-white'>
                  Perspectv is far more efficient and affordable thanmost job boards and recruitment
                  agencies. Our transparent pay as you go structure allows you to only pay for the
                  candidates you really want to meet.
                </p>
              </div>
              <div className='discover-hand-icon'>
                <img src={CostIcon} />
              </div>
              <a className='pb-5' href='javascript:void(0)' onClick={nextStep}>
                <img className='pb-4' src={LogoRight} />{' '}
              </a>
              <br />
              <p>
                {' '}
                <a className='skip-text-white pt-5' href='javascript:void(0)' onClick={skipAndNavigate}>
                  Skip
                </a>
              </p>
            </div>
          </fieldset>
        </form>
      </Modal.Body>
    </Modal>
  );
}
