import { combineReducers } from 'redux';
import { UserAuth } from './authSeekerReducers';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import { getUserType } from './userTypeReducer';
import { choosenOption } from './choosenOptionReducer';
const persistConfig = {
	key: 'root',
	storage: storage,
	whitelist: [ 'userType ,optionSelected' ]
};

const authPersistConfig = {
	key: 'userType',
	storage: storage
};
const choosenOptionPersistConfig = {
	key: 'optionSelected',
	storage: storage
};

const rootReducers = combineReducers({
	UserAuth,
	userType: persistReducer(authPersistConfig, getUserType),
	optionSelected: persistReducer(choosenOptionPersistConfig, choosenOption)
});

export default persistReducer(persistConfig, rootReducers);
