import React, { useState } from "react";

import MobileImage from "../../../assets/images/login-popup-img-1.png";
import ArrowIcon from "../../../assets/images/right-arrow.png";
import Blk from "../../../assets/images/logo-icon-blk.png";
import Eye from "../../../assets/images/speed-eye-img.png";
import Hand from "../../../assets/images/discover-hand.png";

import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import BackgroundImage from "../../../assets/images/login-pop-step-2-bg.png";
import GoBack from "../../../components/GoBack";

function RoleStepCompletePoster() {
  const { state } = useLocation();
  const jobId = state.jobId;
  const [routerData] = useState(state);
  const userType = useSelector((state) => state.userType.userType);

  const navigation = useNavigate();
  console.log("get user data till now role", routerData);
  ///here we will store all the info to server
  const getUserType = () => {
    if (userType.payload === "jobSeeker") {
      navigation("../selfAssest", { state: { ...routerData, jobId: jobId } });
    } else {
      navigation("../selfAssest", { state: { ...routerData, jobId: jobId } });
    }
  };
  return (
    <React.Fragment>
      <GoBack />
      {userType.payload === "jobSeeker" ? (
        <section id="type-personality-look">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="personality-looking">
                  <img src={Blk} />
                  <h6>Congrats! Now let’s discover your personality traits</h6>
                  <div className="discover-hand-icon">
                    <img src={Hand} />
                  </div>
                  <a  onClick={getUserType}>
                    <img src={ArrowIcon} />{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section id="type-personality-look">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="personality-looking">
                  <img src={Blk} alt="blk" />
                  <h6>
                    Congrats! Now let’s see what type of personality you're
                    looking for
                  </h6>
                  <div className="discover-hand-icon">
                    <img src={Hand} alt="hand" />
                  </div>
                  <a  onClick={() => getUserType()}>
                    <img src={ArrowIcon} />{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
}

export default RoleStepCompletePoster;
