import React, { useEffect } from 'react';
// import { Backlogo } from '../../Utils/Constant'

import { Outlet } from 'react-router-dom';

export default function HomePage(props) {
	return (
		<div className="content-wrap" >
			<Outlet />
		</div>
	);
}
