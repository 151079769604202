import React from 'react'

function SuggestionView({Obj, onPress}) {

    console.log('het props value', Obj);
  return (
    <div style={{backgroundColor: 'red', height: 40, width: '100%'}} onClick = {() => onPress(Obj)}>
          {Obj.option}
    </div>
  )
}

export default SuggestionView