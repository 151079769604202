import React, { useState, useEffect } from 'react';
import brand from '../../../assets/images/brand-logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import {
  fullNameValidation,
  emailValidation,
  API_SEEKER_SIGN_UP,
  API_POSTER_SIGN_UP,
  postApiWithoutHeader,
  postApiPosterWithoutHeader,
  validatePhone,
} from '../../../utils';
import PhoneNumberInput from '../../../components/phoneNumber/phoneNumber';
import GoBack from '../../../components/GoBack';
import LocationSearchInput from '../../private/locationAutoSuggestion/autosugestion';

function SignupPage() {
  const navigate = useNavigate();
  const { usertype } = useParams();
  // console.log('url-param-usertype: ', usertype)
  const passedStateUserType = useSelector(state => state.userType.userType);
  // console.log('passedStateUserType: ', passedStateUserType)
  const [isLoading, setLoading] = useState(false);
  const [userType, setUserType] = useState(passedStateUserType);
  const [userFormData, setUserFormData] = useState({});
  const [errorMessage, setErrorMessage] = useState({
    name: '',
    email: '',
    location: '',
    password: '',
    repassword: '',
    mobileNumber: '',
  });

  const [locationSelected, setLocationSelected] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if(![null, undefined].includes(usertype)){
      setUserType(usertype)
    }
  }, []);

  useEffect(() => {
    setUserFormData(prev => ({ ...prev, location: locationSelected }));
  }, [locationSelected]);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setErrorMessage({});
    setUserFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleContactNumberChange = number => {
    setErrorMessage({});
    setUserFormData(prev => ({ ...prev, contactNumber: number }));
  };

  const handleLocationSelected = location => {
    setLocationSelected(location);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setErrorMessage({});

    if (locationSelected.length < 1) {
      setErrorMessage(prev => ({ ...prev, location: 'Please Enter Valid Location' }));
      return;
    }

    const locationSplit = locationSelected.split(',');
    if (!locationSplit || locationSplit.length < 2) {
      setErrorMessage(prev => ({ ...prev, location: 'Enter Complete Address' }));
      return;
    }

    if (!userFormData.fullName || !fullNameValidation(userFormData.fullName)) {
      setErrorMessage(prev => ({ ...prev, name: 'Enter Valid Full Name!' }));
      return;
    }

    if (!userFormData.email || !emailValidation(userFormData.email)) {
      setErrorMessage(prev => ({ ...prev, email: 'Enter Valid Email!' }));
      return;
    }

    if (!userFormData.contactNumber || !validatePhone(userFormData.contactNumber)) {
      setErrorMessage(prev => ({ ...prev, mobileNumber: 'Enter Valid Mobile Number!' }));
      return;
    }

    if (!userFormData.password || userFormData.password.length < 6) {
      setErrorMessage(prev => ({ ...prev, password: 'Password length must be between 6 to 20!' }));
      return;
    }

    if (userFormData.repassword !== userFormData.password) {
      setErrorMessage(prev => ({ ...prev, repassword: 'Password & confirm password did not match!' }));
      return;
    }

    function titleCase(str) {
      return str.split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    }
    
    userFormData.fullName = titleCase(userFormData.fullName);
    console.log('userFormData: ', userFormData)

    setLoading(true);
    let user_type = ''
    if(userType.hasOwnProperty('payload')){
      user_type = userType['payload']
    }else{
      user_type = userType
    }
    const apiCall =
      userType === 'jobSeeker'
        ? postApiWithoutHeader
        : postApiPosterWithoutHeader;

    const apiURL = user_type === 'jobSeeker'
        ? API_SEEKER_SIGN_UP
        : API_POSTER_SIGN_UP;

    apiCall(apiURL, userFormData, handleSuccess, handleError);
  };

  const handleSuccess = response => {
    setLoading(false);
    if (response.status === 200) {
      toast.success('Sign Up successfully!', { autoClose: 2000 });
      navigate('../emailPhoneVerify', {
        state: { email: userFormData.email, phone: userFormData.contactNumber },
      });
    }
  };

  const handleError = error => {
    setLoading(false);
    toast.error(error.response.data.error, { position: 'top-right', autoClose: 5000 });
  };

  return (
    <section id='signup-page'>
      <div className='container'>
        <div className='row justify-content-center'>
          <GoBack />
          <div className='col-lg-6 text-center'>
            <div className='brand-logo'>
              <img className='img-fluid mb-4' src={brand} style={{ width: '200px' }} alt='brand' />
            </div>
            <div>
              <h3>{userType.payload === 'jobPoster' ? 'Job Poster Sign Up' : 'Job Seeker Sign Up'}</h3>
            </div>
            <form onSubmit={handleSubmit} className={userType.payload === 'jobPoster' ? 'mt-5' : ''}>
              <div className='row mb-4'>
                <div className='col-lg-6 col-sm-6 position-relative'>
                  <div className='signup-input-bx'>
                    <label>Full Name</label>
                    <input type='text' name='fullName' placeholder='Enter your name' onChange={handleInputChange} />
                    {errorMessage.name && (
                      <div className='signup-error'>
                        <i className='fa fa-exclamation-circle mx-1' aria-hidden='true' />
                        {errorMessage.name}
                      </div>
                    )}
                  </div>
                </div>

                <div className='col-lg-6 col-sm-6 position-relative'>
                  <div className='signup-input-bx'>
                    <label>Location</label>
                    <LocationSearchInput onLocationSelected={handleLocationSelected} />
                    {errorMessage.location && (
                      <div className='signup-error'>
                        <i className='fa fa-exclamation-circle' aria-hidden='true' />
                        {errorMessage.location}
                      </div>
                    )}
                  </div>
                </div>

                <div className='col-lg-6 col-sm-6 position-relative'>
                  <div className='signup-input-bx'>
                    <label>Email Address</label>
                    <input type='text' name='email' placeholder='Enter email address' onChange={handleInputChange} />
                    {errorMessage.email && (
                      <div className='signup-error'>
                        <i className='fa fa-exclamation-circle' aria-hidden='true' />
                        {errorMessage.email}
                      </div>
                    )}
                  </div>
                </div>

                <div className='col-lg-6 col-sm-6 position-relative'>
                  <div className='signup-input-bx'>
                    <label>Mobile Number</label>
                    <PhoneNumberInput onTouch={handleContactNumberChange} initialValue='' />
                    {errorMessage.mobileNumber && (
                      <div className='signup-error'>
                        <i className='fa fa-exclamation-circle' aria-hidden='true' />
                        {errorMessage.mobileNumber}
                      </div>
                    )}
                  </div>
                </div>

                <div className='col-lg-6 col-sm-6 position-relative'>
                  <div className='signup-input-bx'>
                    <label>Enter Password</label>
                    <input type='password' name='password' placeholder='Enter password' onChange={handleInputChange} maxLength={20} />
                    {errorMessage.password && (
                      <div className='signup-error'>
                        <i className='fa fa-exclamation-circle' aria-hidden='true' />
                        {errorMessage.password}
                      </div>
                    )}
                  </div>
                </div>

                <div className='col-lg-6 col-sm-6 position-relative'>
                  <div className='signup-input-bx'>
                    <label>Confirm Password</label>
                    <input type='password' name='repassword' placeholder='Enter confirm password' onChange={handleInputChange} maxLength={20} />
                    {errorMessage.repassword && (
                      <div className='signup-error'>
                        <i className='fa fa-exclamation-circle' aria-hidden='true' />
                        {errorMessage.repassword}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <p style={{ fontSize: '0.8rem', marginTop: '-6%', marginBottom: '1rem' }}>
                By signing up you accept the <Link to='/terms'>terms of service</Link> and{' '}
                <Link to='/privacy'>privacy policy</Link>.
              </p>

              {isLoading ? (
                <button className='loading-btn' type='button' disabled>
                  <span className='spinner-grow spinner-grow-lg mx-2' role='status' aria-hidden='true' />
                  Processing..
                </button>
              ) : (
                <button className='form-btn' type='submit'>
                  Sign up
                </button>
              )}
            </form>
            <p className='login-signup-txt mt-4' style={{ marginTop: '-1%', fontSize: '0.8rem' }}>
              Already have an account?{' '}
              <strong style={{ textDecoration: 'none' }} onClick={() => navigate('../login')}>
                Login
              </strong>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SignupPage;