import React from "react";
import DocumentMeta from 'react-document-meta';

function TermsAndConditions() {

  const meta = {
    title: 'Terms and Conditions | Perspectv',
    description: "Read Perspectv's terms and conditions for using our AI-powered recruitment platform. By using our services, you agree to abide by these terms and conditions.",
    // canonical: 'http://example.com/path/to/page',
    // meta: {
    //   charset: 'utf-8',
    //   name: {
    //     keywords: 'react,meta,document,html,tags'
    //   }
    // }
  };


  return (
    <DocumentMeta {...meta}>
      <div className="terms-conditions">
        <h1>Terms and Conditions</h1>
        <p>
          By using our website, you agree to the following terms and conditions:
        </p>
        <h2>Meanings</h2>
        <ol className="container">
          <li>These terms and conditions (“Terms”, “Agreement”) govern all Users (“Job Seekers”, “Job Posters” also defined as “you” and “your”) that have a Personal or Company profile page, and who use our Services, app or website (www.perspectv.app, “Our Services” “Site” or “Website” or “App”) to promote themselves or connect with other users.</li>

          <li>
            The Perspectv website and mobile application are owned and operated by Perspectv Recruitment Limited, (71-75 Shelton Street, Covent Garden, London, United Kingdom, WC2H 9JQ) defined herein as (“Perspectv”, “we”, “us” and “our”).
          </li>
          <li>
            Third-party Services (or providers) refer to and may include advertisers, contest sponsors, promotional and marketing partners, and others who provide content or products or Services we think may interest you, and social media sites and social & business networking sites.
          </li>
          <li>
            Our Services(s) (“our Services”) refers to any service provided by Perspectv. Your access to and use of our website, Services and App is conditional upon your acceptance and compliance with these Terms.
          </li>
          <li>
            Job Seeker (“user”, “applicant”, “person” or “candidate”) refers to any individual person who registers with Perspectv and uses our Services, Site and/or App to explore employment opportunities and connect with Job Posters.
          </li>
          <li>
            Job Poster (“user”, “company”, “business”) refers to any entity that registers with Perspectv and uses our Services, Site and/or App to promote employment opportunities and connect with Job Seekers.
          </li>
          <li>
            We may offer products and Services for purchase on the Website and in our App (“In-App Purchase”).
          </li>
          <li>
            We may send you emails, text messages, push notifications, alerts and other messages (“Notifications”) related to the App and/or our Services. If you do not wish to receive push notifications/alerts from the App, you can opt out by changing your notification settings in the App.
          </li>
        </ol>
        <h2>General Terms</h2>
        <ol className="container">
          <li>
            These Terms, constitute the entire agreement between you and us. Please take the time to read them carefully. By using our Website, App and Services, you confirm that you accept these Terms and that you agree to comply with them. The Terms supersede all previous agreements, representations and arrangements between us (written or oral), excluding the Privacy Policy.
          </li>
          <li>
            By using our Services, you acknowledge that we have no control over the quality, accuracy, completeness or legality of content provided by third parties. In addition, you acknowledge that we have no control over the integrity or actions of Job Seekers or Job Posters.
          </li>
          <li>
            When registering, you will be asked to provide and verify your email address, in doing so, you acknowledge that we will use the email address to fulfil our Services, send you information, notices and other communication. We will continue to send you such communications until you opt-out. If you do not want to receive alerts or notifications and/or marketing communications from us, you can change your preferences in the settings.
          </li>

        </ol>

        <h2>User Accounts </h2>

        <ol className="container">

          <li>To use our Services, you will need to register and create a user account.
          </li>
          <li>
            Once registered, you can manage your profile and update information via the Settings page
          </li>
          <li>
            You are entirely responsible for the maintenance and security of your account. You understand and agree that your account is personal to you, and that you will not share your access details or allow other people to use your account/profile to access our Website, App or Services.
          </li>

          <li>
            You agree to notify us immediately of any unauthorised access to or use of your username or password or any other breach of security. You understand that even if you notify us, you will be totally responsible for all activities that occur under your account. You also agree to ensure that you logout from your account at the end of each session. You are responsible for any password misuse or any unauthorised access.
          </li>

          <li>
            If you use a third-party service or social networking site when registering or connecting to our website or app, you give us permission to access and use your information from that service as permitted by that service, and to store your log-in credentials for that service. You agree that we are not liable for any personal data that is made available to us in violation of your privacy settings with the applicable social networking site.
          </li>

          <li>
            You are solely responsible for your interactions with other Users. We reserve the right, but have no obligation, to monitor communication between Users. We shall have no liability for your interactions with other Users and are under no obligation to enforce any part of this Agreement against another User.
          </li>

          <li>
            You acknowledge and agree that we may stop (permanently or temporarily) providing our Services (or any features within our Services) to you or to users generally at our sole discretion, without prior notice to you.
          </li>

        </ol>

        <h2>Content and Users </h2>
        <ol className="container">

          <li>
            Our platform allows Users to create content that may be viewable by other Users, including, but not limited to, job opportunities, company information, job seeker’s information, content of messages, resumes/CVs, logos, trademarks, comments, questions, and other content or information. You are entirely responsible for anything you post online.
          </li>

          <li>
            Any content you create on Perspectv belongs to us! When creating or uploading content on our website or App, you represent and warrant to us that you have all necessary rights and licences to do so, and automatically grant us a non-exclusive, royalty free, perpetual, worldwide licence to use your content in any way.
          </li>

          <li>
            We reserve the right to disclose any content and user information, if it is considered necessary to comply with legal obligations and requests from governmental authorities, law enforcement agencies, court orders, subpoenas, etc., and to protect our own commercial interests.
          </li>

          <li>
            We may, at our absolute discretion, modify or suspend any Profile Page, block or remove any Content, at any time and without notice if it fails to meet the standard we require for publication. These standards preclude any Content that is, or may in our opinion be, factually incorrect, unlawful, harassing, threatening, harmful, tortious, defamatory, libellous, abusive, violent, obscene, invasive of another’s privacy, racially or ethnically offensive or otherwise in our opinion objectionable or damaging to Perspectv, used without consent or in breach of third party rights, incentivised by third parties, contain poor grammar, reference illegal activities, or any form of submission that in our absolute discretion is deemed unhelpful to, or not in the spirit of, helping users.
          </li>

          <li>
            It is your responsibility to ensure that the Content on your Profile Page is current and accurate at all times. Perspectv does not fact check Content to verify its accuracy, nor do we endorse any Content submitted.
          </li>

          <li>
            You acknowledge and agree that we cannot authenticate every user. It is your responsibility to carry out proper due diligence before communicating or interacting with other Users.
          </li>

          <li>
            We may monitor user activity and/or review accounts and messages for content that might breach these Terms. We reserve the right, at our sole discretion, to terminate or suspend any Account and or restrict access to the App, at any time without liability and without the need to give you prior notice. Without limiting the foregoing, we expressly reserve the right to terminate or suspend any user Account without notice:
          </li>

          <ul> If we feel you have violated these terms,   </ul>
          <ul> If we feel your conduct on the App, or your conduct with other users of the App (including your “offline” conduct), is inappropriate or improper,  </ul>
          <ul>If we feel that it’s in our best interest.   </ul>
        </ol>

        <h2>Licence To Access Our Services  </h2>

        <p className="container">
          Perspectv grants you a personal, worldwide, royalty-free, non-assignable, nonexclusive, revocable, and non-sublicensable license to access and use our Services. This license is for the sole purpose of letting you use our Services as intended by Perspectv and permitted by this Agreement. You may not:
        </p>
        <ol className="container">
          <li>
            use our Services or any content contained in our Services for any commercial purposes without our written consent.
          </li>
          <li>
            express or imply that any statements you make are endorsed by Perspectv.
          </li>
          <li>
            use any robot, bot, spider, crawler, scraper, site search/retrieval application, proxy or other manual or automatic device, method or process to access, retrieve, index, "data mine", or in any way reproduce or circumvent the navigational structure or presentation of our Services or its contents.
          </li>
          <li>
            use our Services in any way that could interfere with, disrupt or negatively affect our Services or the servers or networks connected to our Services.
          </li>
          <li>
            upload viruses or other malicious code or otherwise compromise the security of our Services.
          </li>
          <li>
            forge headers or otherwise manipulate identifiers in order to disguise the origin of any information transmitted to or through our Services.
          </li>
          <li>
            "frame" or "mirror" any part of our Services without our prior written authorisation.
          </li>
          <li>
            modify, adapt, sublicense, translate, sell, reverse engineer, decipher, decompile or otherwise disassemble any portion of our Services, or cause others to do so.

          </li>
          <li>
            use or develop any third-party applications that interact with our Services or other members' Content or information without our written consent.
          </li>
          <li>
            use, access, or publish our application programming interface without our written consent.
          </li>
          <li>
            probe, scan or test the vulnerability of our Services or any system or network.
          </li>
          <li>
            encourage or promote any activity that violates this Agreement.
          </li>
        </ol>

        <h2>Information </h2>

        <ol className="container">
          <p>
            When creating an account or uploading information to your profile, you represent and warrant that you will provide true and complete information and that you will keep this information accurate and up to date. You are solely responsible for the management of your account.
          </p>
          <p>
            You agree that we act as a passive conduit for the distribution, provision, and publication of content created by our users, and have no obligation to screen or verify the accuracy, legality, legitimacy, reliability, truthfulness, or completeness of any content that is created by users. Furthermore, you agree and accept that it is your sole responsibility to verify the authenticity of any content you encounter.
          </p>

          <p>
            Under no circumstances will we be liable to you or anyone else for any decision made or action taken by you or anyone else in reliance upon any information contained on or omitted from our Site or App.
          </p>
        </ol>
        <h2>Privacy </h2>

        <ol className="container">
          <p> For information about how we collect, use, and share your personal data, please check out our <a > Privacy Policy.</a> </p>
        </ol>

        <h2> No Guarantees       </h2>
        <div className="container">
          <p> We do not guarantee the availability of any job advertised or candidate listed on our Site or App. We are simply here to introduce Job Seekers to Job Posters and have no influence over whether they decide to connect or engage with each other.  </p>
          <p>The website/app is provided to you "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. We provide no warranty to you that our Services will be uninterrupted or error-free or that defects in our Services will be corrected.   </p>
          <p>You understand that we cannot guarantee or warrant to you that files downloaded from our Site or delivered via electronic mail through our Site or App will be free of infection or viruses, worms, Trojan horses or other code that manifest contaminating or destructive properties. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for accuracy of data input and output, and for implementing and maintaining external measures capable of restoring any lost data. </p>
          <p>
            While we take all due care in ensuring the privacy and integrity of the information you provide, the possibility exists that this information could be unlawfully observed by a third party while in transit over the Internet or while stored on our systems or on our Site/App. We disclaim all liability to you to the greatest extent possible pursuant to law should this occur.
          </p>
        </div>

        <h2>Changes To Our Terms & Conditions      </h2>
        <p className="container">We reserve the right to modify, amend or change these Terms and Conditions at any time. If we do make a change, we’ll send you an email to inform you of the change. It’s also possible that we might ask you to agree to our changes, but we’ll let you know.   </p>

        <h2>Limitation Of Liability</h2>
        <p className="container">
          To the extent allowed by law, we limit our liability to you for certain types of damages for claims relating to your use of our Services, the conduct of other users and unauthorised access or use of your Content. In any event, our aggregate liability will not exceed £100.
        </p>
        <p className="container">
          <b>
            IF YOU BECOME DISSATISFIED IN ANY WAY WITH OUR APP OR SITE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO STOP USING OUR SERVICES AND DELETE YOUR ACCOUNT.
          </b>
        </p>

        <h2>Severability  </h2>
        <p className="container">
          If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.
        </p>
        <p className="container">
          This Agreement, together with the Privacy Policy and any other legal notices published by us on our Services, shall constitute the entire agreement between you and us concerning our Services. If any provision of this Agreement is deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of this Agreement, which shall remain in full force and effect.
        </p>

        <h2>Indemnity By You </h2>
        <p className="container">
          All the actions you make and information you post on Perspectv remain your responsibility. Therefore, you agree to indemnify, defend, release, and hold us, and our partners, licensors, affiliates, contractors, officers, directors, employees, representatives and agents, harmless, from and against any third-party claims, damages (actual and/or consequential), actions, proceedings, demands, losses, liabilities, costs and expenses (including reasonable legal fees) suffered or reasonably incurred by us arising as a result of, or in connection with:
        </p>
        <ol className="container">
          <li>any negligent acts, omissions or wilful misconduct by you;   </li>
          <li>your access to and use of our Site and App;  </li>
          <li>the uploading or submission of Content to our Site and App by you;        </li>
          <li>any breach of these Terms by you; and/or        </li>
          <li> your violation of any law or of any rights of any third party.        </li>

        </ol>
        <h2> Payments</h2>
        <p className="container">
          Certain aspects of our Services require payment. If you are a Job Poster and decide to use our paid Services to engage with Job Seekers, you agree to our pricing and payment terms for that part of our Services.
        </p>
        <p className="container">
          We may (1) add new Services and products for additional fees and charges, at any time at our sole discretion, or (2) amend fees and charges for existing services, at any time at our sole discretion upon notice.

        </p>
        <p className="container">
          If you choose to make an In-App Purchase, you acknowledge and agree that additional terms, disclosed to you at the point of purchase, may apply.

        </p>

        <p className="container">
          When making an In-App Purchase you can use a valid credit card or debit card, which will be processed by a third-party processor. Once you have made an In-App Purchase, you authorise us to charge your chosen Payment Method. If payment is not received by us from your chosen Payment Method, you agree to promptly pay all amounts due upon demand by us. You agree that applicable credit card, debit card and billing information may be shared with third parties such as payment processors and credit agencies, and/or collection agencies for the purposes of checking your credit and effecting payments. We may also share this information with law enforcement authorities and in response to subpoenas, court orders, and search warrants when instructed to do so.
        </p>
        <p className="container">
          Any information you provide in connection with a monetary transaction must be accurate, complete, and current. You agree to pay all charges incurred in connection with your purchase. You will also pay any applicable taxes relating to such transactions.
        </p>

        <h2> Refunds      </h2>

        <p className="container">
          You understand and accept that, to the fullest extent available under applicable law, you will not receive a refund for any pre-paid Services (for example, if you purchase credits or pay to access a Job Seekers contact details).

        </p>
        <p className="container">
          Should you breach this Agreement, we reserve the right to suspend, limit or terminate your access to our Services. In this event, you understand and accept that you will not receive a refund for any unused credits or pre-paid services.
        </p>

      </div>
    </DocumentMeta>
  );
}

export default TermsAndConditions;
