import axios from 'axios';
import { BASE_URL } from '../APIConstant';

const API = axios.create({ baseURL: BASE_URL });

export const createChat = (data) => API.post('/api/chat/', data);

export const userChats = (id) => API.get(`/api/chat/${id}`);

export const getUser = (id, userType) => API.get(`/api/chat/getuser/${id}/${userType}`);

export const findChat = (firstId, secondId) => API.get(`/api/chat/find/${firstId}/${secondId}`);
