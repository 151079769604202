import React from 'react';
import ProfileIcon from '../../assets/images/profile-img-standard.png';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
export default function CardJobMatches({ displayContent }) {
	const percentage = 75;
	return (
		<div className="card postion-relative" style={{border: '2px solid grey'}}>
			<img className="card-img-top mt-2" src={ProfileIcon} alt="Card image" />
			<div className="iconHeart">
				<i className="fa fa-2x fa-heart" style={{ color: 'red' }} aria-hidden="true" />
			</div>
			<div className="card-body ">
				<div className="row">
					<div className="col-3 mt-3">
						<CircularProgressbar
							styles={{
								// Customize the root svg element
								root: {},
								// Customize the path, i.e. the "completed progress"
								path: {
									// Path color
									stroke: `rgba(217, 69, 61, ${displayContent?.percentage.toFixed(0) / 100})`,
									// Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
									strokeLinecap: 'butt',
									// Customize transition animation
									transition: 'stroke-dashoffset 0.5s ease 0s',
									// Rotate the path
									//   transform: 'rotate(0.25turn)',
									transformOrigin: 'center center'
								},
								// Customize the circle behind the path, i.e. the "total progress"
								trail: {
									// Trail color
									stroke: '#d6d6d6',
									// Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
									strokeLinecap: 'butt',
									// Rotate the trail
									transform: 'rotate(0.25turn)',
									transformOrigin: 'center center'
								},
								// Customize the text
								text: {
									// Text color
									fill: '#f88',
									// Text size
									// fontSize: '16px'
								},
								// Customize background - only used when the `background` prop is true
								background: {
									// fill: '#3e98c7'
								}
							}}
							value={percentage}
							text={`${percentage}%`}
						/>;
					</div>

					<div className="col-8">
						<h4 className=" default-heading text-left">John Doe</h4>
						<p className="default-subtitle-text ">Some example text.</p>
						<p className=" default-subtitle-text">Some example text.</p>
					</div>
				</div>
			</div>
		</div>
	);
}
