import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SplashImage from '../../../assets/images/main-screen-logo.png';
import SplashImage_mobile from '../../../assets/images/mobile-splash.png';

function SplashPage() {
	const navigation = useNavigate();
	const [IsMobile, setIsMobile] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			navigation('/landing');
		}, 3000);
	}, []);

	useEffect(() => {
		if (window.matchMedia('(max-width: 767px)').matches) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	}, [window.matchMedia]);

	return (
		<>
			{
				IsMobile ? (
					<section id="splash-screen">
						<img className="img-fluid" src={SplashImage_mobile} />

					</section>
				) : (
					<section id="splash-screen">
						<div className="container-flash">
							<div className="row">
								<div className="col-lg-12 text-center">
									<div className="splash-screen-cnt">


										<img className="img-fluid" src={SplashImage} />


									</div>
								</div >
							</div >
						</div >
					</section >
				)
			}
		</>

	);
}

export default SplashPage;
