import React, { useState, useEffect } from 'react';
import brand from '../../../assets/images/brand-logo.png';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
	API_CHANGEVERIFYPHONE_OTP,
	postApiPoster,
	API_CHANGERESEND_OTP
} from '../../../utils';
import OtpInput from 'react-otp-input';

function Otp() {
	const navigation = useNavigate();
	const { state } = useLocation();
	const [otp, setOtp] = useState('');
	const [isButtonDisabled, setButtonDisabled] = useState(true);
	const userType = useSelector((state) => state.userType.userType);
	const [isLoading, setLoading] = useState(false);
	const [userInfo] = useState(state);
	const userToken = useSelector((state) => state.UserAuth.userData.payload.token);

	const resendOtp = () => {
		setLoading(true);
		postApiPoster(API_CHANGERESEND_OTP, userInfo, userToken, successCallBack, errorCallBack);
	};
	const successCallBack = (response) => {
		setLoading(false);
		toast.success(response.data.message, {
			autoClose: 2000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	};

	const errorCallBack = (error) => {
		setLoading(false);
		toast.error(error.response.data.message, {
			position: 'top-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	};
	const style = {
		borderTop: 'none',
		borderLeft: 'none',
		borderRight: 'none',
		borderBottom: '7px solid #000 ',
		fontSize: '24px',
		boxShadow: 'none ',
		margin: '0 20px ',
		height: '60px ',
		background: 'transparent',
		fontWeight: 'bold'
	};
	const checkOtpStatus = (e) => {
		e.preventDefault();
		let obj = {
			otp: otp,
			_id: userInfo._id,
			userType: userInfo.userType
		};
		if (otp.toString().length === 6) {
			postApiPoster(API_CHANGEVERIFYPHONE_OTP, obj, userToken, successMessage, errorMessagee);
		}
	};
	const successMessage = (response) => {
		setLoading(false);
		toast.success(response.data.message, {
			position: 'top-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
		if (response.status === 200) {
			navigation('../profileUpdate');
		} else {
			alert('Something went wrong.');
		}
	};

	const errorMessagee = (error) => {
		setLoading(false);
		toast.error(error.response.data.message, {
			position: 'top-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});

	};

	useEffect(
		() => {
			if (otp.toString().length === 6) {
				setButtonDisabled(false);
			}
		},
		[otp]
	);

	return (
		<>
			<section id="login-page">
				<div className="container">
					<div className="row">
						<div className="col-lg-2" />
						<div className="col-lg-8 text-center">
							<div className="brand-logo">
								<img className="img-fluid" src={brand} alt="brand" />
							</div>

							<form onSubmit={checkOtpStatus}>
								<div className="otp-input">
									<OtpInput
										inputStyle={style}
										focusStyle={false}
										shouldAutoFocus={false}
										// isInputNum={true}
										onChange={(otp) => setOtp(otp)}
										numInputs={6}
										value={otp}
										style={{ color: 'red' }}
									/>
								</div>
								{/* <!--otp-input--> */}
								<h6>
									<strong style={{ cursor: 'pointer' }} onClick={() => resendOtp()}>
										RESEND CODE
									</strong>
									<br />
									<br />
									Enter 6 digit's code we just sent to your phone number
								</h6>
								{isLoading ? (
									<button className="loading-btn" type="button" disabled>
										<span
											className="spinner-grow spinner-grow-lg mx-2"
											role="status"
											aria-hidden="true"
										/>
										Processing..
									</button>
								) : (
									<button
										type="submit"
										className={isButtonDisabled ? 'form-btn disabled' : 'form-btn disabled'}
										disabled={isButtonDisabled}
										onClick={() => checkOtpStatus()}
									>
										Verify
									</button>
								)}
							</form>
						</div>
						<div className="col-lg-2" />
					</div>
				</div>
			</section>
		</>
	);
}

export default Otp;
