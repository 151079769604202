import axios from 'axios';
import { BASE_URL } from '../../utils';

export const postData = (url, data) => {
    const endpoint = `${BASE_URL}${url}`
    const config = {
        method: 'post',
        url: endpoint,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Accept-Language': 'en-GB,en',
            'Content-Type': 'application/json' 
        },
        data: data
    };

    return axios(config);
}