import React, { useState } from "react";
import { useLocation, useNavigate, } from "react-router-dom";
import { useSelector } from 'react-redux';

import AddCard from "../addCard";
import SavedCard from "../SavedCard";


function PaymentGateway() {

  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const location = useLocation();
  const navigation = useNavigate();
  const cardInfo = location?.state?.cardInfo || null;
  const planInfo = location?.state?.planInfo;
  const userType = useSelector(state => state.userType.userType);

  const planId = planInfo?.title;
  const planName = planInfo?._id;
  const credit = planInfo?.credit;
  const amount = planInfo?.offerprice ?? 35;

  var payAsyouGoAmount = 0;
  var payAsYouGoProfileIds = [];


  // if (userType.payload === 'jobSeeker') {
  //   payAsyouGoAmount = 35;
  //   console.log("this is job seeker so changing the amount to 35")
  // } else {
  if (location?.state?.payAsYouGoData) {
    payAsyouGoAmount = location?.state?.payAsYouGoData.amount;
    payAsYouGoProfileIds = location?.state?.payAsYouGoData.profileId;
  }
  if (location?.state?.payAsYouGoData) {
    console.log("this is pay as you go purchasing", payAsyouGoAmount, "total state", payAsYouGoProfileIds);

  } else {
    console.log("-----this is plan info-------on payment gateway", planInfo);
  }
  // }

  const rendeElemnt = cardInfo ? (
    <SavedCard
      amount={amount}
      setPaymentCompleted={setPaymentCompleted}
      cardInfo={cardInfo}
      planId={planId}
      credit={credit}
      planName={planName}
      payAsyougoAmount={payAsyouGoAmount}
      payAsYouGoProfileIds={payAsYouGoProfileIds}
      setPaymentIntent={setPaymentIntent}
    />
  ) : (
    <AddCard
      amount={amount}
      setPaymentCompleted={setPaymentCompleted}
      planId={planId}
      credit={credit}
      planName={planName}
      payAsyougoAmount={payAsyouGoAmount}
      payAsYouGoProfileIds={payAsYouGoProfileIds}
      setPaymentIntent={setPaymentIntent}
    />
  );

  return (
    <React.Fragment>
      {paymentCompleted ? navigation('../purchaseComplete', {state: {paymentIntent: paymentIntent}}) : rendeElemnt}
    </React.Fragment>
  );
}

export default PaymentGateway;
