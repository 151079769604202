import React, { useState, useEffect } from 'react';
import brand from '../../../assets/images/logo-icon-blk.png';
import currency from '../../../assets/images/pound-icon.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import {
  API_SEEKER_JOB_ROLE_OPTIONS,
  API_SEEKER_JOB_ROLE_TITLE,
  API_COMMON_ONBOARDING_STEPS,
  getApi,
  postApi,
  postApiPoster,
} from '../../../utils';
import GoBack from '../../../components/GoBack';
import { saveUserInfo } from '../../../redux/actions';
// import SelectCurrency from 'react-select-currency'
import '../salary/index.css';

function RoleStepEight() {
  //*******Getting router passed data***************************/
  const { state } = useLocation();
  const jobId = state.jobId;
  const [routerData] = useState(state);
  const navigation = useNavigate();
  const [isRadioChecked, setRadioChecked] = useState(undefined);
  const [rangeValueHourly, setRangeValueHourly] = useState(10);
  const [rangeValueMonthly, setRangeValueMonthly] = useState(1000);
  const [rangeValueAnnual, setRangeValueAnnual] = useState(20000);
  const [employmentLength, setEmploymentLength] = useState([]);
  const [jobTitle, setJobTitle] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isFetchedData, setFetchedData] = useState(false);
  const userType = useSelector(state => state.userType.userType);
  const userToken = useSelector(state => state.UserAuth.userData.payload.token);
  const userId = useSelector(state => state.UserAuth.userData.payload.user._id);


  console.log('get user selected data till now', routerData);
  //Todo: work is this component pending due to API dependecy
  const savingInfo = () => {
    if (employmentLength !== '') {
      if (!isRadioChecked) {
        toast.error('Please select minium asking for!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        if (isRadioChecked === 1) {

          const range = rangeValueHourly;
          const type = `hour:${selectedItem}`;
          const resullt = type.concat(range);
          updateOnBoardingSteps(employmentLength, resullt)
          // navigation('../roleStepComplete', {
          //   state: {
          //     ...routerData,
          //     employmentType: employmentLength,
          //     employmentMode: resullt,
          //   },
          // });

        } else if (isRadioChecked === 2) {
          const range = rangeValueMonthly;
          const type = `monthly:${selectedItem}`;
          const resullt = type.concat(range);
          updateOnBoardingSteps(employmentLength, resullt)

          // navigation('../roleStepComplete', {
          //   state: {
          //     ...routerData,
          //     employmentType: employmentLength,
          //     employmentMode: resullt,
          //   },
          // });

        } else if (isRadioChecked === 3) {
          // if (userType.payload === 'jobSeeker') {
          const range = rangeValueAnnual;
          const type = `annual:${selectedItem}`;
          const resullt = type.concat(range);
          updateOnBoardingSteps(employmentLength, resullt)
          // navigation('../roleStepComplete', {
          //   state: {
          //     ...routerData,
          //     employmentType: employmentLength,
          //     employmentMode: resullt,
          //   },
          // });

        }
      }
    } else {
      toast.error('Please choose any of the option or type manually!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  // *******************************call api fucntion *********************************
  const requestDataFromServer = () => {
    setLoading(true);
    const obj = {
      category: 'salary',
    };
    if (userType.payload === 'jobSeeker') {
      postApi(API_SEEKER_JOB_ROLE_TITLE, obj, userToken, successFunction, errorFunction);
    } else {
      postApiPoster(API_SEEKER_JOB_ROLE_TITLE, obj, userToken, successFunction, errorFunction);

    }

  };
  const successFunction = response => {
    if (response.status === 200) {
      setLoading(false);
      console.log(response.data.result, 'title');
      setJobTitle(response.data.result);
    }
  };
  const errorFunction = error => {
    setLoading(false);
    setFetchedData(true);
    console.log(error, 'drerer');
  };

  useEffect(() => {
    if (!isFetchedData) {
      requestDataFromServer();
    }
  }, [isFetchedData]);


  const updateOnBoardingSteps = (employmentLength, resullt) => {

    const obj = {
      jobId: jobId,
      "userId": userId,
      "employmentMode": resullt,
      "employmentType": employmentLength,
      "userType": userType.payload,
      "stepNumber": 8,
      // "_id": "642d1ab16fa8534d104c5a20"
    };
    // debugger
    postApi(API_COMMON_ONBOARDING_STEPS, obj, userToken, successFunctionOnboardingSteps, errorSavingData);
  }
  const userAuthInfo = useSelector(state => state.UserAuth);
  const dispatch = useDispatch();

  const successFunctionOnboardingSteps = res => {

    setLoading(false);

    if (res.status === 200) {

      // dispatch the selfAssessmentstep
      var existingUserInfo = userAuthInfo.userData?.payload;
      existingUserInfo.user.selfAssessmentResultSteps = 8;

      dispatch(
        saveUserInfo({
          payload: existingUserInfo,
        })
      );

      navigation('../roleStepComplete', {state: {jobId: jobId}});


    }

  }


  const errorSavingData = err => {
    toast.error(err.data.message, {
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  // const data = [
  //   { id: '$', label: "Dollars" },
  //   { id: '€', label: "Euro (EUR)" },
  //   { id: '£', label: "British pound" },
  //   { id: '¥', label: "Japanese yen (JPY)" },
  //   { id: 'CHF', label: "Swiss franc (CHF)" },
  //   { id: '¥', label: "Chinese yuan (CNY)" },
  //   { id: '₹', label: "INDIA (INR)" },
  // ];

  const [isOpen, setOpen] = useState(false);
  // const [items, setItem] = useState(data);
  const [selectedItem, setSelectedItem] = useState("£");

  const toggleDropdown = () => setOpen(!isOpen);

  const handleItemClick = (id) => {
    setSelectedItem(id);
    setOpen(false);
  }


  return (
    <React.Fragment>
      {isLoading && (
        <div className='d-flex justify-content-center align-items-center LoaderCenter'>
          {' '}
          <div className='block'>
            <span className='spinner-grow spinner-grow-lg mx-2' role='status' aria-hidden='true' />
            <h6 className='LoaderText'>Processing...</h6>
          </div>
        </div>
      )}
      {!isLoading && (
        <section id='job-role-steps'>
          <div className='discover-personality-top-cnt'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12 text-center'>
                  <img src={brand} alt='brand' className='w-80px' />
                </div>
              </div>
            </div>
          </div>
          {/* <!--discover-personality-top-cnt--> */}

          <div className='container'>
            <GoBack />
            <div className='row justify-content-center'>
              <div className='col-lg-2' />
              <div className='col-lg-8'>
                <div className='card' style={{ borderWidth: 0 }}>
                  <form id='msform'>
                    {/* <!-- progressbar --> */}
                    <fieldset>
                      <div className='form-card'>
                        {userType.payload === 'jobSeeker'
                          ? jobTitle &&
                          jobTitle.map(data => (
                            <h3 key={data._id} className='job-que'>
                              {data.question}
                            </h3>
                          ))
                          : jobTitle &&
                          jobTitle.map(data => (
                            <h3 key={data._id} className='job-que'>
                              {data.question}
                            </h3>
                          ))}
                        <div className='role-wrapper skyblue-radio-btn'>
                          <div className='role-col'>
                            <div className='inputGroup'>
                              <input
                                id='radio49'
                                name='eradio'
                                type='radio'
                                onChange={() => setEmploymentLength('Permanent')}
                              />
                              <label htmlFor='radio49'>Permanent</label>
                            </div>
                          </div>
                          {/* <!--role-col--> */}

                          <div className='role-col'>
                            <div className='inputGroup'>
                              <input
                                id='radio50'
                                name='eradio'
                                type='radio'
                                onChange={() => setEmploymentLength('Contract')}
                              />
                              <label htmlFor='radio50'>Contract</label>
                            </div>
                          </div>
                          {/* <!--role-col--> */}

                          <div className='role-col'>
                            <div className='inputGroup'>
                              <input
                                id='radio51'
                                name='eradio'
                                type='radio'
                                onChange={() => setEmploymentLength('Freelance')}
                              />
                              <label htmlFor='radio51'>Freelance</label>
                            </div>
                          </div>
                          {/* <!--role-col--> */}

                          {/* <div className='role-col'>
                            <div className='inputGroup'>
                              <input
                                id='radio52'
                                name='eradio'
                                type='radio'
                                onChange={() => setEmploymentLength('Junior (1-2 years)')}
                              />
                              <label htmlFor='radio52'>Junior (1-2 years)</label>
                            </div>
                          </div> */}
                          {/* <!--role-col--> */}
                        </div>
                        {/* selecting currency drop-down */}
                        {/* <div className='row' >
                          <h3 className='job-qu'> Select Currency  </h3>

                          <div className='role-wrapper'>
                            <div className='dropdown '>
                              <div className='dropdown-header' onClick={toggleDropdown}>
                                {selectedItem ? items.find(item => item.id == selectedItem).label : "Euro (EUR)"}
                                <i className={`fa fa-chevron-right icon ${isOpen && "open"}`}></i>
                              </div>
                              <div className={`dropdown-body ${isOpen && 'open'}`}>
                                {items.map(item => (
                                  <div className="dropdown-item" onClick={e => handleItemClick(e.target.id)} id={item.id}>
                                    {item.label}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>

                        </div> */}



                        {/* <!--role-wrapper--> */}
                        {userType.payload === 'jobSeeker' ? (
                          <h3 className='job-que'>
                            Select your pay structure and your minimum salary expectation?{' '}
                          </h3>
                        ) : (
                          <h3 className='job-que'>What is the maximum you offering?</h3>
                        )}

                        {/* <div className='dropdownContainer'>
                          <h5> Select Currency:</h5>
                          <div className='dropdown '>
                            <div className='dropdown-header' onClick={toggleDropdown}>
                              {selectedItem ? items.find(item => item.id == selectedItem).label : "Euro (EUR)"}
                              <i className={`fa fa-chevron-right icon ${isOpen && "open"}`}></i>
                            </div>
                            <div className={`dropdown-body ${isOpen && 'open'}`}>
                              {items.map(item => (
                                <div className="dropdown-item" onClick={e => handleItemClick(e.target.id)} id={item.id}>
                                  {item.label}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <br /> */}

                        <div className='role-wrapper'>
                          <div className='role-col' onClick={() => setRadioChecked(1)}>
                            <div className='inputGroup'>
                              <input id='radio53' name='radio' type='radio' />
                              <label htmlFor='radio53'>Hourly Rate</label>
                            </div>
                          </div>
                          {/* <!--role-col--> */}

                          <div className='role-col' onClick={() => setRadioChecked(2)}>
                            <div className='inputGroup'>
                              <input id='radio54' name='radio' type='radio' />
                              <label htmlFor='radio54'>Monthly</label>
                            </div>
                          </div>
                          {/* <!--role-col--> */}

                          <div className='role-col' onClick={() => setRadioChecked(3)}>
                            <div className='inputGroup'>
                              <input id='radio55' name='radio' type='radio' />
                              <label htmlFor='radio55'>Annual</label>
                            </div>
                          </div>

                          <div className='range-slider'>
                            {isRadioChecked === 1 && (
                              <div className='slidecontainer'>
                                <input
                                  type='range'
                                  min='10'
                                  max='100'
                                  defaultValue={rangeValueHourly}
                                  onChange={e => setRangeValueHourly(e.target.value)}
                                  className='slider form-range'
                                  id='customRange2'
                                  step='10'
                                  disabled={isRadioChecked === undefined}
                                />
                                <p className='col-12 d-flex mt-4' >
                                  <h2>{selectedItem}{rangeValueHourly}</h2>
                                  {/* <img src={currency} alt='' />{' '} */}
                                  {/* <span id='demo'>{rangeValueAnnual}</span> */}
                                </p>
                              </div>
                            )}
                            {isRadioChecked === 2 && (
                              <div className='slidecontainer'>
                                <input
                                  type='range'
                                  min='1000'
                                  max='20000'
                                  defaultValue={rangeValueMonthly}
                                  onChange={e => setRangeValueMonthly(e.target.value)}
                                  className='slider form-range'
                                  id='customRange2'
                                  step='1000'
                                  disabled={isRadioChecked === undefined}
                                />
                                <p className='col-12 d-flex mt-4' >
                                  <h2>{selectedItem}{rangeValueMonthly}</h2>
                                  {/* <img src={currency} alt='' />{' '} */}
                                  {/* <span id='demo'>{rangeValueAnnual}</span> */}
                                </p>
                              </div>
                            )}
                            {isRadioChecked === 3 && (
                              <div className='slidecontainer'>
                                <input
                                  type='range'
                                  min='20000'
                                  max='120000'
                                  defaultValue={rangeValueAnnual}
                                  onChange={e => setRangeValueAnnual(e.target.value)}
                                  className='slider form-range'
                                  id='customRange2'
                                  step='10000'
                                  disabled={isRadioChecked === undefined}
                                />
                                <p className='col-12 d-flex mt-4' >
                                  <h2>{selectedItem}{rangeValueAnnual}</h2>
                                  {/* <img src={currency} alt='' />{' '} */}
                                  {/* <span id='demo'>{rangeValueAnnual}</span> */}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                        {/* <!--role-wrapper--> */}
                      </div>
                      {/* <!--form-card--> */}

                      <input
                        type='button'
                        name='next'
                        className='next action-button mt-0'
                        value=''
                        onClick={savingInfo}
                      />
                      <div className='row'>
                        <h2
                          className='steps'
                          style={{ fontSize: '1.2rem', margin: '0rem 0rem 1rem' }}
                        >
                          Step 8/8
                        </h2>
                      </div>
                    </fieldset>
                  </form>

                  <div className='progress'>
                    <div
                      className='progress-bar'
                      role='progressbar'
                      style={{ width: '100%' }}
                      aria-valuenow='25'
                      aria-valuemin='0'
                      aria-valuemax='100'
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-2' />
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
}

export default RoleStepEight;
