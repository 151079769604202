import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import {
  addMessage,
  getMessages,
} from '../../../../utils/ChatAPi/MessageRequests'
import { getUser } from '../../../../utils/ChatAPi/ChatRequests'
import DefaultImage from '../../../../assets/images/defaultProfile.png'
import fileDefault from '../../../../assets/images/fileDefault.png'
import MessageSendIcon from '../../../../assets/images/message.svg'

import './ChatBox.css'
import { toast } from 'react-toastify'
import { format } from 'timeago.js'
import InputEmoji from 'react-input-emoji'
import { useSelector } from 'react-redux'
import {
  Chat_image_file,
  Profile_image_seeker,
  Profile_image_poster,
} from '../../../../utils'
import FileBase64 from 'react-file-base64'

const ChatBox = ({ chat, currentUser, setSendMessage, receivedMessage }) => {
  const [userData, setUserData] = useState(null)
  const [messages, setMessages] = useState([])
  const [newMessage, setNewMessage] = useState('')
  const [newMessageFsh, setNewMessageFsh] = useState([])

  const userType = useSelector((state) => state.userType.userType)
  const userToken = useSelector(
    (state) => state.UserAuth.userData.payload.token
  );

  const imageRef = useRef()

  const handleChange = (newMessage) => {
    setNewMessage(newMessage)
  }

  if (userType.payload === 'jobPoster') {
    var ImageUrl = userData?.imagePath
  } else {
    var ImageUrl = userData?.destination
  }

  // fetching data for header
  useEffect(() => {
    const userId = chat?.members?.find((id) => id !== currentUser)
    const getUserData = async () => {
      try {
        const { data } = await getUser(userId, userType.payload)
        setUserData(data)
        // console.log(data,"dat userrr");
      } catch (error) {
        // console.log(error);
      }
    }

    if (chat !== null) getUserData()
  }, [chat, currentUser])

  // console.log(newMessageFsh, "ffffff");
  // console.log(receivedMessage, "messages");

  const fetchMessages = async () => {
    try {
      const { data } = await getMessages(chat._id)
      setMessages(data)
    } catch (error) {
      // console.log(error);
    }
  }

  // fetch messages
  useEffect(() => {
    if (chat !== null) fetchMessages()
  }, [chat, newMessageFsh])

  // Always scroll to last Message
  useEffect(() => {
    scroll.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messages])

  const handleSendFile = async (base64) => {
    const receiverId = chat.members.find((id) => id !== currentUser)
    const message = {
      senderId: currentUser,
      text: base64.base64,
      chatId: chat._id,
      type: base64.type,
      messageType: 1,
      filename: base64.name,
      userType: userType.payload,
      receiverId: receiverId
    }

    // send message to socket server
    setSendMessage({ ...message, receiverId })

    try {
      let axiosConfig = {
        headers: {
          Authorization: 'Bearer ' + userToken
        }
      };
      const { data } = await addMessage(message, axiosConfig)
      // setMessages({...message,data});
      setNewMessageFsh(data)
    } catch {
      toast.error('This file is not supported', {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  // Send Message
  const handleSend = async (e) => {
    const receiverId = chat.members.find((id) => id !== currentUser)
    e.preventDefault()
    const message = {
      senderId: currentUser,
      text: newMessage,
      chatId: chat._id,
      userType: userType.payload,
      receiverId: receiverId
      //messageType :  1
    }
    if (message?.text !== '') {
      // send message to socket server
      setSendMessage({ ...message, receiverId })
      // send message to database
      try {
        let axiosConfig = {
          headers: {
            Authorization: 'Bearer ' + userToken
          }
        };
        const { data } = await addMessage(message, axiosConfig)
        setMessages([...messages, data])
        setNewMessage('')
      } catch {
        toast.error('Something went wrong', {
          position: 'top-right',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    }
  }

  // Receive Message from parent component
  useEffect(() => {
    console.log('Message Arrived: ', receivedMessage, chat)
    if (receivedMessage !== null && receivedMessage.chatId === chat._id) {
      setMessages([...messages, receivedMessage])
    }
  }, [receivedMessage, newMessageFsh])

  const scroll = useRef()

  return (
    <>
      <div className="ChatBox-container">
        {chat ? (
          <>
            {/* chat-header */}
            <div className="chat-header">
              <div className="follower">
                <div>
                  <img
                    src={ImageUrl ? ImageUrl : DefaultImage}
                    alt="Profile"
                    width="50px"
                    height={'50px'}
                    className="rounded-circle followerImage"
                  />
                  <div className="name" style={{ fontSize: '0.9rem' }}>
                    <span>{userData?.fullName}</span>
                  </div>
                </div>
              </div>
              <hr
                style={{
                  width: '95%',
                  border: '0.1px solid #ececec',
                  marginTop: '20px',
                }}
              />
            </div>
            {/* chat-body */}
            <div className="chat-body">
              {messages.map((message) => (
                <>
                  <div
                    ref={scroll}
                    className={
                      message.senderId === currentUser
                        ? 'message own'
                        : 'message'
                    }
                  >
                    {message.messageType === '1' ? (
                      <>
                        {' '}
                        <img
                          src={message.text}
                          alt="Profile"
                          className="followerImage"
                          style={{ width: '200px', height: '150px' }}
                        />{' '}
                      </>
                    ) : message.messageType === '2' ? (
                      <>
                        <video width="250" height="180" controls>
                          <source
                            src={message.text}
                            type="video/mp4"
                          />
                        </video>
                      </>
                    ) : message.messageType === '3' ? (
                      <>
                        {' '}
                        <img
                          src={fileDefault}
                          alt="file"
                          className="followerImage"
                          style={{ width: '100px', height: '50px' }}
                        />{' '}
                        <a
                          href={message.text}
                          target="_blank"
                          download
                        >
                          Download
                        </a>{' '}
                      </>
                    ) : (
                      <>
                        <span>{message.text}</span>{' '}
                      </>
                    )}
                    <span>{format(message.createdAt)}</span>
                  </div>
                </>
              ))}
            </div>
            {/* chat-sender */}
            <div className="chat-sender">
              <div className="upload-img1 position-relative">
                <img
                  src="https://img.icons8.com/ios-glyphs/512/attach.png"
                  width="20px"
                />
                <FileBase64
                  multiple={false}
                  //ref={imageRef}
                  onDone={(base64) => {
                    handleSendFile(base64)
                  }}
                />
              </div>
              {/* <div onClick={() => imageRef.current.click()}>+</div> */}
              <InputEmoji value={newMessage} onChange={handleChange} />
              <div className="send-button button" onClick={handleSend}>
                <img src={MessageSendIcon} width="20px" />
              </div>

              {/* <input
                type="file"
                name=""
                id=""
                style={{ display: "none" }}
                
              /> */}
            </div>{' '}
          </>
        ) : (
          <span className="chatbox-empty-message">
            Tap on a chat to start conversation...
          </span>
        )}
      </div>
    </>
  )
}

export default ChatBox
