import React, { useState } from 'react';

import { Modal } from 'react-bootstrap';
import LoginPopUpImage from '../../../assets/images/login-popup-img-4.png';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function JobPostFourthModal(props) {
  const choosenOption = useSelector(state => state.optionSelected.optionSelected);

  console.log('propsAre', props.hidefour);

  const navigate = useNavigate();
  const redirectTo = () => {
    props.hidefour();
    if (choosenOption.payload === 'Sign Up') {
      navigate('../signup');
    } else {
      navigate('../login');
    }
  };
  return (
    <Modal
      style={{ paddingBottom: '20px' }}
      show={props.showfour}
      onHide={props.hidefour}
      size='lg'
      backdrop='static'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className='four-four-modal'
    >
      <Modal.Header closeButton className='modal-close-stepone' />
      <Modal.Body style={{ marginTop: '-20px', padding: '0px' }}>
        <div>
          <div>
            <form id='msform'>
              <fieldset className='login-pop-step-4' style={{ display: 'block', opacity: '1' }}>
                <div className='form-card'>
                  <div className='login-pop-cnt'>
                    <img style={{ transform: 'scale(1.5)' }} src={LoginPopUpImage} />
                    <br />
                    <br />
                    <div className='modal-content-wrap-four pt-4 pb-3'>
                      <p className=''>
                        Complete your profile, define what you’re looking for and Perspectv will get
                        to work and start finding you suitable matches quickly.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='py-3 mt-4'>
                  <button className='action-button ' onClick={redirectTo}>
                    Next
                  </button>

                  <br />
                  <label className='modal-btn mt-5' onClick={redirectTo}>
                    Skip
                  </label>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
