import React, { useRef, useState } from "react";
import ChatBox from "./ChatBox/ChatBox";
import Conversation from "./Coversation/Conversation";
import LogoSearch from "./LogoSearch/LogoSearch";
import NavIcons from "./NavIcons/NavIcons";
import { BASE_URL_SOCKET } from "../../../utils";
import "./Chat.css";
import { useEffect } from "react";
import { userChats } from "../../../utils/ChatAPi/ChatRequests";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { UilSearch } from "@iconscout/react-unicons";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Chat = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const socket = useRef();
  const { user } = useSelector((state) => state.UserAuth.userData.payload);
  const [chats, setChats] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [sendMessage, setSendMessage] = useState(null);
  const [receivedMessage, setReceivedMessage] = useState(null);
  const [search, setSearch] = useState("");
  // Get the chat in chat section
  useEffect(() => {
    getChats();
  }, [user._id]);
  //Getting the chat window list
  const getChats = async () => {
    try {
      const { data } = await userChats(user._id);

      setChats(data);

      //Boom coder_0001
      data.map((chat) => {
        if (chat?.members?.find((id) => id === state?.seekerId)) {
          setCurrentChat(chat);
        } else if (chat?.members?.find((id) => id === state?.posterId)) {
          setCurrentChat(chat);
        }
      });
    } catch (error) {
      toast.error(error);
    }
  };

  // Connect to Socket.io
  useEffect(() => {
    socket.current = io(BASE_URL_SOCKET);
    socket.current.emit("new-user-add", user._id);
    socket.current.on("get-users", (users) => {
      setOnlineUsers(users);
    });
  }, [user]);

  // Send Message to socket server
  useEffect(() => {
    if (sendMessage !== null) {
      socket.current.emit("send-message", sendMessage);
    }
  }, [sendMessage]);

  // Get the message from socket server
  useEffect(() => {
    socket.current.on("recieve-message", (data) => {
      setReceivedMessage(data);
    });
  }, []);

  const checkOnlineStatus = (chat) => {
    const chatMember = chat.members.find((member) => member !== user._id);
    const online = onlineUsers.find((user) => user.userId === chatMember);
    return online ? true : false;
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.matchMedia]);

  const navigate = useNavigate();

  return (
    <>
      <div className="blur" style={{ top: "-18%", right: "0" }}></div>
      <div className="blur" style={{ top: "36%", left: "-8rem" }}></div>
      <div className="d-flex chat-bg align-items-center justify-content-between">
        <i
          onClick={() => {
            if (currentChat) {
              setCurrentChat();
            } else {
              navigate(-1);
            }
          }}
          style={{ fontSize: "25px" }}
          className="fa fa-arrow-left"
          aria-hidden="true"
        ></i>
        <LogoSearch />
        <NavIcons />
      </div>

      <div className="Chat">
        {(!currentChat || !isMobile) && (
          <div className="Left-side-chat">
            <div className="Chat-container">
              <div className="Search">
                <input
                  type="text"
                  placeholder="#Explore"
                  value={search}
                  onChange={(text) => setSearch(text.target.value)}
                />
                <div className="s-icon">
                  <UilSearch />
                </div>
              </div>

              <h2>Chats</h2>
              <div className="Chat-list">
                {chats.map((chat) => (
                  <div
                    onClick={() => {
                      console.log(chat, "cahat");
                      setCurrentChat(chat);
                    }}
                  >
                    <Conversation
                      data={chat}
                      currentUser={user._id}
                      online={checkOnlineStatus(chat)}
                      currentUserChat={currentChat}
                      search={search}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Right Side */}

        {(currentChat || !isMobile) && (
          <div className="Right-side-chat">
            <div style={{ alignSelf: "flex-end" }}>{/* <NavIcons /> */}</div>
            <ChatBox
              chat={currentChat}
              currentUser={user._id}
              setSendMessage={setSendMessage}
              receivedMessage={receivedMessage}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Chat;
