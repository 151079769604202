import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import GoBack from "../../../components/GoBack";
import brand from "../../../assets/images/logo-icon-blk.png";
import {
  API_PAYMENT_INTENT_SECRET,
  API_PAYMENT_REMOVE_DUPLICATE,
  API_PAYMENT_SAVE_PAYMENT,
  API_PERSONAL_BUY_MULTIPLE_PROFILE,
  API_JOB_SEEKER_UPGRADE_PROFILE,
  postApi,
} from "../../../utils";

function AddCard({ planName, credit, payAsYouGoProfileIds, amount: initialAmount, payAsyougoAmount, setPaymentIntent, setPaymentCompleted }) {
  const userToken = useSelector((state) => state.UserAuth.userData.payload.token);
  const userId = useSelector((state) => state.UserAuth.userData.payload.user._id);
  const userType = useSelector((state) => state.userType.userType);

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [name, setName] = useState("");
  const [isRemember, setRememberCard] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [cardNumberComplete, setCardNumberComplete] = useState(false);
  const [cardExpiryComplete, setCardExpiryComplete] = useState(false);
  const [cardCvcComplete, setCardCvcComplete] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  let amount = initialAmount || payAsyougoAmount;
  const currency = "INR";
  const paymentMode = "card";
  const bodyIntentSecret = { amount, currency, payment_method_types: [paymentMode], isRemember };

  // Fetch client secret for payment intent
  useEffect(() => {
    setErrorMsg("");
    postApi(
      API_PAYMENT_INTENT_SECRET,
      bodyIntentSecret,
      userToken,
      ({ data }) => setClientSecret(data.client_secret),
      (err) => {
        console.error(err);
        setErrorMsg("Error generating payment intent.");
      }
    );
  }, [stripe, elements]);

  const validateFields = () => {
    if (!name) {
      setErrorMsg("Name is required.");
      return false;
    }
    if (!cardNumberComplete || !cardExpiryComplete || !cardCvcComplete) {
      setErrorMsg("Please complete all card fields.");
      return false;
    }
    setErrorMsg("");
    return true;
  };

  const handleSubmit = async () => {
    if (!validateFields()) return;
    if (!stripe || !elements || !clientSecret) return;

    setLoading(true);

    const cardElement = elements.getElement(CardNumberElement);
    const { paymentMethod, error: paymentMethodError } = await stripe.createPaymentMethod({ type: "card", card: cardElement });

    if (paymentMethodError) {
      toast.error(paymentMethodError.message, { position: "top-right", autoClose: 5000 });
      setLoading(false);
      return;
    }

    const { paymentIntent, error: stripeError } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: paymentMethod.id,
    });

    if (stripeError) {
      toast.error(stripeError.message, { position: "top-right", autoClose: 5000 });
      setLoading(false);
      return;
    }

    if (isRemember) {
      postApi(
        API_PAYMENT_REMOVE_DUPLICATE,
        {},
        userToken,
        (res) => {
          console.log(res.data.message);
        },
        (err) => {
          console.error(err);
        }
      );
    }

    if (userType.payload === "jobSeeker") {
      postApi(
        API_JOB_SEEKER_UPGRADE_PROFILE,
        { userId },
        userToken,
        (res) => {
          toast.success(res.data.message, { autoClose: 1000 });
        },
        (err) => {
          console.error("Error upgrading profile:", err);
        }
      );
    } else if (!planName) {
      const data = { pointInfoId: payAsYouGoProfileIds, credit: payAsYouGoProfileIds?.length, payAsyouGo: true };
      postApi(
        API_PERSONAL_BUY_MULTIPLE_PROFILE,
        data,
        userToken,
        (res) => {
          toast.success(res.data.message, { autoClose: 1000 });
        },
        (err) => {
          console.error("Error buying profiles:", err);
        }
      );
    } else {
      const dataPaymentBody = {
        name,
        transactionId: paymentIntent.id,
        userId,
        planId: planName,
        amount,
        credit,
        usertype: 2,
        currency,
        paymentMode,
        status: paymentIntent.status,
        cardNo: paymentMethod.card.last4,
      };
      postApi(
        API_PAYMENT_SAVE_PAYMENT,
        dataPaymentBody,
        userToken,
        (res) => {
          toast.success("Payment saved successfully.");
        },
        (err) => {
          console.error("Error saving payment details:", err);
        }
      );
    }

    toast.success(`Payment of £${amount} ${paymentIntent.status}`, { position: "top-right", autoClose: 5000 });
    setPaymentIntent(paymentIntent);
    setLoading(false);
    setPaymentCompleted(true);
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: { lineHeight: "27px", color: "#212529", fontSize: "15px", "::placeholder": { color: "#aab7c4" } },
      invalid: { color: "#fa755a", iconColor: "#fa755a" },
    },
  };

  return clientSecret ? (
    <section id="form-page" className="red-graphics-top">
      <div className="container">
        <GoBack />
        <div className="row">
          <div className="col-lg-4 col-md-3 col-sm-2" />
          <div className="col-lg-4 col-md-6 col-sm-8 text-center">
            <div className="brand-logo">
              <img src={brand} alt="brand" />
              <h3>Add new card</h3>
            </div>

            <div className="signup-input-bx">
              <label htmlFor="cc-name">Name on card</label>
              <input
                id="cc-name"
                type="text"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="signup-input-bx">
              <label htmlFor="cc-number">Card Number</label>
              <CardNumberElement
                id="cc-number"
                className="form-control div-style"
                options={CARD_ELEMENT_OPTIONS}
                onChange={(e) => setCardNumberComplete(e.complete)}
              />
            </div>

            <div className="signup-input-bx">
              <label htmlFor="expiry">Expiration Date</label>
              <CardExpiryElement
                id="expiry"
                className="form-control"
                options={CARD_ELEMENT_OPTIONS}
                onChange={(e) => setCardExpiryComplete(e.complete)}
              />
            </div>

            <div className="signup-input-bx">
              <label htmlFor="cvc">CVC</label>
              <CardCvcElement
                id="cvc"
                className="form-control"
                options={CARD_ELEMENT_OPTIONS}
                onChange={(e) => setCardCvcComplete(e.complete)}
              />
            </div>

            <button className="btn btn-dark w-100 mt-4" onClick={handleSubmit} disabled={loading}>
              {loading ? (
                <div className="spinner-border spinner-border-sm text-light" role="status" />
              ) : (
                `PAY £${amount}`
              )}
            </button>

            {errorMsg && <div className="text-danger mt-2">{errorMsg}</div>}

            <label className="switch mt-4">
              Remember Card
              <input type="checkbox" onChange={() => setRememberCard(!isRemember)} />
              <span className="slider round" />
            </label>
          </div>
          <div className="col-lg-4 col-md-3 col-sm-2" />
        </div>
      </div>
    </section>
  ) : (
    <div className="d-flex justify-content-center align-items-center LoaderCenter">
      <div className="block">
        <span className="spinner-grow spinner-grow-lg mx-2" role="status" />
        <h6 className="LoaderText">Processing...</h6>
      </div>
    </div>
  );
}

export default AddCard;