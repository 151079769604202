import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import brand from '../../../assets/images/logo-icon-blk.png';
import ArrowIcon from '../../../assets/images/right-arrow.png';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { API_SEEKER_ADD_VIDEO_RESUME } from './../../../utils/APIConstant';
import GoBack from '../../../components/GoBack';
function UploadVideo() {
  const navigation = useNavigate();
  const userToken = useSelector(state => state.UserAuth.userData.payload.token);
  const userId = useSelector(state => state.UserAuth.userData.payload.user._id);
  const [percentageUpload, setPecentageUplaod] = useState(0);
  const [isUploding, setUploading] = useState(false);
  const [videoResume, setVideoResume] = useState('');
  const [buttonDisable, setButtonDisable] = useState(false);
  const [videoName, setVideoName] = useState('');
  const location = useLocation();
  const [justForUseEffect, setjustForUseEffect] = useState(false);
  const [planInfo, setPlan] = useState();

  useEffect(() => {
    if (location?.state?.planInfo !== undefined) {
      localStorage.setItem('PlanInfo', JSON.stringify(location?.state?.planInfo));
    }
  }, []);

  useEffect(() => {
    var playInfoData = JSON.parse(localStorage.getItem('PlanInfo'));
    setPlan(playInfoData);
  }, [justForUseEffect])


  //Save user video resume
  const uploadForm = async () => {
    if (videoResume) {
      setUploading(true);
      setButtonDisable(true);
      var formdata = new FormData();
      formdata.append('videoresume', videoResume);
      formdata.append('userId', userId);
      await axios
        .post(API_SEEKER_ADD_VIDEO_RESUME, formdata, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${userToken}`,
            mode: '1',
            type: 'front-end',
          },
          onUploadProgress: progressEvent => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            setPecentageUplaod(progress);
          },
        })
        .then(res => {
          if (res.status === 200) {
            setVideoName('');
            setVideoResume('');

            toast.success(res.data.message, {
              position: 'top-right',
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            navigation('../paymentMethod', { state: { planInfo: planInfo } });
          }
        })
        .catch(err => {
          toast.err(err.res.message, {
            position: 'top-right',
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      setButtonDisable(false);
      setUploading(false);
    } else {
      navigation('../paymentMethod', { state: { planInfo: planInfo } });
    }
  };

  const getFile = e => {
    setVideoResume(e.target.files[0]);
    let name = e.target.files[0].name;
    let substr = name.substring(0, 20);
    setVideoName(substr, '...');
  };
  useEffect(() => { }, [videoName]);

  return (
    <>
      {isUploding && (
        <div className='d-flex justify-content-center align-items-center LoaderCenter'>
          {' '}
          <div className='block'>
            <span className='spinner-grow spinner-grow-lg mx-2' role='status' aria-hidden='true' />
            <h6 className='LoaderText'>Processing...</h6>
          </div>
        </div>
      )}
      {!isUploding && (
        <section id='red-graphics-bg' className='upload-your-video'>
          <GoBack />

          <div className='container'>
            <div className='row d-flex algin-items-center justify-content-center'>
              <div className='col-lg-9 text-center'>
                <img src={brand} alt='brand' className='w-80px' />
                <h3 style={{ fontSize: '2rem', lineHeight: '2.5rem' }}>
                  Upload a short video of yourself
                </h3>
              </div>
            </div>

            <div className='row'>
              <div className='col-lg-2' />
              <div className='col-lg-8 text-center'>
                <h4>This is a fantasitc way to present yourself to the job poster.</h4>

                <div className='form-group upload-cv-col'>
                  <div className='input-group'>
                    <div className='custom-file'>
                      <div>
                        <p>{videoName ? videoName : 'Attach your Video'}</p>

                        <input
                          type='file'
                          className='custom-file-input'
                          id='inputGroupFile01'
                          aria-describedby='inputGroupFileAddon01'
                          onChange={e => getFile(e)}
                          accept='video/mp4'
                        />
                      </div>
                      <label className='custom-file-label' for='inputGroupFile01'>
                        <i className='fa fa-plus' aria-hidden='true' />
                      </label>
                    </div>
                    {isUploding && (
                      <div className='progress'>
                        <div
                          className='progress-bar progress-bar-striped'
                          role='progressbar'
                          style={{ width: percentageUpload }}
                          aria-valuenow={percentageUpload}
                          aria-valuemin='0'
                          aria-valuemax='100'
                        />
                        {parseInt(percentageUpload) + '%'}
                      </div>
                    )}
                  </div>
                </div>
                <br />
                <br />
                <p>
                  {' '}
                  <a href='javascript:void(0)' onClick={uploadForm}>
                    {' '}
                    <img src={ArrowIcon} alt='right' />
                  </a>
                </p>
                <p className='mt-4'>
                  {/* style="text-decoration: none; color:#000;" */}
                  <a
                    href=''
                    className='text-dark text-decoration-none'
                    style={{ fontSize: '1rem' }}
                  >
                    Skip
                  </a>
                </p>
              </div>

              <div className='col-lg-2' />
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default UploadVideo;
