import React from 'react';
import brand from '../../../assets/images/brand-logo.png';
import bgImg from '../../../assets/images/top-graphics-yellow1.png';
// style="background-position: 0px -238px;" 6
import { useNavigate } from 'react-router-dom';

const styles = {
  main: {
    backgroundPosition: 'center top',
    background: `url(${bgImg}), #d9453d`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
};

function AccountVerify() {
  const navigation = useNavigate();
  return (
    <section id='login-page' style={styles.main}>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-12 text-center'>
            <div className='brand-logo'>
              <img className='img-fluid' style={{ width: '200px' }} src={brand} />
            </div>
            <form>
              <h3>Your account has been verified..</h3>
              <br />
              <button
                className='form-btn'
                style={{ maxWidth: '200px' }}
                onClick={() => navigation('/login')}
              >
                Done
              </button>
            </form>
            {/* <p className="login-signup-txt">
              Don’t have an account? <strong >sign up</strong>
            </p> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default AccountVerify;
