import React from "react";

import Home from "../../../../assets/images//home.png";
import Noti from "../../../../assets/images//noti.png";
import Comment from "../../../../assets/images//comment.png";
import { UilSetting } from "@iconscout/react-unicons";
import { Link ,useNavigate } from "react-router-dom";

const NavIcons = () => {
  const navigate = useNavigate();
  return (
    <div className="navIcons">
      {/* <Link onClick={() => navigate(-1)} > */}
      <button onClick={() => navigate(-1)}>
        <img src="https://img.icons8.com/sf-regular-filled/344/home-page.png" alt="" /></button>
      {/* </Link> */}
      {/* <UilSetting /> */}
     {/* <button><img src="https://img.icons8.com/ios-filled/344/appointment-reminders--v1.png" width="27px" alt="" /></button>  */}
      {/* <Link to="../chat">
        <img src={Comment} alt="" />
      </Link> */}
    </div>
  );
};

export default NavIcons;
