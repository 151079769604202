import React from "react";
import { useNavigate } from "react-router-dom";

const GoBack = () => {
  const navigation = useNavigate();
  const isMobile = window.matchMedia('(max-width: 767px)').matches;
  return isMobile ? null : (
    <button
      className="position-absolute"
      style={{
        width: "100px",
        backgroundColor: "transparent",
        top: "20px",
        left: "-11px",
        fontSize: "30px",
        zIndex: "333",
        border: "none",
      }}
      onClick={() => navigation(-1)}
    >
      <i className="fa fa-arrow-circle-left" aria-hidden="true"></i>
    </button>
  );
};

export default GoBack;
