import React from 'react';
import EagleMan from '../../assets/images/eagle-man-img.png';

export default function FooterSection() {
  return (
    <footer className='dashboard-footer'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-3 col-md-6 col-xs-6 col-12 eagle-man-col'>
            <img src={EagleMan} />
          </div>
          <div className='col-lg-8 col-sm-12 col-xs-12 col-12 text-center'>
            <p>Copyright © 2022 perspectv.com. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
